import React, { useMemo } from 'react';
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewDidEnter
} from '@ionic/react';
import { filter } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import AppLayout from '../layouts/AppLayout';
import { useLocale } from '../contexts/LocaleContext';
import useIonVisible from '../hooks/useIonVisible';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import CityWithContentCountCard from '../components/cards/CityWithContentCountCard';
import useCitiesForInfiniteScroll from '../hooks/useCitiesForInfiniteScroll';
import useAppContentCount from '../hooks/useAppContentCount';
import AppHeader from '../components/AppHeader';
import { useGetNumberOfCitiesAndCountriesWithContentQuery } from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import { CityWithDistanceToCurrentCity } from '../interfaces/Interfaces';
import { useCity } from '../contexts/CityContext';
import useToursAndStoriesCountByCityId from '../hooks/useToursAndStoriesCountByCityId';

const CitiesPage: React.FC = () => {
  const { locale } = useLocale();
  const { isVisible } = useIonVisible();
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { currentCity } = useCity();
  const { toursCount, storiesCount } = useToursAndStoriesCountByCityId(isVisible);

  const { cities, isAllCitiesReceived, setCitiesPageNumber, setInfiniteScrollEventTarget } = useCitiesForInfiniteScroll(isVisible);
  const { appContentCount } = useAppContentCount(isVisible);
  const { data: numberOfCitiesAndCountriesData } =
    useGetNumberOfCitiesAndCountriesWithContentQuery({
      fetchPolicy: "no-cache",
    });

  const filteredCities = useMemo(() => {
    return filter(cities, (city) =>
      !!city._allReferencingStoriesMeta?.count || !!city._allReferencingToursMeta?.count
    );
  }, [cities]);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_CITIES);
    }
  });

  return (
    <AppLayout>
      <AppHeader />

      <IonContent>
        <div className="relative mx-auto h-full max-w-xl bg-[#FAFAFA] px-4">
          <div className="mb-5 pt-[25px] text-center">
            {!!currentCity && !toursCount && !storiesCount && (
              <>
                <div className="text-[1rem] font-semibold text-[#232437]">
                  {t("cities.noResults.title")}
                </div>
                <div className="pt-2 text-[0.75rem] font-semibold text-[#C4C4C4]">
                  {t("cities.noResults.text", { cityName: currentCity.name })}
                </div>
                <div className="my-4 border-t-[1px] border-[#D6D6D6]" />
              </>
            )}

            <h2 className="mb-2 text-[1.25rem] font-bold text-[#232437]">
              {t("cities.title")}
            </h2>

            {!!appContentCount && (
              <p className="px-[70px] text-[0.875rem] font-medium text-[#687582]">
                {t("cities.appContentCount", {
                  toursCount: new Intl.NumberFormat(locale).format(
                    appContentCount?._allToursMeta?.count
                  ),
                  storiesCount: new Intl.NumberFormat(locale).format(
                    appContentCount?._allStoriesMeta?.count
                  ),
                  citiesCount: new Intl.NumberFormat(locale).format(
                    numberOfCitiesAndCountriesData?.dato
                      ?.getNumberOfCitiesWithContent || 0,
                  ),
                  countriesCount: new Intl.NumberFormat(locale).format(
                    numberOfCitiesAndCountriesData?.dato
                      ?.getNumberOfCountriesWithContent || 0
                  )
                })}
              </p>
            )}
          </div>

          <div className="ion-content-scroll-host grid grid-cols-2 gap-4">
            {filteredCities?.map((city) => (
              <CityWithContentCountCard
                key={city.id}
                city={city as CityWithDistanceToCurrentCity}
                navigationPath="home"
              />
            ))}
          </div>
          {!isAllCitiesReceived && (
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                // By setting the page size another GraphQL query for the next page gets executed
                setCitiesPageNumber((citiesPageNumber) => citiesPageNumber + 1);
                setInfiniteScrollEventTarget(ev.target);
              }}
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles" />
            </IonInfiniteScroll>
          )}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CitiesPage;
