import React from "react";
import { IonCard } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { Image } from "react-datocms";

import { Theme } from "../../interfaces/Interfaces";
import StepHeader from "./StepHeader";

const ThemeStep: React.FC<{
  options: Theme[];
  control: any;
}> = ({ options, control }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StepHeader
        number={3}
        title={t("createTour.form.theme.title")}
        hint={t("createTour.form.theme.hint")}
      />

      <Controller
        name="datoThemeId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            {options?.map((theme) => (
              <IonCard
                key={theme.id}
                className="relative m-0 mb-3 cursor-pointer rounded-[18px] bg-transparent p-0 shadow-none"
                onClick={() => onChange(theme.id)}
              >
                <div className="relative aspect-[2/1] overflow-hidden bg-[#D9D9D9]">
                  {!!theme.image?.responsiveImage && (
                    <Image
                      data={theme.image?.responsiveImage}
                      layout="fill"
                      objectFit="cover"
                    />
                  )}
                  {value === theme.id && (
                    <div
                      className={clsx(
                        "absolute h-full w-full rounded-[18px] border-[4px] border-primary",
                        value === theme.id ? "bg-primary bg-opacity-10" : ""
                      )}
                    ></div>
                  )}
                </div>

                <div className="absolute bottom-0 left-0 w-full p-5 text-white">
                  <p className="mb-2 text-[1.125rem] font-bold">
                    {theme.title}
                  </p>
                  {!!theme.description && (
                    <p className="text-[0.75rem]">{theme.description}</p>
                  )}
                </div>
              </IonCard>
            ))}
          </>
        )}
      />
    </div>
  );
};

export default ThemeStep;
