import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  PurchasesPackage,
  PurchasesOffering,
} from "@revenuecat/purchases-capacitor";

import AppLayout from "../layouts/AppLayout";
import useIonVisible from "../hooks/useIonVisible";
import useInAppPurchase from "../hooks/useInAppPurchase";
import useAuthStore from "../stores/useAuthStore";
import { useTransaction } from "../contexts/TransactionContext";
import coverImage from "../assets/premium-access-purchasing/cover.png";
import "./PremiumAccessPurchasingPage.scss";
import useRoutes from "../hooks/useRoutes";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";

const PremiumAccessPurchasingPage: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const { isVisible } = useIonVisible();
  const { isInitialized, getOffering, purchasePackage } = useInAppPurchase();
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const { t } = useTranslation();
  const { currentPath, loginPath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const user = useAuthStore((state) => state.me);
  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);

  const [offering, setOffering] = useState<PurchasesOffering | null>();
  const [isLoaderShown, setIsLoaderShown] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] =
    useState<PurchasesPackage | null>(null);

  useEffect(
    () => {
      const receiveOffering = async () => {
        const selectedOffering = await getOffering("premium_non_renewing");
        setOffering(selectedOffering);
      };

      if (isVisible && isInitialized) receiveOffering();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible, isInitialized]
  );

  useEffect(
    () => {
      if (hasPremiumAccess) {
        setIsLoaderShown(false);

        if (isVisible) {
          const params = new URLSearchParams(location.search);
          const returnTo = params.get("returnTo");
          router.push(
            returnTo ? decodeURIComponent(returnTo) : "/",
            "none",
            "pop"
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPremiumAccess, isVisible]
  );

  const purchase = async () => {
    if (!user?.id) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (selectedPackage) {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.BUY_PREMIUM_ACCESS);
      }

      setIsLoaderShown(true);
      const isPurchasing = await purchasePackage(selectedPackage);
      if (!isPurchasing) setIsLoaderShown(false);
    }
  };

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_PREMIUM_ACCESS_PURCHASING);
    }
  });

  if (!Capacitor.isNativePlatform()) {
    return (
      <AppLayout>
        <IonContent>
          <div className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA] p-5 text-center">
            {t("premiumAccessPurchasing.webError")}
          </div>
        </IonContent>
      </AppLayout>
    );
  }

  return (
    <AppLayout isLoaderShown={isLoaderShown}>
      <IonContent>
        <div
          className="relative mx-auto min-h-full max-w-xl bg-white"
          style={{
            marginTop: "var(--ion-safe-area-top)",
          }}
        >
          <img src={coverImage} alt="" />

          <div className="absolute left-[50%] top-[-15px] translate-x-[-50%] rounded-b-[10px] bg-[#EC765A] px-[14px] py-[22px] text-[1.125rem] font-bold text-white">
            {t("premiumAccessPurchasing.label")}
          </div>

          <h2 className="px-5 py-5 text-center text-[1.125rem] font-bold text-[#232437]">
            {t("premiumAccessPurchasing.title")}
          </h2>

          <IonList className="bg-white pl-8 pr-4">
            {["offline", "groupSharing", "access"].map((advantage) => (
              <IonItem
                key={advantage}
                lines="none"
                style={{
                  "--padding-start": 0,
                  "--inner-padding-end": 0,
                  "--background": "#ffffff",
                  "--min-height": "20px",
                }}
              >
                <div className="mb-1.5 flex items-center">
                  <div className="my-[6px] mr-4 min-h-[20px] border-l-2 border-primary" />
                  <div
                    className="text-[0.75rem] text-[#868B90]"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `premiumAccessPurchasing.advantages.${advantage}`
                      ) as string,
                    }}
                  />
                </div>
              </IonItem>
            ))}
          </IonList>

          <div className="px-5 py-5">
            <div className="mb-3 text-[1rem] font-bold text-[#535E69]">
              {t("premiumAccessPurchasing.form.label")}
            </div>

            <IonList className="bg-white">
              <IonRadioGroup value={selectedPackage?.identifier}>
                {offering?.availablePackages.map((availablePackage) => (
                  <IonItem
                    key={availablePackage?.identifier}
                    lines="none"
                    className="mb-2"
                    style={{
                      "--background": "#ffffff",
                      "--border-color": "#D5D6DC",
                      "--border-width": "1px",
                      "--border-radius": "12px",
                    }}
                    onClick={() => setSelectedPackage(availablePackage)}
                    button
                  >
                    <IonRadio
                      slot="start"
                      mode="ios"
                      color="dark"
                      className="item-premium-access-radio"
                      value={availablePackage?.identifier}
                      disabled={isLoaderShown}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPackage(availablePackage);
                      }}
                    />
                    <div className="flex w-full items-center justify-between text-[1rem] font-semibold">
                      <div
                        className={clsx(
                          "mr-2 text-[1rem] font-semibold",
                          availablePackage?.identifier ===
                            selectedPackage?.identifier
                            ? "text-primary"
                            : "text-[#535E69]"
                        )}
                      >
                        {availablePackage?.product?.description}
                      </div>
                      <div className="text-[#535E69]">
                        {availablePackage?.product?.priceString}
                      </div>
                    </div>
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>

            <IonButton
              className="pointer-events-auto min-h-[56px] w-full text-[1rem] font-semibold normal-case tracking-normal"
              shape="round"
              style={{
                "--background": "#ec765a",
                "--padding-start": "32px",
                "--padding-end": "32px",
                "--box-shadow": "none",
              }}
              disabled={
                !isUserInitialised ||
                activeTransactionsLoading ||
                !selectedPackage ||
                isLoaderShown
              }
              onClick={() => purchase()}
            >
              {t("premiumAccessPurchasing.form.buttons.pay")}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default PremiumAccessPurchasingPage;
