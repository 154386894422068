import { useRef, useState } from "react";
import { useDebouncedEffect } from "@react-hookz/web";

const useContainerHeight = (countTrigger: boolean) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setMapContainerHeight] = useState<
    number | undefined
  >();

  useDebouncedEffect(
    () => {
      setMapContainerHeight(
        countTrigger ? containerRef?.current?.clientHeight : 0
      );
    },
    [containerRef, countTrigger],
    10
  );

  return {
    containerRef,
    containerHeight,
  };
};

export default useContainerHeight;
