import React from "react";

interface StepHeaderProps {
  number: number;
  title: string;
  hint: string;
}

const StepHeader: React.FC<StepHeaderProps> = ({ number, title, hint }) => {
  return (
    <div className="mb-10">
      <div className="text-[0.875rem] font-medium text-[#687582]">{hint}</div>
      <h2 className="text-[1.25rem] font-bold text-[#232437]">
        {number}. {title}
      </h2>
    </div>
  );
};

export default StepHeader;
