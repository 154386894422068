import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import {
  Purchases,
  PurchasesPackage,
  PurchasesError,
} from "@revenuecat/purchases-capacitor";

import useToast from "./useToast";
import useAuthStore from "../stores/useAuthStore";

const useInAppPurchase = () => {
  const { presentToast } = useToast();

  const user = useAuthStore((state) => state.me);

  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize RevenueCat when hook is mounted
  useEffect(
    () => {
      const initialize = async () => {
        const success = await initRevenueCat();
        setIsInitialized(success);
      };

      if (Capacitor.isNativePlatform() && !isInitialized) {
        initialize();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Handle subscriber state based on user changes
  useEffect(
    () => {
      const handleSubscriberState = async () => {
        if (!isInitialized) return;

        const isAnonymous = await isSubscriberAnonymous();

        if (user?.id) {
          if (!isAnonymous) await logOutSubscriber();
          await logInSubscriber(user.id);
        } else {
          if (!isAnonymous) await logOutSubscriber();
        }
      };

      handleSubscriberState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.id, isInitialized]
  );

  const handleError = (error: unknown, message: string) => {
    console.error(error);
    const purchaseError = error as PurchasesError;
    presentToast(
      `${message}: ${purchaseError.message || "Unknown error"}`,
      "danger"
    );
    return false;
  };

  const initRevenueCat = async () => {
    if (!Capacitor.isNativePlatform()) return false;

    const apiKey =
      Capacitor.getPlatform() === "android"
        ? process.env.REACT_APP_ANDROID_REVENUE_CAT_API_KEY
        : process.env.REACT_APP_IOS_REVENUE_CAT_API_KEY;

    if (!apiKey) {
      return handleError(
        new Error("Missing API key"),
        "Purchases configuration failed"
      );
    }

    try {
      await Purchases.configure({ apiKey });
      return true;
    } catch (error) {
      return handleError(error, "Purchases initialization failed");
    }
  };

  const getOffering = async (offeringId: string) => {
    if (!isInitialized) return null;

    try {
      const offerings = await Purchases.getOfferings();
      return offerings.all[offeringId] || null;
    } catch (error) {
      handleError(error, "Failed to fetch offerings");
      return null;
    }
  };

  const purchasePackage = async (aPackage: PurchasesPackage) => {
    const isAnonymous = await isSubscriberAnonymous();

    if (!isInitialized || isAnonymous) return false;

    try {
      await Purchases.purchasePackage({ aPackage });
      return true;
    } catch (error) {
      return handleError(error, "Purchase failed");
    }
  };

  const isSubscriberAnonymous = async () => {
    if (!isInitialized) return false;

    try {
      const isAnonymousRes = await Purchases.isAnonymous();
      return isAnonymousRes?.isAnonymous;
    } catch (error) {
      handleError(error, "Failed to get subscriber status");
      return true;
    }
  };

  const logInSubscriber = async (userId: string) => {
    if (!isInitialized) return false;

    try {
      await Purchases.logIn({ appUserID: userId });
      return true;
    } catch (error) {
      return handleError(error, "Failed to connect subscriber");
    }
  };

  const logOutSubscriber = async () => {
    if (!isInitialized) return false;

    try {
      await Purchases.logOut();
      return true;
    } catch (error) {
      return handleError(error, "Failed to logout subscriber");
    }
  };

  return {
    isInitialized,
    getOffering,
    purchasePackage,
  };
};

export default useInAppPurchase;
