import { useState } from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { App as CapacitorApp } from "@capacitor/app";
import { useTranslation } from "react-i18next";
import {
  analytics,
  arrowForwardOutline,
  cloudDownload,
  create,
  key,
  reader,
  volumeHigh,
} from "ionicons/icons";
import * as moment from "moment";
import { get, head } from "lodash-es";

import AppLayout from "../layouts/AppLayout";
import LanguageSelector from "../components/LanguageSelector";
import shoppingCart from "../assets/profile/shopping-cart.svg";
import yourProfileIcon from "../assets/profile/your-account.svg";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import helpAndSupportIcon from "../assets/profile/help-and-support.svg";
import contactUsIcon from "../assets/profile/contact-us.svg";
import logoutIcon from "../assets/profile/logout.svg";
import useRoutes from "../hooks/useRoutes";
import { useLogOutMutation } from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import { useAuth } from "../contexts/AuthContext";
import useError from "../hooks/useError";
import { useTransaction } from "../contexts/TransactionContext";
import useAuthStore from "../stores/useAuthStore";
import packageInfo from "../../package.json";

const UserProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { localePath, premiumAccessPurchasingPath } = useRoutes();
  const [logOutMutation] = useLogOutMutation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();
  const {
    hasPremiumAccess,
    activeTransactionsLoading,
    activePremiumTransactions,
  } = useTransaction();
  const router = useIonRouter();

  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [appVersion, setAppVersion] = useState<string>();

  const user = useAuthStore((state) => state.me);
  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_USER_PROFILE, {
        userId: user?.id,
        userEmail: user?.email,
      });
    }

    const checkAppVersion = async () => {
      const deviceInfo = await Device.getInfo();
      let appVersion;

      const isRunningInBrowser =
        isPlatform("mobileweb") || deviceInfo.platform === "web";

      if (isRunningInBrowser) {
        appVersion = packageInfo.version;
      } else {
        const appInfo = await CapacitorApp.getInfo();
        appVersion = appInfo?.version;
      }

      setAppVersion(appVersion);
    };

    checkAppVersion();
  });

  const logOut = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.LOG_OUT);
    }

    await handleBackendError(async () => {
      const { errors } = await logOutMutation();
      if (errors) return errors;
    });

    setCurrentUserWithRelatedData(null);
  };

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--ion-safe-area-top)",
        }}
      >
        <div className="mx-auto flex min-h-full max-w-md flex-col justify-between p-5">
          <div>
            <LanguageSelector />

            <IonItem
              detail={true}
              lines="none"
              color="light"
              className="mb-2"
              routerLink={localePath("user-profile/voices")}
            >
              <IonIcon
                src={volumeHigh}
                slot="start"
                className="mr-5 text-[#414254]"
              />
              <IonLabel color="dark">{t("profile.menu.voices")}</IonLabel>
            </IonItem>

            <IonItem
              detail={true}
              lines="none"
              color="light"
              className="mb-2"
              routerLink={localePath("user-profile/orders")}
            >
              <IonIcon src={shoppingCart} slot="start" className="mr-5" />
              <IonLabel color="dark">{t("profile.menu.yourOrders")}</IonLabel>
            </IonItem>

            <IonItem
              detail={true}
              lines="none"
              color="light"
              className="mb-2"
              routerLink={localePath("user-profile/edit")}
            >
              <IonIcon src={yourProfileIcon} slot="start" className="mr-5" />
              <IonLabel color="dark">{t("profile.menu.yourAccount")}</IonLabel>
            </IonItem>

            {Capacitor.isNativePlatform() && (
              <IonItem
                detail={true}
                lines="none"
                color="light"
                className="mb-2"
                routerLink={localePath("user-profile/downloads")}
              >
                <IonIcon
                  src={cloudDownload}
                  slot="start"
                  className="mr-5 text-[#414254]"
                />
                <IonLabel color="dark">
                  {t("profile.menu.yourDownloads")}
                </IonLabel>
              </IonItem>
            )}

            {/* TODO: delete this condition when the GPT functionality is ready */}
            {process.env.REACT_APP_DISPLAY_CREATE_STORY_BUTTON === "true" && (
              <IonItem
                detail={true}
                lines="none"
                color="light"
                className="mb-2"
                routerLink={localePath("user-profile/created-stories")}
              >
                <IonIcon
                  src={create}
                  slot="start"
                  className="mr-5 text-[#414254]"
                />
                <IonLabel color="dark">
                  {t("profile.menu.createdStories")}
                </IonLabel>
              </IonItem>
            )}

            <IonItem
              detail={true}
              lines="none"
              color="light"
              className="mb-2"
              routerLink={localePath("user-profile/created-tours")}
            >
              <IonIcon
                src={analytics}
                slot="start"
                className="mr-5 text-[#414254]"
              />
              <IonLabel color="dark">{t("profile.menu.createdTours")}</IonLabel>
            </IonItem>

            {!Capacitor.isNativePlatform() && (
              <IonItem
                detail={true}
                lines="none"
                color="light"
                className="mb-2"
                routerLink={localePath("user-profile/promotional-code")}
              >
                <IonIcon
                  src={reader}
                  slot="start"
                  className="mr-5 text-[#414254]"
                />
                <IonLabel color="dark">{t("profile.menu.promoCode")}</IonLabel>
              </IonItem>
            )}

            <IonItem
              detail={true}
              lines="none"
              color="light"
              className="mb-2"
              href="https://guidable.com"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                if (mixpanelEnabled) {
                  mixpanel.track(MixpanelEvents.HELP_AND_SUPPORT);
                }
              }}
            >
              <IonIcon src={helpAndSupportIcon} slot="start" className="mr-5" />
              <IonLabel color="dark">
                {t("profile.menu.helpAndSupport")}
              </IonLabel>
            </IonItem>

            <IonItem
              detail={true}
              lines="none"
              color="light"
              className="mb-2"
              href="mailto:hello@guidable.com"
              onClick={() => {
                if (mixpanelEnabled) {
                  mixpanel.track(MixpanelEvents.CONTACT_US);
                }
              }}
            >
              <IonIcon src={contactUsIcon} slot="start" className="mr-5" />
              <IonLabel color="dark">{t("profile.menu.contactUs")}</IonLabel>
            </IonItem>

            {isAuthenticated ? (
              <IonItem
                lines="none"
                color="light"
                className="mb-2"
                onClick={logOut}
              >
                <IonIcon src={logoutIcon} slot="start" className="mr-5" />
                <IonLabel color="dark">{t("profile.menu.logout")}</IonLabel>
              </IonItem>
            ) : (
              <IonItem
                detail={true}
                lines="none"
                color="light"
                className="mb-2"
                routerLink={localePath("auth")}
              >
                <IonIcon
                  icon={key}
                  color="dark"
                  slot="start"
                  className="mr-5"
                />
                <IonLabel color="dark">{t("authFlow.login.title")}</IonLabel>
              </IonItem>
            )}
          </div>

          <IonButton
            className="pointer-events-auto mx-auto my-4 min-h-[56px] font-semibold normal-case tracking-normal"
            shape="round"
            style={{
              "--background": hasPremiumAccess
                ? "#6dd7be"
                : "var(--ion-color-primary)",
              "--padding-start": "32px",
              "--padding-end": "32px",
              "--color": hasPremiumAccess ? "#232437" : "",
              "--ripple-color": hasPremiumAccess ? "#6dd7be" : "",
            }}
            onClick={() => {
              if (
                isUserInitialised &&
                !activeTransactionsLoading &&
                !hasPremiumAccess
              ) {
                if (mixpanelEnabled) {
                  mixpanel.track(MixpanelEvents.GO_TO_PURCHASING, {
                    source: "User profile page",
                  });
                }

                router.push(premiumAccessPurchasingPath());
              }
            }}
          >
            {hasPremiumAccess ? (
              t("profile.menu.premiumAccess.premiumUntil", {
                date: moment(
                  get(head(activePremiumTransactions), "expirationDate")
                ).format("DD.MM.yyyy"),
              })
            ) : (
              <>
                <IonIcon
                  slot="end"
                  icon={arrowForwardOutline}
                  className="ml-1"
                />
                {t("profile.menu.premiumAccess.getPremium")}
              </>
            )}
          </IonButton>

          <div className="text-center text-[0.875rem] text-[#979797]">
            {t("appVersion")}: {appVersion}
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default UserProfilePage;
