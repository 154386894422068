import { useTranslation } from "react-i18next";
import { IonCard } from "@ionic/react";

import OpenTourCreationPageButton from "../buttons/OpenTourCreationPageButton";
import backgroundImage from "../../assets/tour-creation/background.jpg";

const CreateTourCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonCard
      className="m-0 flex aspect-[4/3] flex-col items-start justify-center rounded-[20px] bg-cover bg-center p-6 text-left"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="mb-4 text-[1.375rem] font-bold text-white">
        {t("tours.createTourSection.title")}
      </div>
      <div className="mb-6 text-[0.875rem] text-white">
        {t("tours.createTourSection.text")}
      </div>
      <OpenTourCreationPageButton source="toursPage" />
    </IonCard>
  );
};

export default CreateTourCard;
