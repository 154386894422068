import { useEffect, useRef, useMemo, useState, memo } from "react";
import { useIonViewDidEnter, useIonModal } from "@ionic/react";
import Map, {
  Layer,
  Source,
  MapRef,
  NavigationControl,
  ViewState,
} from "react-map-gl";
import { useDebouncedEffect } from "@react-hookz/web";
import { lineString } from "@turf/helpers";
import { DeepPartial } from "react-hook-form";
import { LineString, FeatureCollection, Position } from "geojson";
import bbox from "@turf/bbox";
import PlayRouteIconButton from "../buttons/PlayRouteIconButton";

import { Tour } from "../../interfaces/Interfaces";
import TourStopMarkers from "./TourStopMarkers";
import {
  UserTour,
  UserTourAudioStatistics,
} from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";
import GeolocateControl from "./GeolocateControl";
import { setMapLanguage } from "../../helpers/map-helpers";
import { useLocale } from "../../contexts/LocaleContext";
import RouteAnimationModal from "../modals/RouteAnimationModal";
import BuildingLayer from "./BuildingLayer";
import { useTransaction } from "../../contexts/TransactionContext";

const navControlStyle = {
  right: 10,
  bottom: 10,
};

const TourMap: React.FC<{
  tour: Tour;
  userTour?: DeepPartial<UserTour> | null;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
}> = ({ tour, userTour, userTourAudioStatistics }) => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();
  const { hasPremiumAccess } = useTransaction();

  const initialViewState = useMemo(() => {
    return {
      latitude: tour?.tourStops?.[0]?.location?.latitude,
      longitude: tour?.tourStops?.[0]?.location?.longitude,
      zoom: 16,
      bearing: 0,
      pitch: 60,
      padding: { top: 0, bottom: 0, left: 0, right: 0 },
    };
  }, [tour]);

  const [viewState, setViewState] = useState<ViewState>(initialViewState);

  const routeLineString = useMemo(() => {
    if (!tour.routeGeoJson) return null;
    const route = tour.routeGeoJson as FeatureCollection<LineString>;
    return route.features.filter(
      (data) => data.geometry.type === "LineString"
    )[0];
  }, [tour.routeGeoJson]);

  const [present, dismiss] = useIonModal(RouteAnimationModal, {
    tour,
    hasPremiumAccess,
    onDismiss: () => dismiss(),
  });

  // fit all tour stops to the map
  useDebouncedEffect(
    () => {
      if (!mapRef.current) return;

      const tourStopCoordinates = tour?.tourStops?.reduce(
        (acc: Position[], tourStop) => {
          const coordinates = tourStop?.location?.longitude &&
            tourStop?.location?.latitude && [
              tourStop?.location?.longitude,
              tourStop?.location?.latitude,
            ];
          return coordinates ? [...acc, coordinates] : acc;
        },
        []
      );
      const features = lineString(tourStopCoordinates || []);
      const [minLng, minLat, maxLng, maxLat] = bbox(features);

      mapRef?.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 40,
          duration: 1000,
          pitch: mapRef?.current.getPitch(),
          bearing: mapRef?.current.getBearing(),
        }
      );
    },
    [tour, mapRef],
    10
  );

  // Set a different map center if the tour changes
  useEffect(() => {
    setViewState(initialViewState);
  }, [initialViewState]);

  useIonViewDidEnter(() => {
    mapRef.current?.resize();
  }, [mapRef]);

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(evt) => {
        setViewState(evt.viewState);
      }}
      attributionControl={false}
      reuseMaps={true}
      dragRotate={true}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      onLoad={(e) => {
        setMapLanguage(e, locale);
      }}
    >
      {!!routeLineString?.geometry?.coordinates.length && (
        <PlayRouteIconButton onClick={() => present()} />
      )}

      <GeolocateControl mapRef={mapRef} />
      <NavigationControl style={navControlStyle} showCompass={false} />

      <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.mapbox-terrain-dem-v1"
        tileSize={512}
      />

      <BuildingLayer />

      <TourStopMarkers
        tour={tour}
        userTour={userTour}
        userTourAudioStatistics={userTourAudioStatistics}
      />

      {!!routeLineString && (
        <Source
          type="geojson"
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: routeLineString.geometry.coordinates,
            },
          }}
        >
          <Layer
            key={`${tour.id}-line`}
            type="line"
            layout={{
              "line-cap": "butt",
            }}
            paint={{
              "line-color": "#e38873",
              "line-width": 5,
            }}
          />
        </Source>
      )}
    </Map>
  );
};

export default memo(TourMap);
