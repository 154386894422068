import { memo } from "react";
import { Marker as MapMarker } from "react-map-gl";
import { Icon } from "@iconify/react";
import flagOutlined from "@iconify/icons-ant-design/flag-outlined";
import clsx from "clsx";
import { IonIcon } from "@ionic/react";

import "./TourStopMarker.scss";
import useStoryPlayer from "../../hooks/useStoryPlayer";
import { numberOfTourStop } from "../../helpers/tour-stop-helpers";
import { TourStop } from "../../interfaces/Interfaces";
import startIcon from "../../assets/tour/start.svg";

const TourStopMarker: React.FC<{
  tourStop: TourStop;
  isActive: boolean;
  isCompleted: boolean;
  isDisabledByQuizzes?: boolean;
  isDisabledByPremiumAccess?: boolean;
  tourStopNumber?: number;
}> = ({
  tourStop,
  isActive,
  isCompleted,
  isDisabledByQuizzes,
  isDisabledByPremiumAccess,
  tourStopNumber,
}) => {
  const { navigateToStory } = useStoryPlayer();
  const tourStopLocation = tourStop.location;

  // Only render a marker if the tour stop is active
  if (!tourStopLocation) {
    return null;
  }

  return (
    <MapMarker
      key={`tour-stop-marker-${tourStop.id}`}
      latitude={tourStopLocation.latitude}
      longitude={tourStopLocation.longitude}
    >
      <div
        onClick={() => {
          if (isDisabledByQuizzes || isDisabledByPremiumAccess) return;

          const story = tourStop.stories?.[0];

          if (story) {
            navigateToStory({ story });
          } else {
            console.log("Tour stop doesn't have any stories to play");
          }
        }}
        className={clsx(
          "tour-stop-marker",
          "rounded-full text-center font-semibold",
          isActive
            ? "z-20 h-[32px] w-[32px] text-base leading-[32px] text-blue-50"
            : "z-10 h-[26px] w-[26px] text-sm leading-[26px] text-slate-50",
          isActive
            ? "bg-blue-600"
            : isCompleted
            ? "bg-[#85d2be]"
            : "bg-slate-500"
        )}
      >
        {!!tourStop.isIntroTourStop && (
          <IonIcon
            src={startIcon}
            className={clsx(
              "absolute left-1/2 top-1/2 ml-px -translate-x-1/2 -translate-y-1/2",
              isActive ? "text-lg" : "text-base"
            )}
          />
        )}
        {!!tourStop.isOutroTourStop && (
          <Icon
            icon={flagOutlined}
            className={clsx(
              "absolute left-1/2 top-1/2 ml-px -translate-x-1/2 -translate-y-1/2",
              isActive ? "text-lg" : "text-base"
            )}
          />
        )}
        {!tourStop.isIntroTourStop &&
          !tourStop.isOutroTourStop &&
          (tourStopNumber || numberOfTourStop(tourStop))}
      </div>
    </MapMarker>
  );
};

export default memo(TourStopMarker);
