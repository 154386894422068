import { Image, ResponsiveImageType } from "react-datocms";
import { useTranslation } from "react-i18next";
import { IonIcon } from "@ionic/react";
import {
  cloneDeep,
  filter,
  forEach,
  isNumber,
  map as _map,
  sortBy,
} from "lodash-es";
import clsx from "clsx";

import { Tour } from "../../interfaces/Interfaces";
import CategoryTags from "../../components/category-tags/CategoryTags";
import ShareButton from "../../components/buttons/ShareButton";
import {
  TourRatingStatistics,
  UserTourAudioStatistics,
} from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";
import StarRating from "../../components/StarRating";
import Slider from "../../components/sliders/Slider";
import DownloadTourButton from "../../components/buttons/DownloadTourButton";
import ProgressBar from "../../components/ProgressBar";
import useAuthStore from "../../stores/useAuthStore";
import ResetTourButton from "../../components/buttons/ResetTourButton";
import walkIcon from "../../assets/transportation-types/walk.svg";
import bikeIcon from "../../assets/transportation-types/bike.svg";
import boatIcon from "../../assets/transportation-types/boat.svg";
import busIcon from "../../assets/transportation-types/bus.svg";
import carIcon from "../../assets/transportation-types/car.svg";
import trainIcon from "../../assets/transportation-types/train.svg";
import { VideoRecord } from "../../graphql/dato/__generated__/dato-graphql.generated";
import ExternalVideoPlayer from "../../components/players/ExternalVideoPlayer";
import TourTranslationSelector from "./TourTranslationSelector";
import { getTourAudioDurationInMinutes } from "../../helpers/tour-helpers";
import PlayRouteTextButton from "../../components/buttons/PlayRouteTextButton";
import { useTransaction } from "../../contexts/TransactionContext";

const sliderProps = {
  // Enable virtual slides
  virtual: true,

  // Default config
  slidesPerView: 1,
  spaceBetween: 6,
};

const TourInfo: React.FC<{
  tour: Tour;
  tourRatingStatistics: TourRatingStatistics | null | undefined;
}> = ({ tour, tourRatingStatistics }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx("mx-4", tour?.mediaGallery?.length ? "mt-0" : "mt-3")}>
      <div className="flex">
        {tourRatingStatistics && (
          <StarRating
            tourRatingStatistics={tourRatingStatistics}
            captionClassName="text-[#414254] text-[0.7rem] font-light ml-1.5"
          />
        )}
      </div>

      <div className="flex flex-wrap">
        <div className="mr-1 flex text-[0.7rem] font-light text-[#414254]">
          {tour.distanceInKilometers && (
            <div>
              {t("tourPage.tourDistance")}:
              <span className="font-bold">
                {" "}
                {tour.distanceInKilometers} {t("tour.kilometersInShortForm")}
              </span>
            </div>
          )}
          {getTourAudioDurationInMinutes(tour) && (
            <div className="ml-1">
              {t("tourPage.audioDuration")}:
              <span className="font-bold">
                {" "}
                {getTourAudioDurationInMinutes(tour)}{" "}
                {t("tour.minutesInShortForm")}
              </span>
            </div>
          )}
        </div>

        {(tour.durationInMinutes ||
          tour.durationByBikeInMinutes ||
          tour.durationByCarInMinutes ||
          tour.durationByBusInMinutes ||
          tour.durationByTrainInMinutes ||
          tour.durationByBoatInMinutes) && (
          <div className="flex flex-wrap text-[0.7rem] font-light text-[#414254]">
            <span className="mr-0.5">{t("tourPage.tourDuration")}:</span>

            {tour.durationInMinutes && (
              <div className="mr-1.5 flex items-center justify-end">
                <IonIcon src={walkIcon} className="mr-0.5" />
                <span className="font-bold">
                  {tour.durationInMinutes} {t("tour.minutesInShortForm")}
                </span>
              </div>
            )}

            {tour.durationByBikeInMinutes && (
              <div className="mr-1.5 flex items-center justify-end">
                <IonIcon src={bikeIcon} className="mr-0.5" />
                <span className="font-bold">
                  {tour.durationByBikeInMinutes} {t("tour.minutesInShortForm")}
                </span>
              </div>
            )}

            {tour.durationByCarInMinutes && (
              <div className="mr-1.5 flex items-center justify-end">
                <IonIcon src={carIcon} className="mr-0.5" />
                <span className="font-bold">
                  {tour.durationByCarInMinutes} {t("tour.minutesInShortForm")}
                </span>
              </div>
            )}

            {tour.durationByBusInMinutes && (
              <div className="mr-1.5 flex items-center justify-end">
                <IonIcon src={busIcon} className="mr-0.5" />
                <span className="font-bold">
                  {tour.durationByBusInMinutes} {t("tour.minutesInShortForm")}
                </span>
              </div>
            )}

            {tour.durationByTrainInMinutes && (
              <div className="mr-1.5 flex items-center justify-end">
                <IonIcon src={trainIcon} className="mr-0.5" />
                <span className="font-bold">
                  {tour.durationByTrainInMinutes} {t("tour.minutesInShortForm")}
                </span>
              </div>
            )}

            {tour.durationByBoatInMinutes && (
              <div className="mr-1.5 flex items-center justify-end">
                <IonIcon src={boatIcon} className="mr-0.5" />
                <span className="font-bold">
                  {tour.durationByBoatInMinutes} {t("tour.minutesInShortForm")}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const MediaBlockRenderer: React.FC<{ record: any }> = ({ record }) => {
  switch (record.__typename) {
    case "ImageRecord":
      return (
        <Image
          data={record?.image?.responsiveImage as ResponsiveImageType}
          layout="fill"
          objectFit="cover"
        />
      );
    case "VideoRecord":
      return (
        <ExternalVideoPlayer
          videoRecord={record as VideoRecord}
          aspectRatio="3:2"
        />
      );
    default:
      return null;
  }
};

const TourPageHeader: React.FC<{
  isVisible: boolean;
  tour: Tour;
  tourRatingStatistics: TourRatingStatistics | null | undefined;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
  getUserTourAudioStatistics: () => void;
}> = ({
  isVisible,
  tour,
  tourRatingStatistics,
  userTourAudioStatistics,
  getUserTourAudioStatistics,
}) => {
  const { hasPremiumAccess } = useTransaction();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const tourHighlightImage = tour.highlightImage
    ?.responsiveImage as ResponsiveImageType;

  // combine images and videos for the slider and place the videos according to the "order" field
  const orderedSliderMedia: any = cloneDeep(tour?.mediaGallery || []);
  forEach(
    sortBy(
      filter(tour?.videoGallery, (item) => item.__typename === "VideoRecord"),
      "order"
    ),
    (item) => {
      const videoItem = item as VideoRecord;
      orderedSliderMedia.splice(
        isNumber(videoItem?.order) ? videoItem?.order - 1 : 0,
        0,
        videoItem
      );
    }
  );

  return (
    <div className="relative w-full">
      {(tourHighlightImage || orderedSliderMedia?.length) && (
        <div
          className={clsx(
            "relative",
            orderedSliderMedia?.length ? "" : "pb-[18px]"
          )}
        >
          {orderedSliderMedia?.length ? (
            <Slider sliderProps={sliderProps}>
              {_map(orderedSliderMedia, (media, i) => (
                <div key={i} className="aspect-[3/2]">
                  <MediaBlockRenderer record={media} />
                </div>
              ))}
            </Slider>
          ) : (
            <Image data={tourHighlightImage} layout="responsive" />
          )}

          {!!tour.routeGeoJson && (
            <PlayRouteTextButton
              tour={tour}
              hasPremiumAccess={hasPremiumAccess}
            />
          )}
        </div>
      )}

      <TourInfo tour={tour} tourRatingStatistics={tourRatingStatistics} />

      {isAuthenticated && (
        <div className="ml-4 mr-8 pt-2">
          <ProgressBar progress={userTourAudioStatistics?.audioProgress} />
        </div>
      )}

      <div className="mx-4 mb-2 mt-3 flex items-center justify-between pr-16">
        <h1 className="text-[1.6rem] font-bold leading-[1.85rem] text-slate-800">
          {tour.title}
        </h1>
      </div>

      <div className="mx-4 mb-2 flex items-center justify-between space-x-2">
        <CategoryTags categories={tour.categories} />
        <div className="flex items-center space-x-2">
          <DownloadTourButton tour={tour} />
          <ResetTourButton
            tourId={tour.id}
            getUserTourAudioStatistics={getUserTourAudioStatistics}
          />
          <ShareButton tour={tour} />
        </div>
      </div>

      <TourTranslationSelector isVisible={isVisible} tour={tour} />
    </div>
  );
};

export default TourPageHeader;
