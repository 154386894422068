import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonButton, IonContent, IonIcon, IonSpinner, useIonRouter, useIonViewDidEnter } from '@ionic/react';
import clsx from 'clsx';
import { arrowForwardOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { DeepPartial } from 'react-hook-form';

import AppLayout from '../layouts/AppLayout';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import useTour from '../hooks/useTour';
import TourMapTab from './TourPage/TourMapTab';
import TourTab from './TourPage/TourTab';
import { TourPageSegmentSwitcher, TourPageTabs } from './TourPage/TourPageSegments';
import TourPageHeader from './TourPage/TourPageHeader';
import TourOverviewTab from './TourPage/TourOverviewTab';
import { useMediaPlayer } from '../contexts/MediaPlayerContext';
import useMediaPlayerStore from '../stores/useMediaPlayerStore';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import useTourRatingStatistics from '../hooks/useTourRatingStatistics';
import useRoutes from '../hooks/useRoutes';
import useError from '../hooks/useError';
import useAuthStore from '../stores/useAuthStore';
import {
  useCreateUserTourMutation,
  useGetUserTourAudioStatisticsLazyQuery,
  UserTour,
  UserTourAudioStatistics
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useIonVisible from '../hooks/useIonVisible';
import { getInputToCreateUserTour } from '../helpers/user-tour-helpers';
import { useExploreSightseeingSpotsQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import { SightseeingSpot } from '../interfaces/Interfaces';
import { useLocale } from '../contexts/LocaleContext';
import { useTransaction } from '../contexts/TransactionContext';
import { isAccessibleTour } from '../helpers/tour-helpers';

interface TourPageProps
  extends RouteComponentProps<{
    tourSlug: string;
  }> {}

const TourPage: React.FC<TourPageProps> = ({ match }) => {
  const { tourSlug } = match.params;
  const { tour } = useTour({ tourSlug });
  const { tourRatingStatistics } = useTourRatingStatistics({ tourId: tour?.id });
  const { currentStory } = useMediaPlayer();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { localePath, loginPath, premiumAccessPurchasingPath } = useRoutes();
  const { t } = useTranslation();
  const { handleBackendError } = useError();
  const { isVisible } = useIonVisible();
  const { queryLocale } = useLocale();
  const router = useIonRouter();
  const { hasPremiumAccess, accessibleTourIds, activeTransactionsLoading } = useTransaction();

  const [getUserTourAudioStatisticsQuery] = useGetUserTourAudioStatisticsLazyQuery();
  const [createUserTourMutation] = useCreateUserTourMutation();

  const defaultTab = TourPageTabs.overview;
  const [tab, setTab] = useState<TourPageTabs>(defaultTab);
  const [userTourAudioStatistics, setUserTourAudioStatistics] = useState<UserTourAudioStatistics | null>();
  const [userTour, setUserTour] = useState<DeepPartial<UserTour> | null>();
  const [sightseeingSpots, setSightseeingSpots] = useState<SightseeingSpot[]>([]);

  const currentTour = useMediaPlayerStore((state) => state.currentTour);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const sightseeingSpotsQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      filter: {
        OR: tour?.tourStops?.map((tourStop) => ({
          location: {
            near: {
              latitude: tourStop?.location?.latitude,
              longitude: tourStop?.location?.longitude,
              radius: 0
            }
          }
        }))
      },
      skip: 0,
      first: 100,
    };
  }, [queryLocale, tour]);

  const [sightseeingSpotsResult] = useExploreSightseeingSpotsQuery({
    variables: sightseeingSpotsQueryVariables(),
    pause: !tour,
  });
  const { data: sightseeingSpotsData } = sightseeingSpotsResult;

  // Set sightseeing spots when data is available
  useEffect(() => {
    setSightseeingSpots(sightseeingSpotsData?.sightseeingSpots as SightseeingSpot[] || []);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sightseeingSpotsData]
  );

  useEffect(() => {
    const getUserTourAndAudioStatistics = async () => {
      if (isVisible && isAuthenticated && tour) {
        await getUserTourAudioStatistics();
        await createOrUpdateUserTour();
      } else {
        setUserTour(null);
        setUserTourAudioStatistics(null);
      }
    };

    getUserTourAndAudioStatistics();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tour, isAuthenticated, isVisible]
  );

  const getUserTourAudioStatistics = async () => {
    await handleBackendError(async () => {
      if (tour) {
        const { error, data } = await getUserTourAudioStatisticsQuery({
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              datoTourId: tour.id
            }
          }
        });
        if (error) return error;

        const statistics = data?.userTour?.getUserTourAudioStatistics;
        if (statistics) {
          setUserTourAudioStatistics(statistics);
        }
      }
    });
  };

  const createOrUpdateUserTour = async () => {
    await handleBackendError(async () => {
      if (tour) {
        const { errors, data } = await createUserTourMutation({
          variables: {
            input: getInputToCreateUserTour(tour),
          }
        });
        if (errors) return errors;

        const userTour = data?.userTour?.createUserTour;
        if (userTour) {
          setUserTour(userTour);
        }
      }
    })
  };

  const startTour = () => {
    if (activeTransactionsLoading || !tour) return;

    if (hasPremiumAccess || isAccessibleTour(tour, accessibleTourIds)) {
      router?.push(localePath(`tours/${tour.slug}/start-tour`));
    } else {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.GO_TO_PURCHASING, {
          source: 'Tour page',
          tourId: tour?.id,
          tourTitle: tour?.title,
        });
      }

      router?.push(isAuthenticated ?
        premiumAccessPurchasingPath(localePath(`tours/${tour.slug}`)) :
        loginPath(premiumAccessPurchasingPath(localePath(`tours/${tour.slug}`)))
      );
    }
  };

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR, {
        tourId: tour?.id,
        tourTitle: tour?.title,
      });
    }
  });

  return (
    <AppLayout>
      <IonContent>
        <div className="relative mx-auto h-full max-w-xl">
          <FloatingBackButton />

          {tour && (
            <>
              <div className="flex h-full flex-col items-start justify-start">
                <TourPageHeader
                  isVisible={isVisible}
                  tour={tour}
                  tourRatingStatistics={tourRatingStatistics}
                  userTourAudioStatistics={userTourAudioStatistics}
                  getUserTourAudioStatistics={getUserTourAudioStatistics}
                />
                <TourPageSegmentSwitcher tab={tab} setTab={setTab} />
                {tab === TourPageTabs.overview && <TourOverviewTab tour={tour} />}
                {tab === TourPageTabs.tour && <TourTab
                  tour={tour}
                  userTour={userTour}
                  userTourAudioStatistics={userTourAudioStatistics}
                  sightseeingSpots={sightseeingSpots}
                />}
                {tab === TourPageTabs.map && <TourMapTab
                  tour={tour}
                  userTour={userTour}
                  userTourAudioStatistics={userTourAudioStatistics}
                />}
              </div>

              {tour.id !== currentTour?.id && (
                <div
                  className={clsx(
                    "pointer-events-none fixed left-0 right-0 z-10",
                    // The mini player is showing if there's a current tour stop.
                    // In this case, move the button up above the mini player.
                    currentStory ? "bottom-[78px]" : "bottom-[12px]"
                  )}
                >
                  <div className="flex items-center justify-center">
                    <IonButton
                      className="pointer-events-auto normal-case tracking-normal font-semibold mt-4 min-h-[56px]"
                      shape="round"
                      style={{
                        '--background': '#ec765a',
                        '--padding-start': '32px',
                        '--padding-end': '32px',
                      }}
                      onClick={() => startTour()}
                    >
                      {activeTransactionsLoading ?
                        <IonSpinner name="bubbles" slot="end" className="ml-1"/> :
                        <IonIcon icon={arrowForwardOutline} slot="end" className="ml-1"/>}

                      {t(activeTransactionsLoading ?
                        'tourPage.callToAction.wait' :
                        hasPremiumAccess || isAccessibleTour(tour, accessibleTourIds) ?
                          'tourPage.callToAction.discoverNow' :
                          'tourPage.callToAction.buyPremiumAccess')}
                    </IonButton>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default TourPage;
