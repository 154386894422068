import { memo } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { play as playIcon, pause as pauseIcon } from "ionicons/icons";
import clsx from "clsx";

interface PlayRouteIconButtonProps {
  isPlaying?: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
  className?: string;
  size?: "small" | "large";
}

const PlayRouteIconButton: React.FC<PlayRouteIconButtonProps> = ({
  isPlaying = false,
  onClick,
  style = {},
  className = "",
  size = "large",
}) => {
  return (
    <IonButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      color="primary"
      shape="round"
      className={clsx(
        "absolute z-50",
        size === "small"
          ? "-right-2 -top-4 h-[28px] w-[28px]"
          : "left-2.5 top-2.5 h-[64px] w-[64px]",
        className
      )}
      style={style}
    >
      <IonIcon
        icon={isPlaying ? pauseIcon : playIcon}
        slot="icon-only"
        className={clsx(
          size === "small"
            ? "h-[14px] w-[14px] min-w-[14px]"
            : "h-[28px] w-[28px] min-w-[28px]"
        )}
      />
    </IonButton>
  );
};

export default memo(PlayRouteIconButton);
