import { Layer } from "react-map-gl";
import { memo } from "react";
import { FillExtrusionLayerSpecification } from "mapbox-gl";

const buildingLayer: FillExtrusionLayerSpecification = {
  id: "3d-buildings",
  source: "composite",
  "source-layer": "building",
  type: "fill-extrusion",
  paint: {
    "fill-extrusion-color": "#aaa",
    "fill-extrusion-height": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "height"],
    ],
    "fill-extrusion-base": ["get", "min_height"],
    "fill-extrusion-opacity": 0.6,
  },
};

const BuildingLayer: React.FC = () => {
  return <Layer {...buildingLayer} />;
};

export default memo(BuildingLayer);
