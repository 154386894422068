import { IonButton, IonIcon } from "@ionic/react";
import clsx from "clsx";
import React from "react";
import { some } from "lodash";

type IonButtonProps = Omit<
  React.ComponentProps<typeof IonButton>,
  "size" | "style"
> & { withGradient?: boolean };

const AppButton: React.FC<IonButtonProps> = ({ children, ...props }) => {
  // Check if children contains an IonIcon using lodash
  const hasIcon = some(
    React.Children.toArray(children),
    (child) => React.isValidElement(child) && child.type === IonIcon
  );

  return (
    <IonButton
      {...props}
      size="large"
      className={clsx(
        "w-full text-[16px] font-semibold normal-case",
        props.className
      )}
      style={{
        ...(props.withGradient
          ? { "--background": "linear-gradient(to right, #E16A4F, #E14F84)" }
          : {}),
        ...(props.fill === "outline" ? { "--background": "#ffffff" } : {}),
        "--padding-top": hasIcon ? "17px" : "20px",
        "--padding-bottom": hasIcon ? "17px" : "20px",
      }}
    >
      {children}
    </IonButton>
  );
};

export default AppButton;
