import { IonButton, IonIcon, useIonModal } from "@ionic/react";
import { playCircle as playCircleIcon } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import RouteAnimationModal from "../modals/RouteAnimationModal";
import { Tour } from "../../interfaces/Interfaces";

interface PlayRouteTextButtonProps {
  tour: Tour;
  hasPremiumAccess: boolean;
}

const PlayRouteTextButton: React.FC<PlayRouteTextButtonProps> = ({
  tour,
  hasPremiumAccess,
}) => {
  const { t } = useTranslation();

  const [present, dismiss] = useIonModal(RouteAnimationModal, {
    tour,
    hasPremiumAccess,
    onDismiss: () => dismiss(),
  });

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    present();
  };

  return (
    <IonButton
      shape="round"
      className="absolute bottom-0 right-3 z-10 m-0 text-[0.75rem] font-semibold normal-case tracking-normal"
      style={{
        "--padding-start": "8px",
        "--padding-end": "12px",
        "--box-shadow": "none",
      }}
      onClick={handleClick}
    >
      <IonIcon icon={playCircleIcon} className="mr-1 text-[1.5rem]" />
      {t("dictionary.preview")}
    </IonButton>
  );
};

export default PlayRouteTextButton;
