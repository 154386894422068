import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { memo } from "react";

import useRoutes from "../../hooks/useRoutes";
import starsIcon from "../../assets/tour-creation/stars.svg";
import useAuthStore from "../../stores/useAuthStore";
import { useTranslation } from "react-i18next";
import { MixpanelEvents } from "../../contexts/MixpanelContext";
import { useMixpanel } from "../../contexts/MixpanelContext";
import { useCity } from "../../contexts/CityContext";

const OpenTourCreationPageButton: React.FC<{
  source: "toursPage" | "homePage";
}> = ({ source }) => {
  const router = useIonRouter();
  const { loginPath, localePath } = useRoutes();
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { currentCity } = useCity();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const navigateToCreateTourPage = () => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.NAVIGATE_TO_TOUR_CREATION_PAGE, {
        source,
        currentCityId: currentCity?.id,
        currentCityName: currentCity?.name,
      });
    }

    router.push(
      isAuthenticated
        ? localePath("tours/create")
        : loginPath(localePath("tours/create"))
    );
  };

  return (
    <IonButton
      shape="round"
      className="text-[0.75rem] font-medium normal-case"
      style={{
        "--background": "linear-gradient(to right, #E16A4F, #E14F84)",
        "--padding-start": "14px",
        "--padding-end": "14px",
        "--box-shadow": "none",
      }}
      onClick={() => navigateToCreateTourPage()}
    >
      <IonIcon src={starsIcon} className="mr-1" />
      {t("tours.buttons.navigateToTourCreationPage")}
    </IonButton>
  );
};

export default memo(OpenTourCreationPageButton);
