import { memo, useEffect, useState } from "react";
import { useAudio } from "react-use";
import { IonButton, IonIcon } from "@ionic/react";
import {
  volumeMute as volumeMuteIcon,
  volumeMedium as volumeMediumIcon,
} from "ionicons/icons";
import { find, head, get } from "lodash-es";
import axios from "axios";
import Hls from "hls.js";

import useTourStop from "../../hooks/useTourStop";
import { Tour, TourStop } from "../../interfaces/Interfaces";
import useAuthStore from "../../stores/useAuthStore";
import useNetwork from "../../hooks/useNetwork";
import { getActiveAudioSrc } from "../../helpers/media-player-helpers";

const PlayTourPreviewAudioButton: React.FC<{
  tour: Tour;
  hasPremiumAccess: boolean;
  isPlaying: boolean;
  isAudioEnded: boolean;
  setIsAudioEnded: (value: boolean) => void;
}> = ({ tour, hasPremiumAccess, isPlaying, isAudioEnded, setIsAudioEnded }) => {
  const [audio, state, controls, audioRef] = useAudio({
    src: "",
  });

  const { getNetworkStatus } = useNetwork();

  const user = useAuthStore((state) => state.me);
  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);

  const [previewTourStopId, setPreviewTourStopId] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);

  const { tourStop } = useTourStop({
    tourStopId: previewTourStopId,
    pause: !previewTourStopId,
  });

  useEffect(() => {
    if (state.duration && state.time && state.duration - state.time < 0.1) {
      setIsAudioEnded(true);
    }
  }, [state.duration, state.time, setIsAudioEnded]);

  useEffect(() => {
    const previewTourStop = find<TourStop>(
      tour?.tourStops,
      (tourStop) => tourStop.isIntroTourStop
    );

    if (previewTourStop?.id) {
      setPreviewTourStopId(previewTourStop.id);
    }
  }, [tour]);

  useEffect(
    () => {
      const loadAudio = async () => {
        if (!tourStop || !audioRef?.current || !isUserInitialised) return;

        const firstStorySlide = head(
          get(head(tourStop.stories), "storySlides")
        );
        if (!firstStorySlide) return;

        const connectionStatus = await getNetworkStatus();
        const activeAudioSrc = getActiveAudioSrc(
          firstStorySlide,
          connectionStatus.connected,
          hasPremiumAccess,
          user
        );

        if (audioRef.current.src !== activeAudioSrc) {
          setIsLoading(true);

          const loadAudioFromDato = (url: string) => {
            audioRef.current!.src = url;
            audioRef.current!.load();
            controls.play();
          };

          const playHlsStream = (url: string) => {
            if (audioRef.current && Hls.isSupported()) {
              const hls = new Hls();
              hls.loadSource(url);
              hls.attachMedia(audioRef.current);
              hls.on(Hls.Events.MANIFEST_PARSED, () => {
                controls.play();
              });
            }
          };

          try {
            if (
              activeAudioSrc.startsWith(process.env.REACT_APP_BACKEND_API_URL!)
            ) {
              const res = await axios.get(activeAudioSrc);
              res.data?.endsWith(".m3u8")
                ? playHlsStream(res.data)
                : loadAudioFromDato(res.data);
            } else {
              loadAudioFromDato(activeAudioSrc);
            }
          } finally {
            setIsLoading(false);
          }
        }
      };

      loadAudio();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourStop, audioRef, isUserInitialised]
  );

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handleLoadStart = () => setIsLoading(true);
      const handleCanPlay = () => setIsLoading(false);
      const handleError = () => setIsLoading(false);

      audioElement.addEventListener("loadstart", handleLoadStart);
      audioElement.addEventListener("canplay", handleCanPlay);
      audioElement.addEventListener("error", handleError);

      return () => {
        audioElement.removeEventListener("loadstart", handleLoadStart);
        audioElement.removeEventListener("canplay", handleCanPlay);
        audioElement.removeEventListener("error", handleError);
      };
    }
  }, [audioRef]);

  useEffect(
    () => {
      if (isLoading || isAudioEnded) return;
      isPlaying ? controls.play() : controls.pause();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPlaying, isLoading, isAudioEnded]
  );

  if (!tourStop) {
    return <div id="tour-preview-audio-tag">{audio}</div>;
  }

  return (
    <>
      <IonButton
        onClick={() => {
          state.muted ? controls.unmute() : controls.mute();
        }}
        color="primary"
        shape="round"
        className="absolute left-[22px] top-[135px] z-50 h-[28px] w-[28px]"
        disabled={isLoading}
      >
        <IonIcon
          icon={state.muted ? volumeMediumIcon : volumeMuteIcon}
          slot="icon-only"
        />
      </IonButton>
      <div id="tour-preview-audio-tag">{audio}</div>
    </>
  );
};

export default memo(PlayTourPreviewAudioButton);
