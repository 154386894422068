import React from "react";
import { useTranslation } from "react-i18next";

import StepHeader from "./StepHeader";
import AppCitySearch from "../../components/form/AppCitySearch";

const CityStep: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StepHeader
        number={1}
        title={t("createTour.form.city.title")}
        hint={t("createTour.form.city.hint")}
      />
      <AppCitySearch />
    </div>
  );
};

export default CityStep;
