import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  IonButton,
  IonContent,
  IonLabel,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { debounce, some } from "lodash-es";

import AppLayout from "../layouts/AppLayout";
import useIonVisible from "../hooks/useIonVisible";
import useAuthStore from "../stores/useAuthStore";
import { useTransaction } from "../contexts/TransactionContext";
import "./PremiumAccessPurchasingPage.scss";
import useRoutes from "../hooks/useRoutes";
import useSearchParams from "../hooks/useSearchParams";
import coverImage from "../assets/promotional-code/cover.png";
import AppInput from "../components/form/AppInput";
import { BackendValidationError } from "../interfaces/Interfaces";
import { getBackendValidationErrors } from "../helpers/error-helpers";
import {
  Transaction,
  useRedeemPromoCodeMutation,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useToast from "../hooks/useToast";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import FloatingBackButton from "../components/buttons/FloatingBackButton";

interface PromotionalCodeForm {
  code: string;
}

const PromotionalCodePage: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const history = useHistory();
  const { isVisible } = useIonVisible();
  const { hasPremiumAccess, activeTourTransactions, addCreatedTransaction } =
    useTransaction();
  const { t } = useTranslation();
  const { localePath, currentPath, loginPath } = useRoutes();
  const { code: codeParam } = useSearchParams();
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm<PromotionalCodeForm>();
  const { presentToast } = useToast();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [redeemPromoCodeMutation] = useRedeemPromoCodeMutation();

  const [backendErrors, setBackendErrors] = useState<
    BackendValidationError | null | undefined
  >(null);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);

  const [isLoaderShown, setIsLoaderShown] = useState<boolean>(false);
  const [code, setCode] = useState<string | undefined>(codeParam);

  useEffect(() => {
    const subscription = watch(
      debounce((value) => {
        setCode(value.code);
        const params = new URLSearchParams(location.search);
        value.code ? params.set("code", value.code) : params.delete("code");
        history.replace({ search: params.toString() });
      }, 300)
    );
    return () => subscription.unsubscribe();
  }, [watch, location.search, history]);

  useEffect(
    () => {
      if (isVisible) reset({ code });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible]
  );

  useEffect(
    () => {
      if (
        isVisible &&
        (hasPremiumAccess ||
          some(activeTourTransactions, ["promotionalCode", getValues("code")]))
      ) {
        setIsLoaderShown(false);
        router.push(localePath("user-profile"), "none", "pop");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPremiumAccess, activeTourTransactions, isVisible]
  );

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_VOUCHER_CODE);
    }
  });

  const redeemPromotionalCode = async (input: PromotionalCodeForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VOUCHER_CODE_REDEEM);
    }

    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    setIsLoaderShown(true);

    try {
      const { data } = await redeemPromoCodeMutation({ variables: { input } });
      const createdTransaction = data?.promoCode?.redeemPromoCode;
      if (createdTransaction) {
        addCreatedTransaction(createdTransaction as Transaction);
        presentToast(
          "promotionalCode.successMessageForPromotionalCodeRedemption"
        );
      }
    } catch (e: any) {
      setIsLoaderShown(false);
      let backendErrors = getBackendValidationErrors(e);

      if (backendErrors?.notValidationBackendError?.length) {
        backendErrors = {
          ...backendErrors,
          code: backendErrors?.notValidationBackendError,
        };
      }

      setBackendErrors(backendErrors);
    }
  };

  return (
    <AppLayout isLoaderShown={isLoaderShown}>
      <IonContent
        style={{
          "--padding-top": "var(--ion-safe-area-top)",
        }}
      >
        <div className="relative mx-auto min-h-full max-w-xl bg-white px-4 pt-[65px] text-center">
          <FloatingBackButton style={{ left: "6px" }} />

          <img src={coverImage} alt="" />

          <div className="px-3">
            <h2 className="px-5 pb-3 pt-8 text-[1.125rem] font-bold text-[#232437]">
              {t("promotionalCode.title")}
            </h2>

            <div className="mb-8 text-[0.75rem] text-[#535E69]">
              {t("promotionalCode.text")}
            </div>

            <form onSubmit={handleSubmit(redeemPromotionalCode)}>
              <IonLabel className="mb-3 block text-center text-[0.75rem] font-bold text-[#535E69]">
                {t("promotionalCode.form.code.label")}
              </IonLabel>
              <div className="mx-auto max-w-[250px]">
                <AppInput
                  placeholder="promotionalCode.form.code.placeholder"
                  name="code"
                  register={register}
                  validators={{
                    required: t("promotionalCode.form.code.errors.required"),
                  }}
                  frontendErrors={errors}
                  backendErrors={backendErrors}
                />
              </div>

              <IonButton
                className="mt-10 min-h-[56px] w-full text-[1rem] font-semibold normal-case"
                type="submit"
                shape="round"
                style={{
                  "--background": "#ec765a",
                  "--padding-start": "32px",
                  "--padding-end": "32px",
                  "--box-shadow": "none",
                }}
                disabled={isLoaderShown || !isUserInitialised}
              >
                {t("promotionalCode.form.buttons.redeem")}
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default PromotionalCodePage;
