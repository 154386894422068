import { useTranslation } from "react-i18next";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonIcon,
  useIonModal,
} from "@ionic/react";
import { Image, ResponsiveImageType } from "react-datocms";
import { locationSharp, playCircle as playCircleIcon } from "ionicons/icons";

import { TourWithCityDataForTourCard } from "../../interfaces/Interfaces";
import {
  TourRatingStatistics,
  UserTourAudioStatistics,
} from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useRoutes from "../../hooks/useRoutes";
import StarRating from "../StarRating";
import useAuthStore from "../../stores/useAuthStore";
import ProgressBar from "../ProgressBar";
import { ItemStatus } from "../../graphql/dato/__generated__/dato-graphql.generated";
import CategoryTags from "../category-tags/CategoryTags";
import headsetIcon from "../../assets/tour/headset.svg";
import swapCallsIcon from "../../assets/tour/swap-calls.svg";
import accessTimeIcon from "../../assets/tour/access-time.svg";
import {
  getTourAudioDurationInMinutes,
  isFreeTour,
} from "../../helpers/tour-helpers";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import { useCity } from "../../contexts/CityContext";
import IconsOfAvailableTourLocales from "./IconsOfAvailableTourLocales";
import RouteAnimationModal from "../modals/RouteAnimationModal";
import { useTransaction } from "../../contexts/TransactionContext";

const FullWidthTourCard: React.FC<{
  tour: TourWithCityDataForTourCard;
  tourRatingStatistics?: TourRatingStatistics;
  userTourAudioStatistics?: UserTourAudioStatistics;
}> = ({ tour, tourRatingStatistics, userTourAudioStatistics }) => {
  const { t } = useTranslation();
  const { tourPath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { currentCity } = useCity();
  const { hasPremiumAccess } = useTransaction();

  const [present, dismiss] = useIonModal(RouteAnimationModal, {
    tour,
    hasPremiumAccess,
    onDismiss: () => dismiss(),
  });

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const tourHighlightImage = tour.highlightImage
    ?.responsiveImage as ResponsiveImageType;

  const isDraftTour = tour._status === ItemStatus.draft;

  return (
    <IonCard
      routerLink={tourPath(tour)}
      routerDirection="forward"
      className="m-0 mb-2.5 rounded-[0px] shadow-none"
      onClick={() => {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.TOUR_CARD_CLICKING, {
            tourId: tour?.id,
            tourTitle: tour?.title,
          });
        }
      }}
    >
      {isDraftTour && (
        <div className="absolute right-0 top-0 mx-3 my-3">
          <div className="rounded-sm bg-slate-200/[0.85] px-2 py-0.5 text-sm font-medium text-slate-800 shadow">
            {t("dictionary.preview")}
          </div>
        </div>
      )}

      <IonCardHeader className="bg-white px-4 py-5">
        <div className="flex justify-between gap-5">
          <div className="flex flex-1 flex-col">
            {tourRatingStatistics && (
              <div className="mb-1.5">
                <StarRating
                  tourRatingStatistics={tourRatingStatistics}
                  starDimension="16px"
                  captionClassName="text-black text-[0.75rem] font-semibold ml-1"
                />
              </div>
            )}

            <div className="line-clamp-2 text-[1rem] font-bold leading-[1.5rem] text-[#232437]">
              {tour.title}
            </div>

            {tour.creatorProfile && (
              <div>
                <span className="text-[0.75rem] font-medium text-[#535E69]">
                  {tour.creatorProfile.creatorName}
                </span>
              </div>
            )}

            {isAuthenticated && (
              <div className="py-1">
                <ProgressBar
                  progress={userTourAudioStatistics?.audioProgress}
                />
              </div>
            )}

            {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
            <CategoryTags
              categories={tour.categories}
              wrapperClassName="my-2 line-clamp-2 min-h-[54px]"
            />
          </div>

          <div>
            {tourHighlightImage && (
              <div className="relative mb-1 h-[100px] w-full min-w-[100px]">
                <Image
                  data={tourHighlightImage}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            )}
            {!!tour.routeGeoJson && (
              <IonButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  present();
                }}
                fill="clear"
                color="primary"
                shape="round"
                className="m-0 text-[0.75rem] font-bold normal-case tracking-normal"
                style={{
                  "--padding-start": "0",
                  "--padding-end": "6px",
                }}
              >
                <IonIcon
                  icon={playCircleIcon}
                  className="mr-[2px] h-[34px] w-[34px] min-w-[34px]"
                />
                <span className="text-[#232437]">
                  {t("dictionary.preview")}
                </span>
              </IonButton>
            )}
          </div>
        </div>

        <div className="mb-2 flex">
          <IconsOfAvailableTourLocales tour={tour} />
          {!!tour?.city && (
            <div className="ml-2.5 flex text-[0.75rem] font-semibold text-[#687582]">
              <div className="mr-1.5 border-r-[2px] border-[#687582]" />
              <IonIcon
                icon={locationSharp}
                className="mr-0.5 h-[18px] min-w-[20px] text-[#687582]"
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("tourCard.distanceToCurrentCity", {
                    tourCityName: tour.city.name,
                    distanceToCurrentCity: tour.city.distanceToCurrentCity,
                    currentCityName: currentCity?.name,
                  }) as string,
                }}
              />
            </div>
          )}
        </div>

        <div className="flex justify-between">
          <div className="flex gap-2.5">
            {getTourAudioDurationInMinutes(tour) && (
              <div className="flex items-center">
                <IonIcon
                  src={headsetIcon}
                  className="mr-0.5 h-[20px] w-[20px]"
                />
                <div className="whitespace-nowrap text-[0.75rem] font-semibold text-[#687582]">
                  {getTourAudioDurationInMinutes(tour)}{" "}
                  {t("tour.minutesInShortForm")}
                </div>
              </div>
            )}

            {tour.distanceInKilometers && (
              <div className="flex items-center">
                <IonIcon
                  icon={swapCallsIcon}
                  className="mr-0.5 h-[20px] w-[20px]"
                />
                <div className="whitespace-nowrap text-[0.75rem] font-semibold text-[#687582]">
                  {tour.distanceInKilometers} {t("tour.kilometersInShortForm")}
                </div>
              </div>
            )}

            {tour.durationInMinutes && (
              <div className="flex items-center">
                <IonIcon
                  src={accessTimeIcon}
                  className="mr-0.5 h-[20px] w-[20px]"
                />
                <div className="whitespace-nowrap text-[0.75rem] font-semibold text-[#687582]">
                  {tour.durationInMinutes} {t("tour.minutesInShortForm")}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center text-[0.875rem] font-bold">
            {isFreeTour(tour) ? (
              <div className="rounded-[5px] bg-[#EAFFFA] p-2 text-[#3DAB91]">
                {t("tourCard.free")}
              </div>
            ) : (
              <div className="rounded-[5px] bg-[#9768084a] p-2 text-[#976808]">
                {t("tourCard.premium")}
              </div>
            )}
          </div>
        </div>
      </IonCardHeader>
    </IonCard>
  );
};

export default FullWidthTourCard;
