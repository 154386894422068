import { IonRadio, IonItem, IonRadioGroup } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import StepHeader from "./StepHeader";
import { ExperienceLevel } from "../../interfaces/Interfaces";

const RadioGroupSection: React.FC<{
  options: ExperienceLevel[];
  control: any;
  fieldName: string;
}> = ({ options, control, fieldName }) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <IonRadioGroup className="pb-2" value={value}>
          {options?.map((option) => (
            <IonItem
              key={option.id}
              lines="none"
              className="mb-4"
              style={{
                "--padding-start": 0,
                "--inner-padding-end": 0,
                "--padding-top": "5px",
                "--padding-bottom": "5px",
                "--border-radius": "10px",
                "--background-hover": "none",
              }}
              onClick={() => onChange(option.id)}
              button
            >
              <IonRadio
                slot="end"
                mode="md"
                color="primary"
                value={option.id}
              />
              <div>
                <div className="flex items-center">
                  <span className="font-medium text-[#232437]">
                    {option.title}
                  </span>
                </div>
                {!!option?.description && (
                  <div className="text-[0.875rem] text-[#687582]">
                    {option?.description}
                  </div>
                )}
              </div>
            </IonItem>
          ))}
        </IonRadioGroup>
      )}
    />
  );
};

const ExperienceLevelStep: React.FC<{
  options: ExperienceLevel[];
  control: any;
}> = ({ options, control }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StepHeader
        number={2}
        title={t("createTour.form.experienceLevel.title")}
        hint={t("createTour.form.experienceLevel.hint")}
      />
      <RadioGroupSection
        options={options}
        control={control}
        fieldName="datoExperienceLevelId"
      />
    </div>
  );
};

export default ExperienceLevelStep;
