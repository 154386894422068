import { memo, useRef, useState } from "react";
import Map, { MapRef } from "react-map-gl";
import { lineString } from "@turf/helpers";
import { useDebouncedEffect } from "@react-hookz/web";
import bbox from "@turf/bbox";

import { TourStop } from "../../interfaces/Interfaces";
import TourRoute from "./TourRoute";
import TourStopMarkers from "./TourStopMarkers";
import GeolocateControl from "./GeolocateControl";
import { setMapLanguage } from "../../helpers/map-helpers";
import { useLocale } from "../../contexts/LocaleContext";
import { getNextTourStop } from "../../helpers/tour-helpers";

const TourNavigationMap: React.FC<{
  currentTourStop: TourStop | null;
  initialZoom?: number;
  showGeolocateControl?: boolean;
  // uses for dynamic height of the map
  mapHeight?: number;
}> = ({
  currentTourStop,
  initialZoom = 13,
  showGeolocateControl,
  mapHeight,
}) => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();

  const [viewState, setViewState] = useState({
    latitude: currentTourStop?.location?.latitude,
    longitude: currentTourStop?.location?.longitude,
    zoom: initialZoom,
  });

  // fit current and next tour stops to the map
  useDebouncedEffect(
    () => {
      const nextTourStop = getNextTourStop(currentTourStop!);

      if (nextTourStop?.location && currentTourStop?.location) {
        const tourStopCoordinates = [
          [nextTourStop?.location?.longitude, nextTourStop?.location?.latitude],
          [
            currentTourStop?.location?.longitude,
            currentTourStop?.location?.latitude,
          ],
        ];
        const features = lineString(tourStopCoordinates);
        const [minLng, minLat, maxLng, maxLat] = bbox(features);

        mapRef?.current?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 40, duration: 1000 }
        );
      }
    },
    [currentTourStop, mapRef],
    10
  );

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(e) => setViewState(e.viewState)}
      attributionControl={false}
      reuseMaps={true}
      dragRotate={false}
      mapStyle="mapbox://styles/thomas-guidable/ckwju2mpv96fa14mplcpx79ld"
      style={
        mapHeight
          ? {
              width: "100%",
              height: mapHeight + "px",
            }
          : {}
      }
      onLoad={(e) => {
        setMapLanguage(e, locale);
      }}
    >
      {currentTourStop?.tour && (
        <>
          <TourRoute
            tour={currentTourStop?.tour}
            lineColor="#e38873"
            isActive={true}
          />
          <TourStopMarkers tour={currentTourStop?.tour} />
        </>
      )}

      {showGeolocateControl && <GeolocateControl mapRef={mapRef} />}
    </Map>
  );
};

export default memo(TourNavigationMap);
