import { useEffect, useState } from "react";

import { useLocale } from "../contexts/LocaleContext";
import { useTourStopByTourStopIdQuery } from "../graphql/dato/__generated__/dato-graphql.generated";
import { Story, StorySlide, TourStop } from "../interfaces/Interfaces";

const useTourStop = ({
  tourStopId,
  pause = false,
}: {
  tourStopId?: string | null;
  pause?: boolean;
}) => {
  const { tourQueryLocale } = useLocale();

  const [tourStop, setTourStop] = useState<TourStop | null>(null);

  const [tourStopByTourStopIdResult] = useTourStopByTourStopIdQuery({
    variables: { tourStopId: tourStopId!, locale: tourQueryLocale },
    pause: pause || !tourStopId,
  });

  const { data: tourStopByTourStopIdData } = tourStopByTourStopIdResult;

  // Set the tour stop on the local hook state
  useEffect(() => {
    const tourStop = prepareTourStop(
      tourStopByTourStopIdData?.tourStop as TourStop
    );
    if (tourStop) {
      setTourStop(tourStop);
    }
  }, [tourStopByTourStopIdData]);

  return { tourStop };
};

const prepareTourStop = (tourStopToPrepare: TourStop) => {
  if (!tourStopToPrepare) {
    return null;
  }

  const { stories, ...tourStopData } = tourStopToPrepare as TourStop;

  const tourStop = tourStopData as TourStop;

  tourStop.stories = stories?.map(({ storySlides, ...storyData }) => {
    const story = {
      ...storyData,
      // Set reference to tour stop
      tourStop,
    } as Story;

    story.storySlides = storySlides?.map((storySlide) => {
      return {
        ...storySlide,
        // Set reference to story
        story,
      } as StorySlide;
    });

    return story;
  });

  return tourStop;
};

export default useTourStop;
