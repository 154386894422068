import {
  Position,
  lineString as turfLineString,
  point as turfPoint,
  Units as TurfUnits,
} from "@turf/helpers";
import { length as turfLength } from "@turf/length";
import turfDistance from "@turf/distance";
import { bearing as turfBearing } from "@turf/bearing";

import { LatLonField } from "../graphql/dato/__generated__/dato-graphql.generated";

const getDistanceBetweenCoordinates = (
  location1?: LatLonField | null,
  location2?: LatLonField | null
) => {
  let distance = null;
  if (
    location1?.longitude &&
    location1?.latitude &&
    location2?.longitude &&
    location2?.latitude
  ) {
    const from = turfPoint([location1?.longitude, location1?.latitude]);
    const to = turfPoint([location2?.longitude, location2?.latitude]);
    const options: { units?: TurfUnits } = { units: "meters" };
    distance = turfDistance(from, to, options);
  }

  return distance;
};

// Calculates bearing between two coordinates
const calculateBearing = (start: number[], end: number[]): number => {
  var point1 = turfPoint(start);
  var point2 = turfPoint(end);
  return turfBearing(point1, point2);
};

const determineIsSignificantBearingChange = (
  newBearing: number,
  lastBearing: number,
  bearingTreshold: number
) => {
  const diff = Math.abs(((newBearing - lastBearing + 540) % 360) - 180);
  return diff > bearingTreshold;
};

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

const normalizeBearing = (bearing: number, currentBearing: number) => {
  // Normalize bearing to 0-360 range
  bearing = bearing % 360;
  if (bearing < 0) bearing += 360;

  const diff = bearing - currentBearing;

  if (diff > 180) bearing -= 360;
  if (diff < -180) bearing += 360;

  return bearing;
};

const getLineDistance = (coordinates: Position[]): number => {
  if (!coordinates.length) return 0;
  const line = turfLineString(coordinates);
  return turfLength(line, { units: "meters" });
};

export {
  getDistanceBetweenCoordinates,
  calculateBearing,
  determineIsSignificantBearingChange,
  lerp,
  normalizeBearing,
  getLineDistance,
};
