import { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  useIonRouter,
} from "@ionic/react";
import { generatePath } from "react-router";
import queryString from "query-string";
import clsx from "clsx";
import { find, get, head, reduce, some } from "lodash-es";
import { Icon } from "@iconify/react";

import AppLayout from "../layouts/AppLayout";
import useSearchParams from "../hooks/useSearchParams";
import "./LanguageSelectorPage.scss";
import {
  useStoryTranslationByIdQuery,
  useTourStopTranslationByIdQuery,
} from "../graphql/dato/__generated__/dato-graphql.generated";
import { ItemByLanguageOption } from "../interfaces/ItemByLanguageOption";
import { INITIAL_ITEM_BY_LANGUAGE_OPTIONS } from "../constants";

interface StoryByLanguageOption extends ItemByLanguageOption {
  storyId?: string;
}

interface TourStopByLanguageOption extends ItemByLanguageOption {
  storyId?: string;
  tourStopId?: string;
  tourId?: string;
}

const LanguageSelectorPage: React.FC = () => {
  const { storyTranslationId, tourStopTranslationId } = useSearchParams();
  const router = useIonRouter();

  const [selectedOption, setSelectedOption] = useState<
    (StoryByLanguageOption & TourStopByLanguageOption) | null
  >(null);
  const [options, setOptions] = useState<
    (StoryByLanguageOption & TourStopByLanguageOption)[]
  >([]);

  const [storyTranslationResult] = useStoryTranslationByIdQuery({
    variables: { storyTranslationId },
    pause: !storyTranslationId,
  });
  const allStoryLocales =
    storyTranslationResult?.data?.storyTranslation?._allStoryLocales;

  const [tourStopTranslationResult] = useTourStopTranslationByIdQuery({
    variables: { tourStopTranslationId },
    pause: !tourStopTranslationId,
  });
  const allTourStopLocales =
    tourStopTranslationResult?.data?.tourStopTranslation;

  useEffect(
    () => {
      if (allStoryLocales) {
        const updatedOptions = reduce(
          INITIAL_ITEM_BY_LANGUAGE_OPTIONS,
          (acc: StoryByLanguageOption[], option: StoryByLanguageOption) => {
            const storyId = get(
              find(allStoryLocales, ["locale", option.languageCode]),
              "value.id"
            );
            return storyId ? [...acc, { ...option, storyId }] : acc;
          },
          []
        );

        setOptions(updatedOptions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allStoryLocales]
  );

  useEffect(
    () => {
      if (allTourStopLocales) {
        const updatedOptions = reduce(
          INITIAL_ITEM_BY_LANGUAGE_OPTIONS,
          (
            acc: TourStopByLanguageOption[],
            option: TourStopByLanguageOption
          ) => {
            const tour = get(
              find(allTourStopLocales?._allTourLocales, [
                "locale",
                option.languageCode,
              ]),
              "value"
            );
            const tourId = tour?.id;

            const tourStop = get(
              find(allTourStopLocales?._allTourStopLocales, [
                "locale",
                option.languageCode,
              ]),
              "value"
            );
            const tourStopId = tourStop?.id;

            const storyId = get(head(tourStop?.stories), "id");

            // check if the current tour stop belongs to the current tour
            // if the tour is passed in the TourStopTranslation record
            const tourStops = tour?.tourStops;
            const isTourStopBelongsToTour = some(
              tourStops,
              (tourStop) => tourStop?.id === tourStopId
            );

            return storyId && tourStopId && (!tourId || isTourStopBelongsToTour)
              ? [...acc, { ...option, storyId, tourStopId, tourId }]
              : acc;
          },
          []
        );

        setOptions(updatedOptions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allTourStopLocales]
  );

  const navigateToItem = () => {
    if (selectedOption && (storyTranslationId || tourStopTranslationId)) {
      let route = "/";

      switch (true) {
        case !!tourStopTranslationId:
          route = generatePath("/:locale(de|en|fr|es)/story?:queryParams", {
            locale: selectedOption.languageCode,
            queryParams: queryString.stringify({
              storyId: selectedOption.storyId,
              tourStopId: selectedOption.tourStopId,
              tourId: selectedOption.tourId,
            }),
          });
          break;
        case !!storyTranslationId:
          route = generatePath("/:locale(de|en|fr|es)/story?:queryParams", {
            locale: selectedOption.languageCode,
            queryParams: queryString.stringify({
              storyId: selectedOption.storyId,
            }),
          });
          break;
      }

      router.push(route, "none", "replace");
      setSelectedOption(null);
      setOptions([]);
    }
  };

  return (
    <AppLayout>
      <IonContent>
        <div
          className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA] p-5 text-center"
          style={{ paddingTop: "calc(var(--ion-safe-area-top, 10px) + 50px)" }}
        >
          <p className="text-[1.375rem] text-[#414254]">Sprache wählen</p>
          <p className="mb-10 text-[0.75rem] text-[#979797]">Choose language</p>

          <IonList className="bg-[#FAFAFA]">
            <IonRadioGroup
              value={selectedOption?.tourStopId || selectedOption?.storyId}
            >
              {options?.map(
                (option: StoryByLanguageOption & TourStopByLanguageOption) => (
                  <IonItem
                    key={option.languageCode}
                    lines="none"
                    className={clsx(
                      option?.tourStopId === selectedOption?.tourStopId ||
                        option?.storyId === selectedOption?.storyId
                        ? "border-[#A4D5CD]"
                        : "border-white",
                      "item-by-language mb-2 rounded-xl border-[1px]"
                    )}
                    onClick={() => {
                      setSelectedOption(option);
                    }}
                  >
                    <IonRadio
                      slot="end"
                      mode="ios"
                      color="dark"
                      className="item-by-language-radio"
                      value={option.tourStopId || option?.storyId}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOption(option);
                      }}
                    />
                    <IonLabel>
                      <div className="flex items-center px-5 py-2 text-[1.25rem] text-[#414254]">
                        <Icon
                          icon={option.flag}
                          style={{
                            width: "26px",
                            height: "26px",
                            marginRight: "16px",
                          }}
                        />
                        {option.languageName}
                      </div>
                    </IonLabel>
                  </IonItem>
                )
              )}
            </IonRadioGroup>
          </IonList>
          <IonButton
            expand="block"
            size="large"
            className="mt-5 normal-case"
            onClick={navigateToItem}
          >
            Bestätigen (Confirm)
          </IonButton>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default LanguageSelectorPage;
