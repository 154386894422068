import { useCallback, useEffect, useState } from "react";
import { uniqBy } from "lodash-es";

import { useLocale } from "../contexts/LocaleContext";
import { useThemesQuery } from "../graphql/dato/__generated__/dato-graphql.generated";
import { Theme } from "../interfaces/Interfaces";

const useThemes = (isVisible: boolean) => {
  const { queryLocale, locale } = useLocale();

  const [
    themesInQueryLocale,
    setThemesInQueryLocale,
  ] = useState<{ [key: string]: Theme[] }>({});
  const [
    isAllThemesReceivedInQueryLocale,
    setIsAllThemesReceivedInQueryLocale,
  ] = useState<{ [key: string]: boolean }>({});

  const themesPageSize = 100;
  const [themesPageNumber, setThemesPageNumber] =
    useState(0);

  const themesQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: themesPageSize,
      skip: themesPageNumber * themesPageSize,
    };
  }, [queryLocale, themesPageNumber]);

  const [themesResult] = useThemesQuery({
    variables: themesQueryVariables(),
    pause: !isVisible,
  });

  const { data: themesData } = themesResult;

  // Set themes when data is available
  useEffect(
    () => {
      if (themesData?.themes) {
        // Add themes to the list
        const returnedThemes =
          themesData?.themes as Theme[];
        const newThemesInQueryLocale = uniqBy(
          [
            ...(themesInQueryLocale?.[locale] || []),
            ...returnedThemes,
          ],
          (theme) => theme.id
        );
        setThemesInQueryLocale({
          ...themesInQueryLocale,
          [locale]: newThemesInQueryLocale,
        });

        // Fetch more themes if available
        if (
          returnedThemes.length === themesPageSize
        ) {
          // By setting the page size another GraphQL query for the next page gets executed
          setThemesPageNumber(themesPageNumber + 1);
        } else {
          setIsAllThemesReceivedInQueryLocale({
            ...isAllThemesReceivedInQueryLocale,
            [locale]: true,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [themesData]
  );

  return {
    themesInQueryLocale,
    isAllThemesReceivedInQueryLocale,
  };
};

export default useThemes;
