import {
  IonButton,
  IonContent,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { chevronDownOutline, mapOutline, resizeOutline } from 'ionicons/icons';
import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Story, StorySlide, Tour } from '../../interfaces/Interfaces';
import storyPlayerBackground from '../../assets/backgrounds/story-player-background-3.svg';
import {
  PlaybackRateControl,
  SeekBar,
  SkipBackwardButton,
  SkipForwardButton,
  TogglePlayPauseButton
} from './MediaControls';
import SingleLocationMap from '../map/SingleLocationMap';
import { isIosVersion } from '../../helpers/device-helpers';
import useOnboardingHint from '../../hooks/useOnboardingHint';
import StoryPlayerMapButtonHint from '../onboardingHints/StoryPlayerMapButtonHint';
import StoryPlayerTextContentButtonHint from '../onboardingHints/StoryPlayerTextContentButtonHint';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import useContainerHeight from '../../hooks/useContainerHeight';

const StoryPlayerBottomMenu: React.FC<{
  storySlide: StorySlide;
  story: Story;
  tour?: Tour | null;
}> = ({ storySlide, story, tour }) => {
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [isSubtitlesModalOpen, setIsSubtitlesModalOpen] = useState<boolean>(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState<boolean>(false);
  const [isMapModalPresented, setIsMapModalPresented] = useState<boolean>(false);

  const mapButtonHintTargetRef = useRef<HTMLIonButtonElement>(null);
  const textContentHintTargetRef = useRef<HTMLIonButtonElement>(null);

  const { containerHeight, containerRef } =
    useContainerHeight(isMapModalPresented);

  useOnboardingHint({
    component: StoryPlayerMapButtonHint,
    hintTargetRef: mapButtonHintTargetRef,
    hintName: 'storyPlayerMapButton',
    isVisible: true,
  });

  useOnboardingHint({
    component: StoryPlayerTextContentButtonHint,
    hintTargetRef: textContentHintTargetRef,
    hintName: 'storyPlayerTextContentButton',
    isVisible: !!storySlide?.subtitles,
  });

  const openSubtitlesModal = () => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.STORY_PLAYER_OPEN_SCRIPT_BUTTON);
    }

    setIsSubtitlesModalOpen(true);
  };

  const closeSubtitlesModal = () => {
    setIsSubtitlesModalOpen(false);
  };

  const openMapModal = () => {
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  return (
    <>
      <div
        className="rounded-t-[20px] bg-white w-full z-10 p-[3px]"
        style={{
          paddingBottom: "calc(var(--ion-safe-area-bottom) + 3px)",
        }}
      >
        <div className="flex h-full items-center justify-between">
          <IonButton
            ref={mapButtonHintTargetRef}
            color="dark"
            size="small"
            fill="outline"
            className="normal-case mx-5"
            onClick={openMapModal}
          >
            { t("story.bottomMenu.buttons.map") }
            <IonIcon slot="start" icon={mapOutline} />
          </IonButton>

          {!!storySlide?.subtitles && <IonButton
            ref={textContentHintTargetRef}
            color="dark"
            size="small"
            fill="outline"
            className="normal-case mx-5"
            onClick={openSubtitlesModal}
          >
            { t("story.bottomMenu.buttons.showText") }
            <IonIcon slot="end" icon={resizeOutline} />
          </IonButton>}
        </div>
      </div>

      <IonModal
        isOpen={isSubtitlesModalOpen}
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated={!isIosVersion(17)}
        backdropDismiss={false}
        className="max-w-xl mx-auto"
        style={{ '--height': '100vh' }}
      >
        <IonContent>
          <div
            className="relative z-10 mx-auto flex min-h-full flex-col items-center justify-between bg-cover bg-center"
            style={{
              backgroundImage: `url(${storyPlayerBackground})`,
              paddingBottom: "calc(var(--ion-safe-area-bottom) + 3px)",
              paddingTop: "calc(var(--ion-safe-area-top) + 3px)",
            }}
          >
            <div className="p-5">
              <div className="flex justify-end">
                <IonButton
                  fill="clear"
                  color="light"
                  onClick={closeSubtitlesModal}
                >
                  <IonIcon slot="icon-only" icon={chevronDownOutline} />
                </IonButton>
              </div>

              <div className="text-white font-semibold text-[1.375rem]">
                {storySlide?.subtitles}
              </div>
            </div>

            <div className="w-full">
              {/* Seek bar */}
              <div className="z-10 -mb-2 -mt-3 w-full px-4">
                <SeekBar />
              </div>

              {/* Media controls */}
              <div className="z-10 flex w-full items-center justify-between px-5 pb-[50px] text-white/95">
                <PlaybackRateControl />
                <SkipBackwardButton />
                <TogglePlayPauseButton />
                <SkipForwardButton />
                <div className="pl-[30px]"/>
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={isMapModalOpen}
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated={!isIosVersion(17)}
        backdropDismiss={false}
        className="max-w-xl mx-auto"
        style={{ '--height': '100vh' }}
        onDidPresent={() => setIsMapModalPresented(true)}
        onWillDismiss={() => setIsMapModalPresented(false)}
      >
        <IonContent>
          <div
            className="relative z-10 w-full h-full flex min-h-full flex-col items-center justify-between bg-cover bg-center"
            style={{
              backgroundImage: `url(${storyPlayerBackground})`,
              paddingBottom: "calc(var(--ion-safe-area-bottom) + 3px)",
              paddingTop: "calc(var(--ion-safe-area-top) + 3px)",
            }}
          >
            <div className="flex flex-col w-full h-full p-5">
              <div className="flex justify-end">
                <IonButton
                  fill="clear"
                  color="light"
                  onClick={closeMapModal}
                >
                  <IonIcon slot="icon-only" icon={chevronDownOutline} />
                </IonButton>
              </div>

              <div ref={containerRef} className="flex-1">
                {!!containerHeight && <SingleLocationMap
                  location={story?.location}
                  initialZoom={17}
                  markerScale={0.5}
                  showGeolocateControl={true}
                  mapHeight={containerHeight}
                  currentTour={tour}
                />}
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default memo(StoryPlayerBottomMenu);
