import { IonCard, IonCardHeader, IonIcon } from "@ionic/react";
import { Image, ResponsiveImageType } from "react-datocms";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";

import { Tour } from "../../interfaces/Interfaces";
import { ItemStatus } from "../../graphql/dato/__generated__/dato-graphql.generated";
import CategoryTags from "../category-tags/CategoryTags";
import StarRating from "../StarRating";
import {
  TourRatingStatistics,
  UserTourAudioStatistics,
} from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";
import ProgressBar from "../ProgressBar";
import useAuthStore from "../../stores/useAuthStore";
import accessTimeIcon from "../../assets/tour/access-time.svg";
import headsetIcon from "../../assets/tour/headset.svg";
import swapCallsIcon from "../../assets/tour/swap-calls.svg";
import { useLocale } from "../../contexts/LocaleContext";
import {
  getTourAudioDurationInMinutes,
  isFreeTour,
} from "../../helpers/tour-helpers";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import PlayRouteTextButton from "../buttons/PlayRouteTextButton";
import { useTransaction } from "../../contexts/TransactionContext";

const TourCard: React.FC<{
  tour: Tour;
  tourRatingStatistics?: TourRatingStatistics;
  userTourAudioStatistics?: UserTourAudioStatistics;
}> = ({ tour, tourRatingStatistics, userTourAudioStatistics }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { hasPremiumAccess } = useTransaction();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const tourHighlightImage = tour.highlightImage
    ?.responsiveImage as ResponsiveImageType;

  const isDraftTour = tour._status === ItemStatus.draft;

  return (
    <IonCard
      routerLink={
        tour.slug
          ? generatePath("/:locale(de|en|fr|es)/tours/:tourSlug", {
              locale,
              tourSlug: tour.slug,
            })
          : undefined
      }
      routerDirection="forward"
      className="m-1 rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]"
      onClick={() => {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.TOUR_CARD_CLICKING, {
            tourId: tour?.id,
            tourTitle: tour?.title,
          });
        }
      }}
    >
      <div className="relative bg-white pb-[18px]">
        <div className="relative aspect-[4/3] bg-gray-300">
          {!!tourHighlightImage && (
            <Image data={tourHighlightImage} layout="fill" objectFit="cover" />
          )}
        </div>
        {!!tour.routeGeoJson && (
          <PlayRouteTextButton
            tour={tour}
            hasPremiumAccess={hasPremiumAccess}
          />
        )}
      </div>
      {isDraftTour && (
        <div className="absolute right-0 top-0 mx-3 my-3">
          <div className="rounded-sm bg-slate-200/[0.85] px-2 py-0.5 text-sm font-medium text-slate-800 shadow">
            {t("dictionary.preview")}
          </div>
        </div>
      )}
      <IonCardHeader className="flex min-h-[7.25rem] flex-col justify-between bg-white px-4 pb-4 pt-3.5">
        {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
        <CategoryTags
          categories={tour.categories}
          wrapperClassName="my-1 line-clamp-2 min-h-[54px]"
        />

        <div className="mb-1 line-clamp-2 min-h-[48px] text-[1.25rem] font-bold leading-[1.5rem] text-[#232437]">
          {tour.title}
        </div>

        {tour.creatorProfile && (
          <div className="mr-1">
            <span className="text-[1rem] font-medium text-[#535E69]">
              {tour.creatorProfile.creatorName}
            </span>
          </div>
        )}

        <div className="min-h-[21px]">
          {tourRatingStatistics && (
            <StarRating tourRatingStatistics={tourRatingStatistics} />
          )}
        </div>

        {isAuthenticated && (
          <div className="py-2">
            <ProgressBar progress={userTourAudioStatistics?.audioProgress} />
          </div>
        )}

        <div className="grow" />

        <div className="mb-4 mt-1.5 border-b border-[D5D6DC] opacity-40" />

        <div className="flex flex-wrap justify-between gap-2">
          <div className="flex">
            {getTourAudioDurationInMinutes(tour) && (
              <div className="mr-3 flex flex-col items-center">
                <IonIcon
                  src={headsetIcon}
                  className="mb-0.5 h-[20px] w-[20px]"
                />
                <div className="whitespace-nowrap text-[0.75rem] font-semibold text-[#687582]">
                  {getTourAudioDurationInMinutes(tour)}{" "}
                  {t("tour.minutesInShortForm")}
                </div>
              </div>
            )}

            {tour.distanceInKilometers && (
              <div className="mr-3 flex flex-col items-center">
                <IonIcon
                  icon={swapCallsIcon}
                  className="mb-0.5 h-[20px] w-[20px]"
                />
                <div className="whitespace-nowrap text-[0.75rem] font-semibold text-[#687582]">
                  {tour.distanceInKilometers} {t("tour.kilometersInShortForm")}
                </div>
              </div>
            )}

            {tour.durationInMinutes && (
              <div className="flex flex-col items-center">
                <IonIcon
                  src={accessTimeIcon}
                  className="mb-0.5 h-[20px] w-[20px]"
                />
                <div className="whitespace-nowrap text-[0.75rem] font-semibold text-[#687582]">
                  {tour.durationInMinutes} {t("tour.minutesInShortForm")}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center text-[0.875rem] font-bold">
            {isFreeTour(tour) ? (
              <div className="rounded-[5px] bg-[#EAFFFA] p-2 text-[#3DAB91]">
                {t("tourCard.free")}
              </div>
            ) : (
              <div className=" rounded-[5px] bg-[#9768084a] p-2 text-[#976808]">
                {t("tourCard.premium")}
              </div>
            )}
          </div>
        </div>
      </IonCardHeader>
    </IonCard>
  );
};

export default TourCard;
