import {
  IonButton,
  IonContent,
  IonIcon,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { pick } from "lodash-es";
import { closeOutline, chevronBackOutline } from "ionicons/icons";
import clsx from "clsx";

import AppLayout from "../layouts/AppLayout";
import useError from "../hooks/useError";
import useAuthStore from "../stores/useAuthStore";
import useExperienceLevels from "../hooks/useExperienceLevels";
import { Theme, ExperienceLevel } from "../interfaces/Interfaces";
import { useLocale } from "../contexts/LocaleContext";
import {
  CreateCityInput,
  CreateTourByGptInput,
  useCreateTourByGptMutation,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useRoutes from "../hooks/useRoutes";
import useToast from "../hooks/useToast";
import { useCity } from "../contexts/CityContext";
import { useTourCreationRequest } from "../contexts/TourCreationRequestContext";
import useIonVisible from "../hooks/useIonVisible";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import starsIcon from "../assets/tour-creation/stars.svg";
import AppButton from "../components/buttons/AppButton";
import useThemes from "../hooks/useThemes";
import DurationStep from "./CreateTourPage/DurationStep";
import ThemeStep from "./CreateTourPage/ThemeStep";
import ExperienceLevelStep from "./CreateTourPage/ExperienceLevelStep";
import CityStep from "./CreateTourPage/CityStep";
import FloatingBackButton from "../components/buttons/FloatingBackButton";

const Steps: React.FC<{
  currentStep: number;
  control: any;
  register: any;
  experienceLevelsInQueryLocale: Record<string, ExperienceLevel[]>;
  themesInQueryLocale: Record<string, Theme[]>;
  locale: string;
}> = ({
  currentStep,
  control,
  register,
  experienceLevelsInQueryLocale,
  themesInQueryLocale,
  locale,
}) => {
  switch (currentStep) {
    case 1:
      return <CityStep />;
    case 2:
      return (
        <ExperienceLevelStep
          options={experienceLevelsInQueryLocale[locale]}
          control={control}
        />
      );
    case 3:
      return (
        <ThemeStep options={themesInQueryLocale[locale]} control={control} />
      );
    case 4:
      return <DurationStep register={register} />;
    default:
      return null;
  }
};

const CreateTourPage: React.FC = () => {
  const { t } = useTranslation();
  const { handleBackendError } = useError();
  const { isVisible } = useIonVisible();
  const { themesInQueryLocale } = useThemes(isVisible);
  const { experienceLevelsInQueryLocale } = useExperienceLevels(isVisible);
  const { locale } = useLocale();
  const router = useIonRouter();
  const { currentPath, loginPath, localePath } = useRoutes();
  const { presentToast } = useToast();
  const { currentCity } = useCity();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const {
    tourCreationRequestStatisticsByUser,
    numberOfAvailableTourCreationRequests,
    setTourCreationRequestStatisticsByUser,
  } = useTourCreationRequest();

  const [createTourByGptMutation] = useCreateTourByGptMutation();

  const { register, reset, control, handleSubmit, watch, unregister } =
    useForm<CreateTourByGptInput>();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [currentStep, setCurrentStep] = useState(1);

  // Watch the form values we need to validate
  const formSubscription = watch();

  useIonViewWillLeave(() => {
    // Cleanup form state when leaving the page
    unregister(["datoExperienceLevelId", "datoThemeId", "tourDuration"]);
    setCurrentStep(1);
  });

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_CREATION);
    }
  });

  useEffect(
    () => {
      resetForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, currentCity]
  );

  const resetForm = async () => {
    let cityData = {};

    if (currentCity) {
      cityData = currentCity.id
        ? { datoCityId: currentCity.id }
        : {
            cityData: pick(currentCity, [
              "name",
              "location",
            ]) as CreateCityInput,
          };
    }

    await reset({
      ...cityData,
      tourDuration: 30,
      datoExperienceLevelId: "",
      datoThemeId: "",
    });
  };

  const createTour = async (input: CreateTourByGptInput) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.CREATE_TOUR, {
        input,
        currentCityId: currentCity?.id,
        currentCityName: currentCity?.name,
      });
    }

    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    resetForm();
    router.push(localePath("user-profile/created-tours"), "forward", "pop", {
      unmount: true,
    });

    await handleBackendError(async () => {
      const { errors } = await createTourByGptMutation({
        variables: { input },
      });
      if (errors) return errors;

      setTourCreationRequestStatisticsByUser({
        ...tourCreationRequestStatisticsByUser,
        inProgress: tourCreationRequestStatisticsByUser?.inProgress + 1,
      });

      presentToast(
        "createTour.successMessageForTourCreationRequest",
        "secondary",
        20000,
        "top",
        [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ]
      );
    });
  };

  const isStepValid = () => {
    switch (currentStep) {
      case 1:
        return !!(
          currentCity?.id ||
          (currentCity?.name && currentCity?.location)
        );
      case 2:
        return !!formSubscription.datoExperienceLevelId;
      case 3:
        return !!formSubscription.datoThemeId;
      case 4:
        return !!formSubscription.tourDuration;
      default:
        return false;
    }
  };

  return (
    <AppLayout>
      <IonContent>
        <div
          className="relative mx-auto flex min-h-full max-w-xl flex-col bg-[#FAFAFA] px-5 pb-[140px]"
          style={{
            marginTop: "var(--ion-safe-area-top)",
          }}
        >
          <div className="relative flex items-center gap-1 py-10 pl-10">
            {currentStep > 1 ? (
              <IonButton
                onClick={() => setCurrentStep(currentStep - 1)}
                fill="clear"
                shape="round"
                className="absolute -left-1 top-1/2 m-0 -translate-y-1/2"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  slot="icon-only"
                  className="h-10 w-10"
                />
              </IonButton>
            ) : (
              <FloatingBackButton
                style={{
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "-10px",
                }}
              />
            )}
            {[1, 2, 3, 4].map((step) => (
              <div
                key={step}
                className={clsx(
                  "h-1 flex-1 rounded-full",
                  step <= currentStep ? "bg-primary" : "bg-gray-300"
                )}
              />
            ))}
          </div>

          <form
            onSubmit={handleSubmit(createTour)}
            className="flex flex-1 flex-col"
          >
            <Steps
              currentStep={currentStep}
              control={control}
              register={register}
              experienceLevelsInQueryLocale={experienceLevelsInQueryLocale}
              themesInQueryLocale={themesInQueryLocale}
              locale={locale}
            />

            <div className="fixed bottom-0 left-1/2 mt-auto w-full max-w-xl -translate-x-1/2 bg-[#FAFAFA] px-5">
              <div className="mb-2">
                {currentStep < 4 ? (
                  <AppButton
                    type="button"
                    shape="round"
                    disabled={!isStepValid()}
                    onClick={() => setCurrentStep(currentStep + 1)}
                  >
                    {t("createTour.form.buttons.continue")}
                  </AppButton>
                ) : (
                  <AppButton
                    type="submit"
                    shape="round"
                    withGradient
                    disabled={!isStepValid()}
                  >
                    <IonIcon src={starsIcon} className="mr-1" />
                    {t("createTour.form.buttons.create")}
                  </AppButton>
                )}
              </div>
              {!!numberOfAvailableTourCreationRequests && (
                <div className="mb-4 flex items-center justify-center text-[0.875rem] text-[#848D96]">
                  <div className="mr-2 h-[6px] w-[6px] rounded-full bg-[#E59953]" />
                  <span
                    className="text-[#535E69]"
                    dangerouslySetInnerHTML={{
                      __html: t("createTour.statistics.message", {
                        limit: numberOfAvailableTourCreationRequests?.limit,
                        remainder:
                          numberOfAvailableTourCreationRequests?.remainder,
                      })!,
                    }}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreateTourPage;
