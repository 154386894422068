// THIS FILE IS GENERATED WITH GRAPHQL-CODEGEN, DO NOT EDIT!

import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BooleanType: any;
  CustomData: any;
  DateTime: any;
  FloatType: any;
  IntType: any;
  ItemId: any;
  JsonField: any;
  MetaTagAttributes: any;
  UploadId: any;
};

export type AgeRangeModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<AgeRangeModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AgeRangeModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  from?: InputMaybe<IntegerFilter>;
  id?: InputMaybe<ItemIdFilter>;
  position?: InputMaybe<PositionFilter>;
  title?: InputMaybe<StringFilter>;
  to?: InputMaybe<IntegerFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum AgeRangeModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  from_ASC = "from_ASC",
  from_DESC = "from_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  to_ASC = "to_ASC",
  to_DESC = "to_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Age Range (age_range) */
export type AgeRangeRecord = RecordInterface & {
  __typename?: "AgeRangeRecord";
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  from?: Maybe<Scalars["IntType"]>;
  id: Scalars["ItemId"];
  position?: Maybe<Scalars["IntType"]>;
  title?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["IntType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Age Range (age_range) */
export type AgeRangeRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Age Range (age_range) */
export type AgeRangeRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Age Range (age_range) */
export type AgeRangeRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type AppVersionFeatureModelButtonsField =
  | LinkButtonRecord
  | PlaceholderBlockRecord;

export type AppVersionFeatureModelButtonsFieldListListNonNullMultiLocaleField =
  {
    __typename?: "AppVersionFeatureModelButtonsFieldListListNonNullMultiLocaleField";
    locale?: Maybe<SiteLocale>;
    value: Array<AppVersionFeatureModelButtonsField>;
  };

export type AppVersionFeatureModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<AppVersionFeatureModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AppVersionFeatureModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  android?: InputMaybe<BooleanFilter>;
  appVersion?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  ios?: InputMaybe<BooleanFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
  web?: InputMaybe<BooleanFilter>;
};

export enum AppVersionFeatureModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  android_ASC = "android_ASC",
  android_DESC = "android_DESC",
  appVersion_ASC = "appVersion_ASC",
  appVersion_DESC = "appVersion_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  ios_ASC = "ios_ASC",
  ios_DESC = "ios_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  web_ASC = "web_ASC",
  web_DESC = "web_DESC",
}

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecord = RecordInterface & {
  __typename?: "AppVersionFeatureRecord";
  _allButtonsLocales?: Maybe<
    Array<AppVersionFeatureModelButtonsFieldListListNonNullMultiLocaleField>
  >;
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  android?: Maybe<Scalars["BooleanType"]>;
  appVersion?: Maybe<Scalars["String"]>;
  buttons: Array<AppVersionFeatureModelButtonsField>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  ios?: Maybe<Scalars["BooleanType"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  web?: Maybe<Scalars["BooleanType"]>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecord_AllButtonsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecordButtonsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type App Version Features (app_version_feature) */
export type AppVersionFeatureRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Application (application) */
export type ApplicationRecord = RecordInterface & {
  __typename?: "ApplicationRecord";
  _allHomePageTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allHomePageTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  defaultCityImage?: Maybe<FileField>;
  homePageImage?: Maybe<FileField>;
  homePageText?: Maybe<Scalars["String"]>;
  homePageTitle?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Application (application) */
export type ApplicationRecord_AllHomePageTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Application (application) */
export type ApplicationRecord_AllHomePageTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Application (application) */
export type ApplicationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Application (application) */
export type ApplicationRecordHomePageTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Application (application) */
export type ApplicationRecordHomePageTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["BooleanType"]>;
};

export type CategoryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CategoryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CategoryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  color?: InputMaybe<ColorFilter>;
  colorIcon?: InputMaybe<FileFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<StringFilter>;
  icon?: InputMaybe<FileFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  isAvailableForContentCategorizationByAi?: InputMaybe<BooleanFilter>;
  isAvailableForDynamicTourCreation?: InputMaybe<BooleanFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum CategoryModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isAvailableForContentCategorizationByAi_ASC = "isAvailableForContentCategorizationByAi_ASC",
  isAvailableForContentCategorizationByAi_DESC = "isAvailableForContentCategorizationByAi_DESC",
  isAvailableForDynamicTourCreation_ASC = "isAvailableForDynamicTourCreation_ASC",
  isAvailableForDynamicTourCreation_DESC = "isAvailableForDynamicTourCreation_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Category (category) */
export type CategoryRecord = RecordInterface & {
  __typename?: "CategoryRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  color?: Maybe<ColorField>;
  colorIcon?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<FileField>;
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  isAvailableForContentCategorizationByAi?: Maybe<Scalars["BooleanType"]>;
  isAvailableForDynamicTourCreation?: Maybe<Scalars["BooleanType"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Category (category) */
export type CategoryRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Category (category) */
export type CategoryRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Category (category) */
export type CategoryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Category (category) */
export type CategoryRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Category (category) */
export type CategoryRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type CityModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CityModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CityModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  country?: InputMaybe<LinkFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isImageVisibleOnMap?: InputMaybe<BooleanFilter>;
  isPopular?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<LatLonFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
  preview?: InputMaybe<CityModelPreviewFieldFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum CityModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isImageVisibleOnMap_ASC = "isImageVisibleOnMap_ASC",
  isImageVisibleOnMap_DESC = "isImageVisibleOnMap_DESC",
  isPopular_ASC = "isPopular_ASC",
  isPopular_DESC = "isPopular_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Specify nested blocks fields conditions */
export type CityModelPreviewFieldBlocksConditions = {
  image?: InputMaybe<ImageModelFilter>;
};

/** Specify nested blocks types presence */
export type CityModelPreviewFieldBlocksPresence = {
  image?: InputMaybe<Scalars["BooleanType"]>;
};

/** Specify how to filter this specific Modular Content field instance */
export type CityModelPreviewFieldFilter = {
  /** Filter records containing at least one block matching the specified conditions */
  any?: InputMaybe<CityModelPreviewFieldBlocksConditions>;
  /** Filter records containing at least one block of specified type or not */
  containsAny?: InputMaybe<CityModelPreviewFieldBlocksPresence>;
  /** Filter records containing at least one block of any kind or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
};

/** Record of type City (city) */
export type CityRecord = RecordInterface & {
  __typename?: "CityRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allReferencingSightseeingSpots: Array<SightseeingSpotRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingSightseeingSpotsMeta: CollectionMetadata;
  _allReferencingStories: Array<StoryRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingStoriesMeta: CollectionMetadata;
  _allReferencingTourCollections: Array<TourCollectionRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingTourCollectionsMeta: CollectionMetadata;
  _allReferencingTours: Array<TourRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingToursMeta: CollectionMetadata;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  country?: Maybe<CountryRecord>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isImageVisibleOnMap?: Maybe<Scalars["BooleanType"]>;
  isPopular?: Maybe<Scalars["BooleanType"]>;
  location?: Maybe<LatLonField>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["IntType"]>;
  preview: Array<ImageRecord>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type City (city) */
export type CityRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type City (city) */
export type CityRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingSightseeingSpotsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SightseeingSpotModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SightseeingSpotModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenSightseeingSpotAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingSightseeingSpotsMetaArgs = {
  filter?: InputMaybe<SightseeingSpotModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenSightseeingSpotAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingStoriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoryModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenStoryAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingStoriesMetaArgs = {
  filter?: InputMaybe<StoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenStoryAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingTourCollectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourCollectionModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourCollectionAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingTourCollectionsMetaArgs = {
  filter?: InputMaybe<TourCollectionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourCollectionAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingToursArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllReferencingToursMetaArgs = {
  filter?: InputMaybe<TourModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourAndCity>;
};

/** Record of type City (city) */
export type CityRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type City (city) */
export type CityRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type City (city) */
export type CityRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type City (city) */
export type CityRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type City (city) */
export type CityRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type CollectionMetadata = {
  __typename?: "CollectionMetadata";
  count: Scalars["IntType"];
};

export enum ColorBucketType {
  black = "black",
  blue = "blue",
  brown = "brown",
  cyan = "cyan",
  green = "green",
  grey = "grey",
  orange = "orange",
  pink = "pink",
  purple = "purple",
  red = "red",
  white = "white",
  yellow = "yellow",
}

export type ColorField = {
  __typename?: "ColorField";
  alpha: Scalars["IntType"];
  blue: Scalars["IntType"];
  cssRgb: Scalars["String"];
  green: Scalars["IntType"];
  hex: Scalars["String"];
  red: Scalars["IntType"];
};

/** Specifies how to filter Color fields */
export type ColorFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
};

export type ContinentModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContinentModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContinentModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  codeAlphaTwo?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum ContinentModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  codeAlphaTwo_ASC = "codeAlphaTwo_ASC",
  codeAlphaTwo_DESC = "codeAlphaTwo_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Continent (continent) */
export type ContinentRecord = RecordInterface & {
  __typename?: "ContinentRecord";
  _allNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  codeAlphaTwo?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Continent (continent) */
export type ContinentRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Continent (continent) */
export type ContinentRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Continent (continent) */
export type ContinentRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type CountryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  codeAlphaTwo?: InputMaybe<StringFilter>;
  continent?: InputMaybe<LinkFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum CountryModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  codeAlphaTwo_ASC = "codeAlphaTwo_ASC",
  codeAlphaTwo_DESC = "codeAlphaTwo_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Country (country) */
export type CountryRecord = RecordInterface & {
  __typename?: "CountryRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  codeAlphaTwo?: Maybe<Scalars["String"]>;
  continent?: Maybe<ContinentRecord>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Country (country) */
export type CountryRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Country (country) */
export type CountryRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Country (country) */
export type CountryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Country (country) */
export type CountryRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Country (country) */
export type CountryRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

export type CreatorProfileModelAboutMeBlocksField =
  | ImageRecord
  | PlaceholderBlockRecord
  | VideoRecord;

export type CreatorProfileModelAboutMeField = {
  __typename?: "CreatorProfileModelAboutMeField";
  blocks: Array<CreatorProfileModelAboutMeBlocksField>;
  links: Array<Scalars["String"]>;
  value: Scalars["JsonField"];
};

export type CreatorProfileModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CreatorProfileModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CreatorProfileModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  aboutMe?: InputMaybe<StructuredTextFilter>;
  audioPreview?: InputMaybe<FileFilter>;
  categories?: InputMaybe<LinksFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  creatorName?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isDiscoverable?: InputMaybe<BooleanFilter>;
  position?: InputMaybe<PositionFilter>;
  profileImage?: InputMaybe<FileFilter>;
  slogan?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
  username?: InputMaybe<SlugFilter>;
};

export enum CreatorProfileModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  creatorName_ASC = "creatorName_ASC",
  creatorName_DESC = "creatorName_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isDiscoverable_ASC = "isDiscoverable_ASC",
  isDiscoverable_DESC = "isDiscoverable_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  slogan_ASC = "slogan_ASC",
  slogan_DESC = "slogan_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Creator Profile (creator_profile) */
export type CreatorProfileRecord = RecordInterface & {
  __typename?: "CreatorProfileRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  aboutMe?: Maybe<CreatorProfileModelAboutMeField>;
  audioPreview?: Maybe<FileField>;
  categories: Array<CategoryRecord>;
  createdAt: Scalars["DateTime"];
  creatorName?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isDiscoverable?: Maybe<Scalars["BooleanType"]>;
  position?: Maybe<Scalars["IntType"]>;
  profileImage?: Maybe<FileField>;
  slogan?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  username?: Maybe<Scalars["String"]>;
};

/** Record of type Creator Profile (creator_profile) */
export type CreatorProfileRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ExperienceLevelModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExperienceLevelModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExperienceLevelModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  color?: InputMaybe<ColorFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  position?: InputMaybe<PositionFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum ExperienceLevelModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Experience Level (experience_level) */
export type ExperienceLevelRecord = RecordInterface & {
  __typename?: "ExperienceLevelRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  color?: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  position?: Maybe<Scalars["IntType"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Experience Level (experience_level) */
export type ExperienceLevelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Experience Level (experience_level) */
export type ExperienceLevelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Experience Level (experience_level) */
export type ExperienceLevelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Experience Level (experience_level) */
export type ExperienceLevelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Experience Level (experience_level) */
export type ExperienceLevelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum FaviconType {
  appleTouchIcon = "appleTouchIcon",
  icon = "icon",
  msApplication = "msApplication",
}

export type FileField = FileFieldInterface & {
  __typename?: "FileField";
  _createdAt: Scalars["DateTime"];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars["String"]>;
  _updatedAt: Scalars["DateTime"];
  alt?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurUpThumb?: Maybe<Scalars["String"]>;
  blurhash?: Maybe<Scalars["String"]>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars["String"]>;
  customData: Scalars["CustomData"];
  exifInfo: Scalars["CustomData"];
  filename: Scalars["String"];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars["String"];
  height?: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  thumbhash?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars["IntType"]>;
};

export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars["Float"];
  quality?: Scalars["Int"];
  size?: Scalars["Int"];
};

export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars["String"]>;
};

export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars["DateTime"];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars["String"]>;
  _updatedAt: Scalars["DateTime"];
  alt?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurUpThumb?: Maybe<Scalars["String"]>;
  blurhash?: Maybe<Scalars["String"]>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars["String"]>;
  customData: Scalars["CustomData"];
  exifInfo: Scalars["CustomData"];
  filename: Scalars["String"];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars["String"];
  height?: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  thumbhash?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars["IntType"]>;
};

export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars["Float"];
  quality?: Scalars["Int"];
  size?: Scalars["Int"];
};

export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars["String"]>;
};

export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldMultiLocaleField = {
  __typename?: "FileFieldMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<FileField>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars["UploadId"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars["UploadId"]>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Specifies how to filter Floating-point fields */
export type FloatFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["FloatType"]>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["FloatType"]>;
};

export type GenderModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<GenderModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GenderModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  position?: InputMaybe<PositionFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum GenderModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Gender (gender) */
export type GenderRecord = RecordInterface & {
  __typename?: "GenderRecord";
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  position?: Maybe<Scalars["IntType"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Gender (gender) */
export type GenderRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Gender (gender) */
export type GenderRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Gender (gender) */
export type GenderRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type GlobalSeoField = {
  __typename?: "GlobalSeoField";
  facebookPageUrl?: Maybe<Scalars["String"]>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars["String"]>;
  titleSuffix?: Maybe<Scalars["String"]>;
  twitterAccount?: Maybe<Scalars["String"]>;
};

/** Block of type Image Comparer (image_comparer) */
export type ImageComparerRecord = RecordInterface & {
  __typename?: "ImageComparerRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  caption?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  imageAfter?: Maybe<FileField>;
  imageBefore?: Maybe<FileField>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image Comparer (image_comparer) */
export type ImageComparerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageModelFilter>>>;
  caption?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
};

/** Block of type Image (image) */
export type ImageRecord = RecordInterface & {
  __typename?: "ImageRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  caption?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image (image) */
export type ImageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars["String"]>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars["String"]>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace?: InputMaybe<Scalars["String"]>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt?: InputMaybe<Scalars["String"]>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars["String"]>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars["String"]>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars["IntType"]>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars["String"]>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars["IntType"]>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars["String"]>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars["String"]>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars["IntType"]>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars["IntType"]>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars["IntType"]>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars["IntType"]>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars["IntType"]>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars["IntType"]>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars["String"]>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars["String"]>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars["IntType"]>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars["String"]>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars["IntType"]>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars["IntType"]>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars["IntType"]>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars["IntType"]>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars["IntType"]>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars["String"]>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt?: InputMaybe<Scalars["String"]>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos?: InputMaybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt?: InputMaybe<Scalars["String"]>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed?: InputMaybe<Scalars["IntType"]>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs?: InputMaybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear?: InputMaybe<Scalars["String"]>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: InputMaybe<
    Array<ImgixParamsFillGradientLinearDirection>
  >;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial?: InputMaybe<Scalars["String"]>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: InputMaybe<Scalars["String"]>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType?: InputMaybe<ImgixParamsFillGradientType>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: InputMaybe<Scalars["IntType"]>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: InputMaybe<Scalars["String"]>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars["IntType"]>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ?: InputMaybe<Scalars["IntType"]>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars["String"]>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars["IntType"]>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars["IntType"]>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars["IntType"]>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars["IntType"]>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: InputMaybe<Scalars["IntType"]>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: InputMaybe<Scalars["IntType"]>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars["String"]>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars["String"]>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars["IntType"]>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars["String"]>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars["String"]>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars["IntType"]>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars["IntType"]>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars["IntType"]>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars["IntType"]>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars["String"]>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars["IntType"]>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars["IntType"]>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars["IntType"]>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars["IntType"]>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars["String"]>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars["IntType"]>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars["IntType"]>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars["String"]>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars["IntType"]>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars["IntType"]>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: InputMaybe<Scalars["IntType"]>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars["String"]>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars["String"]>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars["String"]>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars["String"]>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars["String"]>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars["IntType"]>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars["IntType"]>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars["IntType"]>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars["FloatType"]>;
};

export enum ImgixParamsAuto {
  compress = "compress",
  enhance = "enhance",
  format = "format",
  redeye = "redeye",
}

export enum ImgixParamsBlendAlign {
  bottom = "bottom",
  center = "center",
  left = "left",
  middle = "middle",
  right = "right",
  top = "top",
}

export enum ImgixParamsBlendCrop {
  bottom = "bottom",
  faces = "faces",
  left = "left",
  right = "right",
  top = "top",
}

export enum ImgixParamsBlendFit {
  clamp = "clamp",
  clip = "clip",
  crop = "crop",
  max = "max",
  scale = "scale",
}

export enum ImgixParamsBlendMode {
  burn = "burn",
  color = "color",
  darken = "darken",
  difference = "difference",
  dodge = "dodge",
  exclusion = "exclusion",
  hardlight = "hardlight",
  hue = "hue",
  lighten = "lighten",
  luminosity = "luminosity",
  multiply = "multiply",
  normal = "normal",
  overlay = "overlay",
  saturation = "saturation",
  screen = "screen",
  softlight = "softlight",
}

export enum ImgixParamsBlendSize {
  inherit = "inherit",
}

export enum ImgixParamsCh {
  dpr = "dpr",
  saveData = "saveData",
  width = "width",
}

export enum ImgixParamsCrop {
  bottom = "bottom",
  edges = "edges",
  entropy = "entropy",
  faces = "faces",
  focalpoint = "focalpoint",
  left = "left",
  right = "right",
  top = "top",
}

export enum ImgixParamsCs {
  adobergb1998 = "adobergb1998",
  srgb = "srgb",
  strip = "strip",
  tinysrgb = "tinysrgb",
}

export enum ImgixParamsFill {
  blur = "blur",
  gen = "gen",
  generative = "generative",
  gradient = "gradient",
  solid = "solid",
}

export enum ImgixParamsFillGenPos {
  bottom = "bottom",
  center = "center",
  left = "left",
  middle = "middle",
  right = "right",
  top = "top",
}

export enum ImgixParamsFillGradientCs {
  hsl = "hsl",
  lch = "lch",
  linear = "linear",
  oklab = "oklab",
  srgb = "srgb",
}

export enum ImgixParamsFillGradientLinearDirection {
  bottom = "bottom",
  left = "left",
  right = "right",
  top = "top",
}

export enum ImgixParamsFillGradientType {
  linear = "linear",
  radial = "radial",
}

export enum ImgixParamsFit {
  clamp = "clamp",
  clip = "clip",
  crop = "crop",
  facearea = "facearea",
  fill = "fill",
  fillmax = "fillmax",
  max = "max",
  min = "min",
  scale = "scale",
}

export enum ImgixParamsFlip {
  h = "h",
  hv = "hv",
  v = "v",
}

export enum ImgixParamsFm {
  avif = "avif",
  blurhash = "blurhash",
  gif = "gif",
  jp2 = "jp2",
  jpg = "jpg",
  json = "json",
  jxr = "jxr",
  mp4 = "mp4",
  pjpg = "pjpg",
  png = "png",
  png8 = "png8",
  png32 = "png32",
  webm = "webm",
  webp = "webp",
}

export enum ImgixParamsIptc {
  allow = "allow",
  block = "block",
}

export enum ImgixParamsMarkAlign {
  bottom = "bottom",
  center = "center",
  left = "left",
  middle = "middle",
  right = "right",
  top = "top",
}

export enum ImgixParamsMarkFit {
  clip = "clip",
  crop = "crop",
  fill = "fill",
  max = "max",
  scale = "scale",
}

export enum ImgixParamsMarkTile {
  grid = "grid",
}

export enum ImgixParamsPalette {
  css = "css",
  json = "json",
}

export enum ImgixParamsTransparency {
  grid = "grid",
}

export enum ImgixParamsTrim {
  auto = "auto",
  color = "color",
}

export enum ImgixParamsTxtAlign {
  bottom = "bottom",
  center = "center",
  left = "left",
  middle = "middle",
  right = "right",
  top = "top",
}

export enum ImgixParamsTxtClip {
  ellipsis = "ellipsis",
  end = "end",
  middle = "middle",
  start = "start",
}

export enum ImgixParamsTxtFit {
  max = "max",
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter Integer fields */
export type IntegerFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenSightseeingSpotAndCity = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<SightseeingSpotModelFieldsReferencingCityModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<SightseeingSpotModelFieldsReferencingCityModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenStoryAndCity = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<StoryModelFieldsReferencingCityModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<StoryModelFieldsReferencingCityModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenStoryTranslationAndStory = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<StoryTranslationModelFieldsReferencingStoryModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<StoryTranslationModelFieldsReferencingStoryModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourAndCity = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<TourModelFieldsReferencingCityModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<TourModelFieldsReferencingCityModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourAndTourStop = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<TourModelFieldsReferencingTourStopModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<TourModelFieldsReferencingTourStopModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourCollectionAndCity = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<TourCollectionModelFieldsReferencingCityModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<TourCollectionModelFieldsReferencingCityModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourCreationTestDatumAndTour =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn?: InputMaybe<
      Array<TourCreationTestDatumModelFieldsReferencingTourModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn?: InputMaybe<
      Array<TourCreationTestDatumModelFieldsReferencingTourModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourStopAndStory = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<TourStopModelFieldsReferencingStoryModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<TourStopModelFieldsReferencingStoryModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourStopTranslationAndTour = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<TourStopTranslationModelFieldsReferencingTourModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<TourStopTranslationModelFieldsReferencingTourModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourStopTranslationAndTourStop =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn?: InputMaybe<
      Array<TourStopTranslationModelFieldsReferencingTourStopModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn?: InputMaybe<
      Array<TourStopTranslationModelFieldsReferencingTourStopModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenTourTranslationAndTour = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<Array<TourTranslationModelFieldsReferencingTourModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<Array<TourTranslationModelFieldsReferencingTourModel>>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenSightseeingSpotAndCity = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenSightseeingSpotAndCity>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenStoryAndCity = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenStoryAndCity>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenStoryTranslationAndStory = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenStoryTranslationAndStory>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourAndCity = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourAndCity>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourAndTourStop = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourAndTourStop>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourCollectionAndCity = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourCollectionAndCity>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourCreationTestDatumAndTour = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourCreationTestDatumAndTour>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourStopAndStory = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourStopAndStory>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourStopTranslationAndTour = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourStopTranslationAndTour>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourStopTranslationAndTourStop = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourStopTranslationAndTourStop>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenTourTranslationAndTour = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenTourTranslationAndTour>;
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars["ItemId"]>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars["ItemId"]>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

export enum ItemStatus {
  draft = "draft",
  published = "published",
  updated = "updated",
}

/** Specifies how to filter JSON fields */
export type JsonFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
};

export type LatLonField = {
  __typename?: "LatLonField";
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
};

/** Specifies how to filter Geolocation fields */
export type LatLonFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records within the specified radius in meters */
  near?: InputMaybe<LatLonNearFilter>;
};

export type LatLonNearFilter = {
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
};

/** Block of type Link Button (link_button) */
export type LinkButtonRecord = RecordInterface & {
  __typename?: "LinkButtonRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  fill?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  pathWithoutLocale?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Link Button (link_button) */
export type LinkButtonRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: InputMaybe<Scalars["ItemId"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records linked to one of the specified records */
  in?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: InputMaybe<Scalars["ItemId"]>;
  /** Filter records not linked to one of the specified records */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

/** Linking locales */
export enum LinkingLocale {
  _nonLocalized = "_nonLocalized",
  de = "de",
  en = "en",
  es = "es",
  fr = "fr",
  it = "it",
  uk = "uk",
}

/** Specifies how to filter by linking locales */
export type LinkingLocalesFilter = {
  /** Filter linking records that link to current record in at least one of the specified locales */
  anyIn?: InputMaybe<Array<LinkingLocale>>;
  /** Filter linking records that do not link to current record in any of the specified locales */
  notIn?: InputMaybe<Array<LinkingLocale>>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

/** Specifies how to filter by locale */
export type LocalesFilter = {
  /** Filter records that are localized in all the specified locales */
  allIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are localized in at least one of the specified locales */
  anyIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are not localized in any of the specified locales */
  notIn?: InputMaybe<Array<SiteLocale>>;
};

/** Block of type Location Task (location_task) */
export type LocationTaskRecord = RecordInterface & {
  __typename?: "LocationTaskRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  location?: Maybe<LatLonField>;
  maximumDistanceToPoint?: Maybe<Scalars["FloatType"]>;
  task?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Location Task (location_task) */
export type LocationTaskRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Multiple Choice Question Option (multiple_choice_question_option) */
export type MultipleChoiceQuestionOptionRecord = RecordInterface & {
  __typename?: "MultipleChoiceQuestionOptionRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  answer?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  isCorrect?: Maybe<Scalars["BooleanType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Multiple Choice Question Option (multiple_choice_question_option) */
export type MultipleChoiceQuestionOptionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Multiple Choice Question (multiple_choice_question) */
export type MultipleChoiceQuestionRecord = RecordInterface & {
  __typename?: "MultipleChoiceQuestionRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  options: Array<MultipleChoiceQuestionOptionRecord>;
  question?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Multiple Choice Question (multiple_choice_question) */
export type MultipleChoiceQuestionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum MuxThumbnailFormatType {
  gif = "gif",
  jpg = "jpg",
  png = "png",
}

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

/** Block of type Panorama Image Marker (panorama_image_marker) */
export type PanoramaImageMarkerRecord = RecordInterface & {
  __typename?: "PanoramaImageMarkerRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  latitude?: Maybe<Scalars["FloatType"]>;
  longitude?: Maybe<Scalars["FloatType"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Panorama Image Marker (panorama_image_marker) */
export type PanoramaImageMarkerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Panorama Image (panorama_image) */
export type PanoramaImageRecord = RecordInterface & {
  __typename?: "PanoramaImageRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  defaultLatitude?: Maybe<Scalars["FloatType"]>;
  defaultLongitude?: Maybe<Scalars["FloatType"]>;
  defaultZoomLevel?: Maybe<Scalars["FloatType"]>;
  id: Scalars["ItemId"];
  markers: Array<PanoramaImageMarkerRecord>;
  panoramaImageFile?: Maybe<FileField>;
  sphereCorrectionPan?: Maybe<Scalars["FloatType"]>;
  sphereCorrectionRoll?: Maybe<Scalars["FloatType"]>;
  sphereCorrectionTilt?: Maybe<Scalars["FloatType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Panorama Image (panorama_image) */
export type PanoramaImageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Placeholder Block (placeholder_block) */
export type PlaceholderBlockRecord = RecordInterface & {
  __typename?: "PlaceholderBlockRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Block of type Placeholder Block (placeholder_block) */
export type PlaceholderBlockRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Block of type QR Code Task (qr_code_task) */
export type QrCodeTaskRecord = RecordInterface & {
  __typename?: "QrCodeTaskRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  qrCodeUrl?: Maybe<Scalars["String"]>;
  referenceId?: Maybe<Scalars["String"]>;
  task?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type QR Code Task (qr_code_task) */
export type QrCodeTaskRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query = {
  __typename?: "Query";
  /** Returns meta information regarding a record collection */
  _allAgeRangesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allAppVersionFeaturesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCategoriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCitiesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allContinentsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCountriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCreatorProfilesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allExperienceLevelsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allGendersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allQuizzesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSchemaMigrationsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSightseeingSpotsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allStoriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allStorySlidesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allStoryTranslationsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allThemesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTourCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTourCreationTestDataMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTourStopTranslationsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTourStopsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTourTranslationsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allToursMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allVoicesMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a specific record */
  ageRange?: Maybe<AgeRangeRecord>;
  /** Returns a collection of records */
  allAgeRanges: Array<AgeRangeRecord>;
  /** Returns a collection of records */
  allAppVersionFeatures: Array<AppVersionFeatureRecord>;
  /** Returns a collection of records */
  allCategories: Array<CategoryRecord>;
  /** Returns a collection of records */
  allCities: Array<CityRecord>;
  /** Returns a collection of records */
  allContinents: Array<ContinentRecord>;
  /** Returns a collection of records */
  allCountries: Array<CountryRecord>;
  /** Returns a collection of records */
  allCreatorProfiles: Array<CreatorProfileRecord>;
  /** Returns a collection of records */
  allExperienceLevels: Array<ExperienceLevelRecord>;
  /** Returns a collection of records */
  allGenders: Array<GenderRecord>;
  /** Returns a collection of records */
  allQuizzes: Array<QuizRecord>;
  /** Returns a collection of records */
  allSchemaMigrations: Array<SchemaMigrationRecord>;
  /** Returns a collection of records */
  allSightseeingSpots: Array<SightseeingSpotRecord>;
  /** Returns a collection of records */
  allStories: Array<StoryRecord>;
  /** Returns a collection of records */
  allStorySlides: Array<StorySlideRecord>;
  /** Returns a collection of records */
  allStoryTranslations: Array<StoryTranslationRecord>;
  /** Returns a collection of records */
  allThemes: Array<ThemeRecord>;
  /** Returns a collection of records */
  allTourCollections: Array<TourCollectionRecord>;
  /** Returns a collection of records */
  allTourCreationTestData: Array<TourCreationTestDatumRecord>;
  /** Returns a collection of records */
  allTourStopTranslations: Array<TourStopTranslationRecord>;
  /** Returns a collection of records */
  allTourStops: Array<TourStopRecord>;
  /** Returns a collection of records */
  allTourTranslations: Array<TourTranslationRecord>;
  /** Returns a collection of records */
  allTours: Array<TourRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a collection of records */
  allVoices: Array<VoiceRecord>;
  /** Returns a specific record */
  appVersionFeature?: Maybe<AppVersionFeatureRecord>;
  /** Returns the single instance record */
  application?: Maybe<ApplicationRecord>;
  /** Returns a specific record */
  category?: Maybe<CategoryRecord>;
  /** Returns a specific record */
  city?: Maybe<CityRecord>;
  /** Returns a specific record */
  continent?: Maybe<ContinentRecord>;
  /** Returns a specific record */
  country?: Maybe<CountryRecord>;
  /** Returns a specific record */
  creatorProfile?: Maybe<CreatorProfileRecord>;
  /** Returns a specific record */
  experienceLevel?: Maybe<ExperienceLevelRecord>;
  /** Returns a specific record */
  gender?: Maybe<GenderRecord>;
  /** Returns a specific record */
  quiz?: Maybe<QuizRecord>;
  /** Returns a specific record */
  schemaMigration?: Maybe<SchemaMigrationRecord>;
  /** Returns a specific record */
  sightseeingSpot?: Maybe<SightseeingSpotRecord>;
  /** Returns a specific record */
  story?: Maybe<StoryRecord>;
  /** Returns a specific record */
  storySlide?: Maybe<StorySlideRecord>;
  /** Returns a specific record */
  storyTranslation?: Maybe<StoryTranslationRecord>;
  /** Returns a specific record */
  theme?: Maybe<ThemeRecord>;
  /** Returns a specific record */
  tour?: Maybe<TourRecord>;
  /** Returns a specific record */
  tourCollection?: Maybe<TourCollectionRecord>;
  /** Returns a specific record */
  tourCreationTestDatum?: Maybe<TourCreationTestDatumRecord>;
  /** Returns a specific record */
  tourStop?: Maybe<TourStopRecord>;
  /** Returns a specific record */
  tourStopTranslation?: Maybe<TourStopTranslationRecord>;
  /** Returns a specific record */
  tourTranslation?: Maybe<TourTranslationRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  /** Returns a specific record */
  voice?: Maybe<VoiceRecord>;
};

/** The query root for this schema */
export type Query_AllAgeRangesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AgeRangeModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllAppVersionFeaturesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AppVersionFeatureModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllCategoriesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllCitiesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CityModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllContinentsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ContinentModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllCountriesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllCreatorProfilesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CreatorProfileModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllExperienceLevelsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ExperienceLevelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllGendersMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GenderModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllQuizzesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<QuizModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSchemaMigrationsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SchemaMigrationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSightseeingSpotsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SightseeingSpotModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllStoriesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllStorySlidesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StorySlideModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllStoryTranslationsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllThemesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ThemeModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTourCollectionsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCollectionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTourCreationTestDataMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCreationTestDatumModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTourStopTranslationsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTourStopsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTourTranslationsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllToursMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllVoicesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<VoiceModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAgeRangeArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AgeRangeModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AgeRangeModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllAgeRangesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AgeRangeModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AgeRangeModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllAppVersionFeaturesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AppVersionFeatureModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AppVersionFeatureModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllCategoriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CategoryModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllCitiesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CityModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CityModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllContinentsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ContinentModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ContinentModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllCountriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CountryModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllCreatorProfilesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CreatorProfileModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CreatorProfileModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllExperienceLevelsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ExperienceLevelModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ExperienceLevelModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllGendersArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GenderModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GenderModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllQuizzesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<QuizModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<QuizModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllSchemaMigrationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SchemaMigrationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SchemaMigrationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllSightseeingSpotsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SightseeingSpotModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SightseeingSpotModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllStoriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoryModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllStorySlidesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StorySlideModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StorySlideModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllStoryTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoryTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllThemesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ThemeModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ThemeModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllTourCollectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourCollectionModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllTourCreationTestDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCreationTestDatumModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourCreationTestDatumModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllTourStopTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllTourStopsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllTourTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllToursArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllVoicesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<VoiceModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<VoiceModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAppVersionFeatureArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AppVersionFeatureModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AppVersionFeatureModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryApplicationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryCategoryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CategoryModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryCityArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CityModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CityModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryContinentArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ContinentModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ContinentModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryCountryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CountryModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryCreatorProfileArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CreatorProfileModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CreatorProfileModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryExperienceLevelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ExperienceLevelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ExperienceLevelModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryGenderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GenderModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GenderModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryQuizArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<QuizModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<QuizModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySchemaMigrationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SchemaMigrationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SchemaMigrationModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySightseeingSpotArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SightseeingSpotModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SightseeingSpotModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryStoryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoryModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryStorySlideArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StorySlideModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StorySlideModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryStoryTranslationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoryTranslationModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryThemeArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ThemeModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ThemeModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTourArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTourCollectionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCollectionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourCollectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTourCreationTestDatumArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCreationTestDatumModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourCreationTestDatumModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTourStopArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTourStopTranslationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopTranslationModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTourTranslationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourTranslationModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryVoiceArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<VoiceModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<VoiceModelOrderBy>>>;
};

export type QuizModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<QuizModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<QuizModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  internalQuizName?: InputMaybe<StringFilter>;
  isBlocking?: InputMaybe<BooleanFilter>;
  isVisibleAtEndOfAudio?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum QuizModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  internalQuizName_ASC = "internalQuizName_ASC",
  internalQuizName_DESC = "internalQuizName_DESC",
  isBlocking_ASC = "isBlocking_ASC",
  isBlocking_DESC = "isBlocking_DESC",
  isVisibleAtEndOfAudio_ASC = "isVisibleAtEndOfAudio_ASC",
  isVisibleAtEndOfAudio_DESC = "isVisibleAtEndOfAudio_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type QuizModelQuizTasksField =
  | LocationTaskRecord
  | MultipleChoiceQuestionRecord
  | QrCodeTaskRecord
  | TextInputRecord;

/** Record of type Quiz (quiz) */
export type QuizRecord = RecordInterface & {
  __typename?: "QuizRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  internalQuizName?: Maybe<Scalars["String"]>;
  isBlocking?: Maybe<Scalars["BooleanType"]>;
  isVisibleAtEndOfAudio?: Maybe<Scalars["BooleanType"]>;
  quizTasks: Array<QuizModelQuizTasksField>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Quiz (quiz) */
export type QuizRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type RecordInterface = {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
};

export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  icon = "icon",
  large = "large",
  medium = "medium",
  small = "small",
}

export type ResponsiveImage = {
  __typename?: "ResponsiveImage";
  alt?: Maybe<Scalars["String"]>;
  aspectRatio: Scalars["FloatType"];
  base64?: Maybe<Scalars["String"]>;
  bgColor?: Maybe<Scalars["String"]>;
  height: Scalars["IntType"];
  sizes: Scalars["String"];
  src: Scalars["String"];
  srcSet: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  webpSrcSet: Scalars["String"];
  width: Scalars["IntType"];
};

export type SchemaMigrationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<SchemaMigrationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SchemaMigrationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum SchemaMigrationModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Schema migration (schema_migration) */
export type SchemaMigrationRecord = RecordInterface & {
  __typename?: "SchemaMigrationRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Schema migration (schema_migration) */
export type SchemaMigrationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type SeoField = {
  __typename?: "SeoField";
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<FileField>;
  noIndex?: Maybe<Scalars["BooleanType"]>;
  title?: Maybe<Scalars["String"]>;
  twitterCard?: Maybe<Scalars["String"]>;
};

/** Linking fields */
export enum SightseeingSpotModelFieldsReferencingCityModel {
  sightseeingSpot_city = "sightseeingSpot_city",
}

export type SightseeingSpotModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<SightseeingSpotModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SightseeingSpotModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  city?: InputMaybe<LinkFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isPopular?: InputMaybe<BooleanFilter>;
  isVisibleOnMap?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<LatLonFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum SightseeingSpotModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isPopular_ASC = "isPopular_ASC",
  isPopular_DESC = "isPopular_DESC",
  isVisibleOnMap_ASC = "isVisibleOnMap_ASC",
  isVisibleOnMap_DESC = "isVisibleOnMap_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Sightseeing Spot (sightseeing_spot) */
export type SightseeingSpotRecord = RecordInterface & {
  __typename?: "SightseeingSpotRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  city?: Maybe<CityRecord>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isPopular?: Maybe<Scalars["BooleanType"]>;
  isVisibleOnMap?: Maybe<Scalars["BooleanType"]>;
  location?: Maybe<LatLonField>;
  mediaGallery: Array<ImageRecord>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["IntType"]>;
  preview: Array<ImageRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Sightseeing Spot (sightseeing_spot) */
export type SightseeingSpotRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Sightseeing Spot (sightseeing_spot) */
export type SightseeingSpotRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Sightseeing Spot (sightseeing_spot) */
export type SightseeingSpotRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Sightseeing Spot (sightseeing_spot) */
export type SightseeingSpotRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Sightseeing Spot (sightseeing_spot) */
export type SightseeingSpotRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type Site = {
  __typename?: "Site";
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex?: Maybe<Scalars["BooleanType"]>;
};

export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  de = "de",
  en = "en",
  es = "es",
  fr = "fr",
  it = "it",
  uk = "uk",
}

/** Block of type Slide Action Button (slide_action_button) */
export type SlideActionButtonRecord = RecordInterface & {
  __typename?: "SlideActionButtonRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  url?: Maybe<Scalars["String"]>;
  variant?: Maybe<Scalars["String"]>;
};

/** Block of type Slide Action Button (slide_action_button) */
export type SlideActionButtonRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter records that have one of the specified slugs */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["String"]>;
  /** Filter records that do have one of the specified slugs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Linking fields */
export enum StoryModelFieldsReferencingCityModel {
  story_city = "story_city",
}

export type StoryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<StoryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StoryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  ageRanges?: InputMaybe<LinksFilter>;
  appLinks?: InputMaybe<JsonFilter>;
  audioDurationInSeconds?: InputMaybe<IntegerFilter>;
  categories?: InputMaybe<LinksFilter>;
  city?: InputMaybe<LinkFilter>;
  coverImage?: InputMaybe<FileFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  creatorProfile?: InputMaybe<LinkFilter>;
  creatorType?: InputMaybe<StringFilter>;
  displayedPlaceName?: InputMaybe<StringFilter>;
  experienceLevels?: InputMaybe<LinksFilter>;
  externalStoryUrl?: InputMaybe<StringFilter>;
  genders?: InputMaybe<LinksFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isDiscoverable?: InputMaybe<BooleanFilter>;
  isPremium?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<LatLonFilter>;
  mainCategory?: InputMaybe<LinkFilter>;
  mapboxPlaceData?: InputMaybe<JsonFilter>;
  placeName?: InputMaybe<StringFilter>;
  storySlides?: InputMaybe<LinksFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum StoryModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  audioDurationInSeconds_ASC = "audioDurationInSeconds_ASC",
  audioDurationInSeconds_DESC = "audioDurationInSeconds_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  creatorType_ASC = "creatorType_ASC",
  creatorType_DESC = "creatorType_DESC",
  displayedPlaceName_ASC = "displayedPlaceName_ASC",
  displayedPlaceName_DESC = "displayedPlaceName_DESC",
  externalStoryUrl_ASC = "externalStoryUrl_ASC",
  externalStoryUrl_DESC = "externalStoryUrl_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isDiscoverable_ASC = "isDiscoverable_ASC",
  isDiscoverable_DESC = "isDiscoverable_DESC",
  isPremium_ASC = "isPremium_ASC",
  isPremium_DESC = "isPremium_DESC",
  placeName_ASC = "placeName_ASC",
  placeName_DESC = "placeName_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Story (story) */
export type StoryRecord = RecordInterface & {
  __typename?: "StoryRecord";
  _allDisplayedPlaceNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allPlaceNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allReferencingStoryTranslations: Array<StoryTranslationRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingStoryTranslationsMeta: CollectionMetadata;
  _allReferencingTourStops: Array<TourStopRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingTourStopsMeta: CollectionMetadata;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  ageRanges: Array<AgeRangeRecord>;
  appLinks?: Maybe<Scalars["JsonField"]>;
  audioDurationInSeconds?: Maybe<Scalars["IntType"]>;
  categories: Array<CategoryRecord>;
  city?: Maybe<CityRecord>;
  coverImage?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  creatorProfile?: Maybe<CreatorProfileRecord>;
  creatorType?: Maybe<Scalars["String"]>;
  displayedPlaceName?: Maybe<Scalars["String"]>;
  experienceLevels: Array<ExperienceLevelRecord>;
  externalStoryUrl?: Maybe<Scalars["String"]>;
  genders: Array<GenderRecord>;
  id: Scalars["ItemId"];
  isDiscoverable?: Maybe<Scalars["BooleanType"]>;
  isPremium?: Maybe<Scalars["BooleanType"]>;
  location?: Maybe<LatLonField>;
  mainCategory?: Maybe<CategoryRecord>;
  mapboxPlaceData?: Maybe<Scalars["JsonField"]>;
  placeName?: Maybe<Scalars["String"]>;
  storySlides: Array<StorySlideRecord>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Story (story) */
export type StoryRecord_AllDisplayedPlaceNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story (story) */
export type StoryRecord_AllPlaceNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story (story) */
export type StoryRecord_AllReferencingStoryTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoryTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoryTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenStoryTranslationAndStory>;
};

/** Record of type Story (story) */
export type StoryRecord_AllReferencingStoryTranslationsMetaArgs = {
  filter?: InputMaybe<StoryTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenStoryTranslationAndStory>;
};

/** Record of type Story (story) */
export type StoryRecord_AllReferencingTourStopsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourStopAndStory>;
};

/** Record of type Story (story) */
export type StoryRecord_AllReferencingTourStopsMetaArgs = {
  filter?: InputMaybe<TourStopModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourStopAndStory>;
};

/** Record of type Story (story) */
export type StoryRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story (story) */
export type StoryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story (story) */
export type StoryRecordDisplayedPlaceNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story (story) */
export type StoryRecordPlaceNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story (story) */
export type StoryRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type StoryRecordMultiLocaleField = {
  __typename?: "StoryRecordMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<StoryRecord>;
};

export type StorySlideModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<StorySlideModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StorySlideModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  audioDurationInSeconds?: InputMaybe<IntegerFilter>;
  backgroundImage?: InputMaybe<FileFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  internalSlideContent?: InputMaybe<StructuredTextFilter>;
  internalSlideName?: InputMaybe<StringFilter>;
  isNavigationSlide?: InputMaybe<BooleanFilter>;
  isSlideAudioFixed?: InputMaybe<BooleanFilter>;
  quiz?: InputMaybe<LinkFilter>;
  slideAudio?: InputMaybe<FileFilter>;
  slideVideo?: InputMaybe<FileFilter>;
  speechMarks?: InputMaybe<JsonFilter>;
  subtitles?: InputMaybe<TextFilter>;
  textForAudioGeneration?: InputMaybe<TextFilter>;
  textTrack?: InputMaybe<FileFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type StorySlideModelInternalSlideContentField = {
  __typename?: "StorySlideModelInternalSlideContentField";
  blocks: Array<Scalars["String"]>;
  links: Array<Scalars["String"]>;
  value: Scalars["JsonField"];
};

export type StorySlideModelMediaGalleryField =
  | ImageComparerRecord
  | ImageRecord
  | VideoRecord;

export enum StorySlideModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  audioDurationInSeconds_ASC = "audioDurationInSeconds_ASC",
  audioDurationInSeconds_DESC = "audioDurationInSeconds_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  internalSlideName_ASC = "internalSlideName_ASC",
  internalSlideName_DESC = "internalSlideName_DESC",
  isNavigationSlide_ASC = "isNavigationSlide_ASC",
  isNavigationSlide_DESC = "isNavigationSlide_DESC",
  isSlideAudioFixed_ASC = "isSlideAudioFixed_ASC",
  isSlideAudioFixed_DESC = "isSlideAudioFixed_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type StorySlideModelSlideActionsField =
  | PlaceholderBlockRecord
  | SlideActionButtonRecord;

export type StorySlideModelSlideVoiceAudiosField =
  | PlaceholderBlockRecord
  | VoiceAudioRecord;

/** Record of type Story Slide (story_slide) */
export type StorySlideRecord = RecordInterface & {
  __typename?: "StorySlideRecord";
  _allSubtitlesLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextForAudioGenerationLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  audioDurationInSeconds?: Maybe<Scalars["IntType"]>;
  backgroundImage?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  internalSlideContent?: Maybe<StorySlideModelInternalSlideContentField>;
  internalSlideName?: Maybe<Scalars["String"]>;
  isNavigationSlide?: Maybe<Scalars["BooleanType"]>;
  isSlideAudioFixed?: Maybe<Scalars["BooleanType"]>;
  mediaGallery: Array<StorySlideModelMediaGalleryField>;
  panoramaImage: Array<PanoramaImageRecord>;
  quiz?: Maybe<QuizRecord>;
  slideActions: Array<StorySlideModelSlideActionsField>;
  slideAudio?: Maybe<FileField>;
  slideVideo?: Maybe<FileField>;
  slideVoiceAudios: Array<StorySlideModelSlideVoiceAudiosField>;
  speechMarks?: Maybe<Scalars["JsonField"]>;
  subtitles?: Maybe<Scalars["String"]>;
  textForAudioGeneration?: Maybe<Scalars["String"]>;
  textTrack?: Maybe<FileField>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Story Slide (story_slide) */
export type StorySlideRecord_AllSubtitlesLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Story Slide (story_slide) */
export type StorySlideRecord_AllTextForAudioGenerationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Story Slide (story_slide) */
export type StorySlideRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story Slide (story_slide) */
export type StorySlideRecordSubtitlesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Story Slide (story_slide) */
export type StorySlideRecordTextForAudioGenerationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum StoryTranslationModelFieldsReferencingStoryModel {
  storyTranslation_story = "storyTranslation_story",
}

export type StoryTranslationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<StoryTranslationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StoryTranslationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  story?: InputMaybe<LinkFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum StoryTranslationModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Story Translation (story_translation) */
export type StoryTranslationRecord = RecordInterface & {
  __typename?: "StoryTranslationRecord";
  _allStoryLocales?: Maybe<Array<StoryRecordMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  story?: Maybe<StoryRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Story Translation (story_translation) */
export type StoryTranslationRecord_AllStoryLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story Translation (story_translation) */
export type StoryTranslationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Story Translation (story_translation) */
export type StoryTranslationRecordStoryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["String"]>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars["BooleanType"]>;
  pattern: Scalars["String"];
  regexp?: InputMaybe<Scalars["BooleanType"]>;
};

export type StringMultiLocaleField = {
  __typename?: "StringMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["String"]>;
};

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type Tag = {
  __typename?: "Tag";
  attributes?: Maybe<Scalars["MetaTagAttributes"]>;
  content?: Maybe<Scalars["String"]>;
  tag: Scalars["String"];
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Block of type Text Input (text_input) */
export type TextInputRecord = RecordInterface & {
  __typename?: "TextInputRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage?: Maybe<FileField>;
  correctAnswer?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  question?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Text Input (text_input) */
export type TextInputRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ThemeModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ThemeModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ThemeModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  aiDescription?: InputMaybe<StringFilter>;
  categories?: InputMaybe<LinksFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  position?: InputMaybe<PositionFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum ThemeModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  aiDescription_ASC = "aiDescription_ASC",
  aiDescription_DESC = "aiDescription_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Theme (theme) */
export type ThemeRecord = RecordInterface & {
  __typename?: "ThemeRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  aiDescription?: Maybe<Scalars["String"]>;
  categories: Array<CategoryRecord>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  position?: Maybe<Scalars["IntType"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Theme (theme) */
export type ThemeRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Theme (theme) */
export type ThemeRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Theme (theme) */
export type ThemeRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Theme (theme) */
export type ThemeRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Theme (theme) */
export type ThemeRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum TourCollectionModelFieldsReferencingCityModel {
  tourCollection_cities = "tourCollection_cities",
}

export type TourCollectionModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TourCollectionModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TourCollectionModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  cities?: InputMaybe<LinksFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  longDescription?: InputMaybe<TextFilter>;
  name?: InputMaybe<StringFilter>;
  shortDescription?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TourCollectionModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecord = RecordInterface & {
  __typename?: "TourCollectionRecord";
  _allLongDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allShortDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  cities: Array<CityRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  longDescription?: Maybe<Scalars["String"]>;
  mediaGallery: Array<ImageRecord>;
  name?: Maybe<Scalars["String"]>;
  preview: Array<ImageRecord>;
  shortDescription?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecord_AllLongDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecord_AllShortDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecordLongDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecordShortDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Tour Collection (tour_collection) */
export type TourCollectionRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum TourCreationTestDatumModelFieldsReferencingTourModel {
  tourCreationTestDatum_tour = "tourCreationTestDatum_tour",
}

export type TourCreationTestDatumModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TourCreationTestDatumModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TourCreationTestDatumModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  testData?: InputMaybe<JsonFilter>;
  tour?: InputMaybe<LinkFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TourCreationTestDatumModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Tour Creation Test Datum (tour_creation_test_datum) */
export type TourCreationTestDatumRecord = RecordInterface & {
  __typename?: "TourCreationTestDatumRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  testData?: Maybe<Scalars["JsonField"]>;
  tour?: Maybe<TourRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Tour Creation Test Datum (tour_creation_test_datum) */
export type TourCreationTestDatumRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum TourModelFieldsReferencingCityModel {
  tour_cities = "tour_cities",
}

/** Linking fields */
export enum TourModelFieldsReferencingTourStopModel {
  tour_tourStops = "tour_tourStops",
}

export type TourModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TourModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TourModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  audioDurationInMinutes?: InputMaybe<IntegerFilter>;
  categories?: InputMaybe<LinksFilter>;
  cities?: InputMaybe<LinksFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  creatorProfile?: InputMaybe<LinkFilter>;
  creatorType?: InputMaybe<StringFilter>;
  currentTourPrice?: InputMaybe<FloatFilter>;
  distanceInKilometers?: InputMaybe<FloatFilter>;
  durationByBikeInMinutes?: InputMaybe<IntegerFilter>;
  durationByBoatInMinutes?: InputMaybe<IntegerFilter>;
  durationByBusInMinutes?: InputMaybe<IntegerFilter>;
  durationByCarInMinutes?: InputMaybe<IntegerFilter>;
  durationByTrainInMinutes?: InputMaybe<IntegerFilter>;
  durationInMinutes?: InputMaybe<IntegerFilter>;
  experienceLevels?: InputMaybe<LinksFilter>;
  highlightImage?: InputMaybe<FileFilter>;
  highlights?: InputMaybe<JsonFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isDiscoverable?: InputMaybe<BooleanFilter>;
  isPopular?: InputMaybe<BooleanFilter>;
  isTrackableForTourStops?: InputMaybe<BooleanFilter>;
  maximumDistanceToTourStop?: InputMaybe<FloatFilter>;
  position?: InputMaybe<PositionFilter>;
  regularTourPrice?: InputMaybe<FloatFilter>;
  routeGeoJson?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
  tourCollections?: InputMaybe<LinksFilter>;
  tourPreviewContent?: InputMaybe<StructuredTextFilter>;
  tourStops?: InputMaybe<LinksFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TourModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  audioDurationInMinutes_ASC = "audioDurationInMinutes_ASC",
  audioDurationInMinutes_DESC = "audioDurationInMinutes_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  creatorType_ASC = "creatorType_ASC",
  creatorType_DESC = "creatorType_DESC",
  currentTourPrice_ASC = "currentTourPrice_ASC",
  currentTourPrice_DESC = "currentTourPrice_DESC",
  distanceInKilometers_ASC = "distanceInKilometers_ASC",
  distanceInKilometers_DESC = "distanceInKilometers_DESC",
  durationByBikeInMinutes_ASC = "durationByBikeInMinutes_ASC",
  durationByBikeInMinutes_DESC = "durationByBikeInMinutes_DESC",
  durationByBoatInMinutes_ASC = "durationByBoatInMinutes_ASC",
  durationByBoatInMinutes_DESC = "durationByBoatInMinutes_DESC",
  durationByBusInMinutes_ASC = "durationByBusInMinutes_ASC",
  durationByBusInMinutes_DESC = "durationByBusInMinutes_DESC",
  durationByCarInMinutes_ASC = "durationByCarInMinutes_ASC",
  durationByCarInMinutes_DESC = "durationByCarInMinutes_DESC",
  durationByTrainInMinutes_ASC = "durationByTrainInMinutes_ASC",
  durationByTrainInMinutes_DESC = "durationByTrainInMinutes_DESC",
  durationInMinutes_ASC = "durationInMinutes_ASC",
  durationInMinutes_DESC = "durationInMinutes_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isDiscoverable_ASC = "isDiscoverable_ASC",
  isDiscoverable_DESC = "isDiscoverable_DESC",
  isPopular_ASC = "isPopular_ASC",
  isPopular_DESC = "isPopular_DESC",
  isTrackableForTourStops_ASC = "isTrackableForTourStops_ASC",
  isTrackableForTourStops_DESC = "isTrackableForTourStops_DESC",
  maximumDistanceToTourStop_ASC = "maximumDistanceToTourStop_ASC",
  maximumDistanceToTourStop_DESC = "maximumDistanceToTourStop_DESC",
  position_ASC = "position_ASC",
  position_DESC = "position_DESC",
  regularTourPrice_ASC = "regularTourPrice_ASC",
  regularTourPrice_DESC = "regularTourPrice_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type TourModelTourImpressionsField =
  | ImageRecord
  | PlaceholderBlockRecord;

export type TourModelTourPreviewContentBlocksField =
  | ImageRecord
  | PlaceholderBlockRecord;

export type TourModelTourPreviewContentField = {
  __typename?: "TourModelTourPreviewContentField";
  blocks: Array<TourModelTourPreviewContentBlocksField>;
  links: Array<Scalars["String"]>;
  value: Scalars["JsonField"];
};

export type TourModelVideoGalleryField = PlaceholderBlockRecord | VideoRecord;

/** Record of type Tour (tour) */
export type TourRecord = RecordInterface & {
  __typename?: "TourRecord";
  _allReferencingTourCreationTestData: Array<TourCreationTestDatumRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingTourCreationTestDataMeta: CollectionMetadata;
  _allReferencingTourStopTranslations: Array<TourStopTranslationRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingTourStopTranslationsMeta: CollectionMetadata;
  _allReferencingTourTranslations: Array<TourTranslationRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingTourTranslationsMeta: CollectionMetadata;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  audioDurationInMinutes?: Maybe<Scalars["IntType"]>;
  categories: Array<CategoryRecord>;
  cities: Array<CityRecord>;
  createdAt: Scalars["DateTime"];
  creatorProfile?: Maybe<CreatorProfileRecord>;
  creatorType?: Maybe<Scalars["String"]>;
  currentTourPrice?: Maybe<Scalars["FloatType"]>;
  distanceInKilometers?: Maybe<Scalars["FloatType"]>;
  durationByBikeInMinutes?: Maybe<Scalars["IntType"]>;
  durationByBoatInMinutes?: Maybe<Scalars["IntType"]>;
  durationByBusInMinutes?: Maybe<Scalars["IntType"]>;
  durationByCarInMinutes?: Maybe<Scalars["IntType"]>;
  durationByTrainInMinutes?: Maybe<Scalars["IntType"]>;
  durationInMinutes?: Maybe<Scalars["IntType"]>;
  experienceLevels: Array<ExperienceLevelRecord>;
  highlightImage?: Maybe<FileField>;
  highlights?: Maybe<Scalars["JsonField"]>;
  id: Scalars["ItemId"];
  isDiscoverable?: Maybe<Scalars["BooleanType"]>;
  isPopular?: Maybe<Scalars["BooleanType"]>;
  isTrackableForTourStops?: Maybe<Scalars["BooleanType"]>;
  maximumDistanceToTourStop?: Maybe<Scalars["FloatType"]>;
  mediaGallery: Array<ImageRecord>;
  position?: Maybe<Scalars["IntType"]>;
  regularTourPrice?: Maybe<Scalars["FloatType"]>;
  routeGeoJson?: Maybe<Scalars["JsonField"]>;
  slug?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tourCollections: Array<TourCollectionRecord>;
  tourImpressions: Array<TourModelTourImpressionsField>;
  tourPreviewContent?: Maybe<TourModelTourPreviewContentField>;
  tourStops: Array<TourStopRecord>;
  updatedAt: Scalars["DateTime"];
  videoGallery: Array<TourModelVideoGalleryField>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllReferencingTourCreationTestDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourCreationTestDatumModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourCreationTestDatumModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourCreationTestDatumAndTour>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllReferencingTourCreationTestDataMetaArgs = {
  filter?: InputMaybe<TourCreationTestDatumModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourCreationTestDatumAndTour>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllReferencingTourStopTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourStopTranslationAndTour>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllReferencingTourStopTranslationsMetaArgs = {
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourStopTranslationAndTour>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllReferencingTourTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourTranslationAndTour>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllReferencingTourTranslationsMetaArgs = {
  filter?: InputMaybe<TourTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourTranslationAndTour>;
};

/** Record of type Tour (tour) */
export type TourRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour (tour) */
export type TourRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour (tour) */
export type TourRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TourRecordMultiLocaleField = {
  __typename?: "TourRecordMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<TourRecord>;
};

/** Linking fields */
export enum TourStopModelFieldsReferencingStoryModel {
  tourStop_stories = "tourStop_stories",
}

export type TourStopModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TourStopModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TourStopModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  displayedPlaceName?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isIntroTourStop?: InputMaybe<BooleanFilter>;
  isOutroTourStop?: InputMaybe<BooleanFilter>;
  isPreviewTourStop?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<LatLonFilter>;
  maximumDistanceToNotificationTrigger?: InputMaybe<FloatFilter>;
  notificationTriggerLocation?: InputMaybe<LatLonFilter>;
  placeName?: InputMaybe<StringFilter>;
  stories?: InputMaybe<LinksFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TourStopModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  displayedPlaceName_ASC = "displayedPlaceName_ASC",
  displayedPlaceName_DESC = "displayedPlaceName_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isIntroTourStop_ASC = "isIntroTourStop_ASC",
  isIntroTourStop_DESC = "isIntroTourStop_DESC",
  isOutroTourStop_ASC = "isOutroTourStop_ASC",
  isOutroTourStop_DESC = "isOutroTourStop_DESC",
  isPreviewTourStop_ASC = "isPreviewTourStop_ASC",
  isPreviewTourStop_DESC = "isPreviewTourStop_DESC",
  maximumDistanceToNotificationTrigger_ASC = "maximumDistanceToNotificationTrigger_ASC",
  maximumDistanceToNotificationTrigger_DESC = "maximumDistanceToNotificationTrigger_DESC",
  placeName_ASC = "placeName_ASC",
  placeName_DESC = "placeName_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord = RecordInterface & {
  __typename?: "TourStopRecord";
  _allDisplayedPlaceNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allPlaceNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allReferencingTourStopTranslations: Array<TourStopTranslationRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingTourStopTranslationsMeta: CollectionMetadata;
  _allReferencingTours: Array<TourRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingToursMeta: CollectionMetadata;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  displayedPlaceName?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isIntroTourStop?: Maybe<Scalars["BooleanType"]>;
  isOutroTourStop?: Maybe<Scalars["BooleanType"]>;
  isPreviewTourStop?: Maybe<Scalars["BooleanType"]>;
  location?: Maybe<LatLonField>;
  maximumDistanceToNotificationTrigger?: Maybe<Scalars["FloatType"]>;
  notificationTriggerLocation?: Maybe<LatLonField>;
  placeName?: Maybe<Scalars["String"]>;
  stories: Array<StoryRecord>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllDisplayedPlaceNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllPlaceNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllReferencingTourStopTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourStopTranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourStopTranslationAndTourStop>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllReferencingTourStopTranslationsMetaArgs = {
  filter?: InputMaybe<TourStopTranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourStopTranslationAndTourStop>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllReferencingToursArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TourModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TourModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourAndTourStop>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllReferencingToursMetaArgs = {
  filter?: InputMaybe<TourModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  through?: InputMaybe<InverseRelationshipFilterBetweenTourAndTourStop>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecordDisplayedPlaceNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecordPlaceNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop (tour_stop) */
export type TourStopRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TourStopRecordMultiLocaleField = {
  __typename?: "TourStopRecordMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<TourStopRecord>;
};

/** Linking fields */
export enum TourStopTranslationModelFieldsReferencingTourModel {
  tourStopTranslation_tour = "tourStopTranslation_tour",
}

/** Linking fields */
export enum TourStopTranslationModelFieldsReferencingTourStopModel {
  tourStopTranslation_tourStop = "tourStopTranslation_tourStop",
}

export type TourStopTranslationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TourStopTranslationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TourStopTranslationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  tour?: InputMaybe<LinkFilter>;
  tourStop?: InputMaybe<LinkFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TourStopTranslationModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Tour Stop Translation (tour_stop_translation) */
export type TourStopTranslationRecord = RecordInterface & {
  __typename?: "TourStopTranslationRecord";
  _allTourLocales?: Maybe<Array<TourRecordMultiLocaleField>>;
  _allTourStopLocales?: Maybe<Array<TourStopRecordMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  tour?: Maybe<TourRecord>;
  tourStop?: Maybe<TourStopRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Tour Stop Translation (tour_stop_translation) */
export type TourStopTranslationRecord_AllTourLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop Translation (tour_stop_translation) */
export type TourStopTranslationRecord_AllTourStopLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop Translation (tour_stop_translation) */
export type TourStopTranslationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop Translation (tour_stop_translation) */
export type TourStopTranslationRecordTourArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Stop Translation (tour_stop_translation) */
export type TourStopTranslationRecordTourStopArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum TourTranslationModelFieldsReferencingTourModel {
  tourTranslation_tour = "tourTranslation_tour",
}

export type TourTranslationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TourTranslationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TourTranslationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  tour?: InputMaybe<LinkFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TourTranslationModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Tour Translation (tour_translation) */
export type TourTranslationRecord = RecordInterface & {
  __typename?: "TourTranslationRecord";
  _allTourLocales?: Maybe<Array<TourRecordMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  tour?: Maybe<TourRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Tour Translation (tour_translation) */
export type TourTranslationRecord_AllTourLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Translation (tour_translation) */
export type TourTranslationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Tour Translation (tour_translation) */
export type TourTranslationRecordTourArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars["UploadId"]>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars["UploadId"]>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  basename_ASC = "basename_ASC",
  basename_DESC = "basename_DESC",
  filename_ASC = "filename_ASC",
  filename_DESC = "filename_DESC",
  format_ASC = "format_ASC",
  format_DESC = "format_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  mimeType_ASC = "mimeType_ASC",
  mimeType_DESC = "mimeType_DESC",
  resolution_ASC = "resolution_ASC",
  resolution_DESC = "resolution_DESC",
  size_ASC = "size_ASC",
  size_DESC = "size_DESC",
}

export enum UploadOrientation {
  landscape = "landscape",
  portrait = "portrait",
  square = "square",
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars["String"]>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  archive = "archive",
  audio = "audio",
  image = "image",
  pdfdocument = "pdfdocument",
  presentation = "presentation",
  richtext = "richtext",
  spreadsheet = "spreadsheet",
  video = "video",
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

export type UploadVideoField = {
  __typename?: "UploadVideoField";
  alt?: Maybe<Scalars["String"]>;
  blurUpThumb?: Maybe<Scalars["String"]>;
  blurhash?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  framerate?: Maybe<Scalars["Int"]>;
  height: Scalars["IntType"];
  mp4Url?: Maybe<Scalars["String"]>;
  muxAssetId: Scalars["String"];
  muxPlaybackId: Scalars["String"];
  streamingUrl: Scalars["String"];
  thumbhash?: Maybe<Scalars["String"]>;
  thumbnailUrl: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  width: Scalars["IntType"];
};

export type UploadVideoFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars["Float"];
  quality?: Scalars["Int"];
  size?: Scalars["Int"];
};

export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};

export type UploadVideoFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars["IntType"]>;
};

export type VideoField = {
  __typename?: "VideoField";
  height: Scalars["IntType"];
  provider: Scalars["String"];
  providerUid: Scalars["String"];
  thumbnailUrl: Scalars["String"];
  title: Scalars["String"];
  url: Scalars["String"];
  width: Scalars["IntType"];
};

export enum VideoMp4Res {
  high = "high",
  low = "low",
  medium = "medium",
}

/** Block of type Video (video) */
export type VideoRecord = RecordInterface & {
  __typename?: "VideoRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  caption?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  externalVideoUrl?: Maybe<VideoField>;
  id: Scalars["ItemId"];
  order?: Maybe<Scalars["IntType"]>;
  updatedAt: Scalars["DateTime"];
  videoFile?: Maybe<FileField>;
};

/** Block of type Video (video) */
export type VideoRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Voice Audio (voice_audio) */
export type VoiceAudioRecord = RecordInterface & {
  __typename?: "VoiceAudioRecord";
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  audio?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
  voice?: Maybe<VoiceRecord>;
};

/** Block of type Voice Audio (voice_audio) */
export type VoiceAudioRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type VoiceModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<VoiceModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VoiceModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  audio?: InputMaybe<FileFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isDefault?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  textToSpeechVoiceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum VoiceModelOrderBy {
  _createdAt_ASC = "_createdAt_ASC",
  _createdAt_DESC = "_createdAt_DESC",
  _firstPublishedAt_ASC = "_firstPublishedAt_ASC",
  _firstPublishedAt_DESC = "_firstPublishedAt_DESC",
  _isValid_ASC = "_isValid_ASC",
  _isValid_DESC = "_isValid_DESC",
  _publicationScheduledAt_ASC = "_publicationScheduledAt_ASC",
  _publicationScheduledAt_DESC = "_publicationScheduledAt_DESC",
  _publishedAt_ASC = "_publishedAt_ASC",
  _publishedAt_DESC = "_publishedAt_DESC",
  _status_ASC = "_status_ASC",
  _status_DESC = "_status_DESC",
  _unpublishingScheduledAt_ASC = "_unpublishingScheduledAt_ASC",
  _unpublishingScheduledAt_DESC = "_unpublishingScheduledAt_DESC",
  _updatedAt_ASC = "_updatedAt_ASC",
  _updatedAt_DESC = "_updatedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isDefault_ASC = "isDefault_ASC",
  isDefault_DESC = "isDefault_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  textToSpeechVoiceId_ASC = "textToSpeechVoiceId_ASC",
  textToSpeechVoiceId_DESC = "textToSpeechVoiceId_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Record of type Voice (voice) */
export type VoiceRecord = RecordInterface & {
  __typename?: "VoiceRecord";
  _allAudioLocales?: Maybe<Array<FileFieldMultiLocaleField>>;
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars["String"]>;
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  audio?: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isDefault?: Maybe<Scalars["BooleanType"]>;
  name?: Maybe<Scalars["String"]>;
  textToSpeechVoiceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Voice (voice) */
export type VoiceRecord_AllAudioLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Voice (voice) */
export type VoiceRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Voice (voice) */
export type VoiceRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Voice (voice) */
export type VoiceRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Voice (voice) */
export type VoiceRecordAudioArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Voice (voice) */
export type VoiceRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Voice (voice) */
export type VoiceRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FocalPoint = {
  __typename?: "focalPoint";
  x: Scalars["FloatType"];
  y: Scalars["FloatType"];
};

export type AudioFileFieldsFragment = {
  __typename?: "FileField";
  id: any;
  basename: string;
  filename: string;
  format: string;
  mimeType: string;
  size: any;
  url: string;
};

export type BaseFileFieldsFragment = {
  __typename?: "FileField";
  id: any;
  title?: string | null;
  copyright?: string | null;
  author?: string | null;
};

export type CategoryFieldsFragment = {
  __typename?: "CategoryRecord";
  id: any;
  title?: string | null;
  color?: {
    __typename?: "ColorField";
    hex: string;
    red: any;
    green: any;
    blue: any;
  } | null;
  icon?: { __typename?: "FileField"; url: string } | null;
  colorIcon?: { __typename?: "FileField"; url: string } | null;
};

export type CityFieldsFragment = {
  __typename?: "CityRecord";
  id: any;
  name?: string | null;
  title?: string | null;
  description?: string | null;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
};

export type RoundCityImageFragmentFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type RectangularCityImageFragmentFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type CityToursAndStoriesCountFragment = {
  __typename?: "CityRecord";
  _allReferencingToursMeta: { __typename?: "CollectionMetadata"; count: any };
  _allReferencingStoriesMeta: { __typename?: "CollectionMetadata"; count: any };
};

export type ClampedResponsiveImageFileFieldsFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type CreatorProfileFieldsFragment = {
  __typename?: "CreatorProfileRecord";
  id: any;
  creatorName?: string | null;
  username?: string | null;
  slogan?: string | null;
  _status: ItemStatus;
  isDiscoverable?: any | null;
  audioPreview?: {
    __typename?: "FileField";
    url: string;
    mimeType: string;
  } | null;
  aboutMe?: {
    __typename?: "CreatorProfileModelAboutMeField";
    value: any;
    blocks: Array<
      | {
          __typename: "ImageRecord";
          id: any;
          caption?: string | null;
          image?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | { __typename: "PlaceholderBlockRecord"; id: any }
      | { __typename: "VideoRecord" }
    >;
  } | null;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  roundProfileImage?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
  squareProfileImage?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

export type CreatorProfileAboutMeFragmentFragment = {
  __typename?: "CreatorProfileModelAboutMeField";
  value: any;
  blocks: Array<
    | {
        __typename: "ImageRecord";
        id: any;
        caption?: string | null;
        image?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      }
    | { __typename: "PlaceholderBlockRecord"; id: any }
    | { __typename: "VideoRecord" }
  >;
};

export type RoundCreatorProfileImageFragmentFragment = {
  __typename?: "FileField";
  id: any;
  title?: string | null;
  copyright?: string | null;
  author?: string | null;
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type SquareCreatorProfileImageFragmentFragment = {
  __typename?: "FileField";
  id: any;
  title?: string | null;
  copyright?: string | null;
  author?: string | null;
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type CreatorProfilePreviewFieldsFragment = {
  __typename?: "CreatorProfileRecord";
  id: any;
  creatorName?: string | null;
  username?: string | null;
  slogan?: string | null;
  _status: ItemStatus;
  isDiscoverable?: any | null;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  squareProfileImage?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

export type CroppedResponsiveImageFileFieldsFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type ExperienceLevelFieldsFragment = {
  __typename?: "ExperienceLevelRecord";
  id: any;
  title?: string | null;
  description?: string | null;
  color?: { __typename?: "ColorField"; hex: string } | null;
};

export type GenderFieldsFragment = {
  __typename?: "GenderRecord";
  id: any;
  title?: string | null;
};

export type ImageComparerFieldsFragment = {
  __typename?: "ImageComparerRecord";
  id: any;
  caption?: string | null;
  imageBefore?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
  imageAfter?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

export type ImageRecordFieldsFragment = {
  __typename?: "ImageRecord";
  id: any;
  caption?: string | null;
  image?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

export type LocationFieldsFragment = {
  __typename?: "LatLonField";
  latitude: any;
  longitude: any;
};

export type LocationTaskFieldsFragment = {
  __typename?: "LocationTaskRecord";
  id: any;
  task?: string | null;
  maximumDistanceToPoint?: any | null;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
  backgroundImage?: { __typename?: "FileField"; url: string } | null;
};

type MediaGalleryFields_ImageComparerRecord_Fragment = {
  __typename?: "ImageComparerRecord";
  id: any;
  caption?: string | null;
  imageBefore?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
  imageAfter?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

type MediaGalleryFields_ImageRecord_Fragment = {
  __typename?: "ImageRecord";
  id: any;
  caption?: string | null;
  image?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

type MediaGalleryFields_VideoRecord_Fragment = {
  __typename?: "VideoRecord";
  id: any;
  order?: any | null;
  caption?: string | null;
  externalVideoUrl?: {
    __typename?: "VideoField";
    provider: string;
    providerUid: string;
    thumbnailUrl: string;
    title: string;
    url: string;
    height: any;
    width: any;
  } | null;
  videoFile?: {
    __typename?: "FileField";
    url: string;
    height?: any | null;
    width?: any | null;
    mimeType: string;
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
  } | null;
};

export type MediaGalleryFieldsFragment =
  | MediaGalleryFields_ImageComparerRecord_Fragment
  | MediaGalleryFields_ImageRecord_Fragment
  | MediaGalleryFields_VideoRecord_Fragment;

export type MultipleChoiceQuestionFieldsFragment = {
  __typename?: "MultipleChoiceQuestionRecord";
  id: any;
  question?: string | null;
  backgroundImage?: { __typename?: "FileField"; url: string } | null;
  options: Array<{
    __typename?: "MultipleChoiceQuestionOptionRecord";
    id: any;
    answer?: string | null;
    isCorrect?: any | null;
  }>;
};

export type PanoramaImageFieldsFragment = {
  __typename?: "PanoramaImageRecord";
  defaultLongitude?: any | null;
  defaultLatitude?: any | null;
  defaultZoomLevel?: any | null;
  sphereCorrectionPan?: any | null;
  sphereCorrectionTilt?: any | null;
  sphereCorrectionRoll?: any | null;
  panoramaImageFile?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
  markers: Array<{
    __typename?: "PanoramaImageMarkerRecord";
    id: any;
    longitude?: any | null;
    latitude?: any | null;
    title?: string | null;
  }>;
};

export type PlaceholderBlockRecordFieldsFragment = {
  __typename?: "PlaceholderBlockRecord";
  id: any;
};

export type QrCodeTaskFieldsFragment = {
  __typename?: "QrCodeTaskRecord";
  id: any;
  task?: string | null;
  referenceId?: string | null;
};

export type QuizFieldsFragment = {
  __typename?: "QuizRecord";
  id: any;
  isBlocking?: any | null;
  isVisibleAtEndOfAudio?: any | null;
  quizTasks: Array<
    | {
        __typename?: "LocationTaskRecord";
        id: any;
        task?: string | null;
        maximumDistanceToPoint?: any | null;
        location?: {
          __typename?: "LatLonField";
          latitude: any;
          longitude: any;
        } | null;
        backgroundImage?: { __typename?: "FileField"; url: string } | null;
      }
    | {
        __typename?: "MultipleChoiceQuestionRecord";
        id: any;
        question?: string | null;
        backgroundImage?: { __typename?: "FileField"; url: string } | null;
        options: Array<{
          __typename?: "MultipleChoiceQuestionOptionRecord";
          id: any;
          answer?: string | null;
          isCorrect?: any | null;
        }>;
      }
    | {
        __typename?: "QrCodeTaskRecord";
        id: any;
        task?: string | null;
        referenceId?: string | null;
      }
    | {
        __typename?: "TextInputRecord";
        id: any;
        question?: string | null;
        correctAnswer?: string | null;
        backgroundImage?: { __typename?: "FileField"; url: string } | null;
      }
  >;
};

export type ResponsiveImageFieldsFragment = {
  __typename?: "ResponsiveImage";
  src: string;
  sizes: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt?: string | null;
  title?: string | null;
  bgColor?: string | null;
  base64?: string | null;
};

export type RoundSightSeeingSpotImageFragmentFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type RectangularSightSeeingSpotImageFragmentFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type StoryAudioDurationFieldsFragment = {
  __typename?: "StoryRecord";
  audioDurationInSeconds?: any | null;
  storySlides: Array<{
    __typename?: "StorySlideRecord";
    audioDurationInSeconds?: any | null;
  }>;
};

export type StorySlideAudioDurationFieldsFragment = {
  __typename?: "StorySlideRecord";
  audioDurationInSeconds?: any | null;
};

export type StoryFieldsFragment = {
  __typename?: "StoryRecord";
  id: any;
  title?: string | null;
  isPremium?: any | null;
  placeName?: string | null;
  displayedPlaceName?: string | null;
  _status: ItemStatus;
  isDiscoverable?: any | null;
  audioDurationInSeconds?: any | null;
  externalStoryUrl?: string | null;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
  storySlides: Array<{
    __typename?: "StorySlideRecord";
    id: any;
    isSlideAudioFixed?: any | null;
    audioDurationInSeconds?: any | null;
    isNavigationSlide?: any | null;
    speechMarks?: any | null;
    subtitles?: string | null;
    slideAudio?: {
      __typename?: "FileField";
      id: any;
      basename: string;
      filename: string;
      format: string;
      mimeType: string;
      size: any;
      url: string;
    } | null;
    slideVoiceAudios: Array<
      | { __typename?: "PlaceholderBlockRecord" }
      | {
          __typename?: "VoiceAudioRecord";
          voice?: { __typename?: "VoiceRecord"; id: any } | null;
          audio?: {
            __typename?: "FileField";
            id: any;
            basename: string;
            filename: string;
            format: string;
            mimeType: string;
            size: any;
            url: string;
          } | null;
        }
    >;
    mediaGallery: Array<
      | {
          __typename?: "ImageComparerRecord";
          id: any;
          caption?: string | null;
          imageBefore?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
          imageAfter?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: "ImageRecord";
          id: any;
          caption?: string | null;
          image?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: "VideoRecord";
          id: any;
          order?: any | null;
          caption?: string | null;
          externalVideoUrl?: {
            __typename?: "VideoField";
            provider: string;
            providerUid: string;
            thumbnailUrl: string;
            title: string;
            url: string;
            height: any;
            width: any;
          } | null;
          videoFile?: {
            __typename?: "FileField";
            url: string;
            height?: any | null;
            width?: any | null;
            mimeType: string;
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
          } | null;
        }
    >;
    panoramaImage: Array<{
      __typename?: "PanoramaImageRecord";
      defaultLongitude?: any | null;
      defaultLatitude?: any | null;
      defaultZoomLevel?: any | null;
      sphereCorrectionPan?: any | null;
      sphereCorrectionTilt?: any | null;
      sphereCorrectionRoll?: any | null;
      panoramaImageFile?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      markers: Array<{
        __typename?: "PanoramaImageMarkerRecord";
        id: any;
        longitude?: any | null;
        latitude?: any | null;
        title?: string | null;
      }>;
    }>;
    backgroundImage?: { __typename?: "FileField"; url: string } | null;
    slideActions: Array<
      | { __typename?: "PlaceholderBlockRecord" }
      | {
          __typename?: "SlideActionButtonRecord";
          label?: string | null;
          url?: string | null;
          variant?: string | null;
        }
    >;
    textTrack?: { __typename?: "FileField"; url: string } | null;
    quiz?: {
      __typename?: "QuizRecord";
      id: any;
      isBlocking?: any | null;
      isVisibleAtEndOfAudio?: any | null;
      quizTasks: Array<
        | {
            __typename?: "LocationTaskRecord";
            id: any;
            task?: string | null;
            maximumDistanceToPoint?: any | null;
            location?: {
              __typename?: "LatLonField";
              latitude: any;
              longitude: any;
            } | null;
            backgroundImage?: { __typename?: "FileField"; url: string } | null;
          }
        | {
            __typename?: "MultipleChoiceQuestionRecord";
            id: any;
            question?: string | null;
            backgroundImage?: { __typename?: "FileField"; url: string } | null;
            options: Array<{
              __typename?: "MultipleChoiceQuestionOptionRecord";
              id: any;
              answer?: string | null;
              isCorrect?: any | null;
            }>;
          }
        | {
            __typename?: "QrCodeTaskRecord";
            id: any;
            task?: string | null;
            referenceId?: string | null;
          }
        | {
            __typename?: "TextInputRecord";
            id: any;
            question?: string | null;
            correctAnswer?: string | null;
            backgroundImage?: { __typename?: "FileField"; url: string } | null;
          }
      >;
    } | null;
  }>;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    username?: string | null;
    slogan?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioPreview?: {
      __typename?: "FileField";
      url: string;
      mimeType: string;
    } | null;
    aboutMe?: {
      __typename?: "CreatorProfileModelAboutMeField";
      value: any;
      blocks: Array<
        | {
            __typename: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | { __typename: "PlaceholderBlockRecord"; id: any }
        | { __typename: "VideoRecord" }
      >;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    squareProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
};

export type StorySlideFieldsFragment = {
  __typename?: "StorySlideRecord";
  id: any;
  isSlideAudioFixed?: any | null;
  audioDurationInSeconds?: any | null;
  isNavigationSlide?: any | null;
  speechMarks?: any | null;
  subtitles?: string | null;
  slideAudio?: {
    __typename?: "FileField";
    id: any;
    basename: string;
    filename: string;
    format: string;
    mimeType: string;
    size: any;
    url: string;
  } | null;
  slideVoiceAudios: Array<
    | { __typename?: "PlaceholderBlockRecord" }
    | {
        __typename?: "VoiceAudioRecord";
        voice?: { __typename?: "VoiceRecord"; id: any } | null;
        audio?: {
          __typename?: "FileField";
          id: any;
          basename: string;
          filename: string;
          format: string;
          mimeType: string;
          size: any;
          url: string;
        } | null;
      }
  >;
  mediaGallery: Array<
    | {
        __typename?: "ImageComparerRecord";
        id: any;
        caption?: string | null;
        imageBefore?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
        imageAfter?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "ImageRecord";
        id: any;
        caption?: string | null;
        image?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "VideoRecord";
        id: any;
        order?: any | null;
        caption?: string | null;
        externalVideoUrl?: {
          __typename?: "VideoField";
          provider: string;
          providerUid: string;
          thumbnailUrl: string;
          title: string;
          url: string;
          height: any;
          width: any;
        } | null;
        videoFile?: {
          __typename?: "FileField";
          url: string;
          height?: any | null;
          width?: any | null;
          mimeType: string;
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
        } | null;
      }
  >;
  panoramaImage: Array<{
    __typename?: "PanoramaImageRecord";
    defaultLongitude?: any | null;
    defaultLatitude?: any | null;
    defaultZoomLevel?: any | null;
    sphereCorrectionPan?: any | null;
    sphereCorrectionTilt?: any | null;
    sphereCorrectionRoll?: any | null;
    panoramaImageFile?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    markers: Array<{
      __typename?: "PanoramaImageMarkerRecord";
      id: any;
      longitude?: any | null;
      latitude?: any | null;
      title?: string | null;
    }>;
  }>;
  backgroundImage?: { __typename?: "FileField"; url: string } | null;
  slideActions: Array<
    | { __typename?: "PlaceholderBlockRecord" }
    | {
        __typename?: "SlideActionButtonRecord";
        label?: string | null;
        url?: string | null;
        variant?: string | null;
      }
  >;
  textTrack?: { __typename?: "FileField"; url: string } | null;
  quiz?: {
    __typename?: "QuizRecord";
    id: any;
    isBlocking?: any | null;
    isVisibleAtEndOfAudio?: any | null;
    quizTasks: Array<
      | {
          __typename?: "LocationTaskRecord";
          id: any;
          task?: string | null;
          maximumDistanceToPoint?: any | null;
          location?: {
            __typename?: "LatLonField";
            latitude: any;
            longitude: any;
          } | null;
          backgroundImage?: { __typename?: "FileField"; url: string } | null;
        }
      | {
          __typename?: "MultipleChoiceQuestionRecord";
          id: any;
          question?: string | null;
          backgroundImage?: { __typename?: "FileField"; url: string } | null;
          options: Array<{
            __typename?: "MultipleChoiceQuestionOptionRecord";
            id: any;
            answer?: string | null;
            isCorrect?: any | null;
          }>;
        }
      | {
          __typename?: "QrCodeTaskRecord";
          id: any;
          task?: string | null;
          referenceId?: string | null;
        }
      | {
          __typename?: "TextInputRecord";
          id: any;
          question?: string | null;
          correctAnswer?: string | null;
          backgroundImage?: { __typename?: "FileField"; url: string } | null;
        }
    >;
  } | null;
};

export type StoryPreviewFieldsFragment = {
  __typename?: "StoryRecord";
  id: any;
  title?: string | null;
  isPremium?: any | null;
  placeName?: string | null;
  displayedPlaceName?: string | null;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  experienceLevels: Array<{
    __typename?: "ExperienceLevelRecord";
    id: any;
    title?: string | null;
    description?: string | null;
    color?: { __typename?: "ColorField"; hex: string } | null;
  }>;
};

export type StoryWithParentsFieldsFragment = {
  __typename?: "StoryRecord";
  id: any;
  title?: string | null;
  isPremium?: any | null;
  placeName?: string | null;
  displayedPlaceName?: string | null;
  _status: ItemStatus;
  isDiscoverable?: any | null;
  audioDurationInSeconds?: any | null;
  externalStoryUrl?: string | null;
  _allReferencingTourStops: Array<{
    __typename?: "TourStopRecord";
    id: any;
    _allReferencingTours: Array<{
      __typename?: "TourRecord";
      id: any;
      currentTourPrice?: any | null;
      regularTourPrice?: any | null;
    }>;
  }>;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
  storySlides: Array<{
    __typename?: "StorySlideRecord";
    id: any;
    isSlideAudioFixed?: any | null;
    audioDurationInSeconds?: any | null;
    isNavigationSlide?: any | null;
    speechMarks?: any | null;
    subtitles?: string | null;
    slideAudio?: {
      __typename?: "FileField";
      id: any;
      basename: string;
      filename: string;
      format: string;
      mimeType: string;
      size: any;
      url: string;
    } | null;
    slideVoiceAudios: Array<
      | { __typename?: "PlaceholderBlockRecord" }
      | {
          __typename?: "VoiceAudioRecord";
          voice?: { __typename?: "VoiceRecord"; id: any } | null;
          audio?: {
            __typename?: "FileField";
            id: any;
            basename: string;
            filename: string;
            format: string;
            mimeType: string;
            size: any;
            url: string;
          } | null;
        }
    >;
    mediaGallery: Array<
      | {
          __typename?: "ImageComparerRecord";
          id: any;
          caption?: string | null;
          imageBefore?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
          imageAfter?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: "ImageRecord";
          id: any;
          caption?: string | null;
          image?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: "VideoRecord";
          id: any;
          order?: any | null;
          caption?: string | null;
          externalVideoUrl?: {
            __typename?: "VideoField";
            provider: string;
            providerUid: string;
            thumbnailUrl: string;
            title: string;
            url: string;
            height: any;
            width: any;
          } | null;
          videoFile?: {
            __typename?: "FileField";
            url: string;
            height?: any | null;
            width?: any | null;
            mimeType: string;
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
          } | null;
        }
    >;
    panoramaImage: Array<{
      __typename?: "PanoramaImageRecord";
      defaultLongitude?: any | null;
      defaultLatitude?: any | null;
      defaultZoomLevel?: any | null;
      sphereCorrectionPan?: any | null;
      sphereCorrectionTilt?: any | null;
      sphereCorrectionRoll?: any | null;
      panoramaImageFile?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      markers: Array<{
        __typename?: "PanoramaImageMarkerRecord";
        id: any;
        longitude?: any | null;
        latitude?: any | null;
        title?: string | null;
      }>;
    }>;
    backgroundImage?: { __typename?: "FileField"; url: string } | null;
    slideActions: Array<
      | { __typename?: "PlaceholderBlockRecord" }
      | {
          __typename?: "SlideActionButtonRecord";
          label?: string | null;
          url?: string | null;
          variant?: string | null;
        }
    >;
    textTrack?: { __typename?: "FileField"; url: string } | null;
    quiz?: {
      __typename?: "QuizRecord";
      id: any;
      isBlocking?: any | null;
      isVisibleAtEndOfAudio?: any | null;
      quizTasks: Array<
        | {
            __typename?: "LocationTaskRecord";
            id: any;
            task?: string | null;
            maximumDistanceToPoint?: any | null;
            location?: {
              __typename?: "LatLonField";
              latitude: any;
              longitude: any;
            } | null;
            backgroundImage?: { __typename?: "FileField"; url: string } | null;
          }
        | {
            __typename?: "MultipleChoiceQuestionRecord";
            id: any;
            question?: string | null;
            backgroundImage?: { __typename?: "FileField"; url: string } | null;
            options: Array<{
              __typename?: "MultipleChoiceQuestionOptionRecord";
              id: any;
              answer?: string | null;
              isCorrect?: any | null;
            }>;
          }
        | {
            __typename?: "QrCodeTaskRecord";
            id: any;
            task?: string | null;
            referenceId?: string | null;
          }
        | {
            __typename?: "TextInputRecord";
            id: any;
            question?: string | null;
            correctAnswer?: string | null;
            backgroundImage?: { __typename?: "FileField"; url: string } | null;
          }
      >;
    } | null;
  }>;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    username?: string | null;
    slogan?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioPreview?: {
      __typename?: "FileField";
      url: string;
      mimeType: string;
    } | null;
    aboutMe?: {
      __typename?: "CreatorProfileModelAboutMeField";
      value: any;
      blocks: Array<
        | {
            __typename: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | { __typename: "PlaceholderBlockRecord"; id: any }
        | { __typename: "VideoRecord" }
      >;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    squareProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
};

export type StoryWithParentsPreviewFieldsFragment = {
  __typename?: "StoryRecord";
  id: any;
  title?: string | null;
  isPremium?: any | null;
  placeName?: string | null;
  displayedPlaceName?: string | null;
  _allReferencingTourStops: Array<{
    __typename?: "TourStopRecord";
    id: any;
    _allReferencingTours: Array<{
      __typename?: "TourRecord";
      id: any;
      currentTourPrice?: any | null;
      regularTourPrice?: any | null;
    }>;
  }>;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  experienceLevels: Array<{
    __typename?: "ExperienceLevelRecord";
    id: any;
    title?: string | null;
    description?: string | null;
    color?: { __typename?: "ColorField"; hex: string } | null;
  }>;
};

export type TextInputFieldsFragment = {
  __typename?: "TextInputRecord";
  id: any;
  question?: string | null;
  correctAnswer?: string | null;
  backgroundImage?: { __typename?: "FileField"; url: string } | null;
};

export type TourPreviewContentFieldsFragment = {
  __typename?: "TourModelTourPreviewContentField";
  value: any;
  blocks: Array<
    | {
        __typename: "ImageRecord";
        id: any;
        caption?: string | null;
        image?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      }
    | { __typename: "PlaceholderBlockRecord"; id: any }
  >;
};

type TourImpressionFields_ImageRecord_Fragment = {
  __typename: "ImageRecord";
  id: any;
  caption?: string | null;
  image?: {
    __typename?: "FileField";
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
};

type TourImpressionFields_PlaceholderBlockRecord_Fragment = {
  __typename: "PlaceholderBlockRecord";
  id: any;
};

export type TourImpressionFieldsFragment =
  | TourImpressionFields_ImageRecord_Fragment
  | TourImpressionFields_PlaceholderBlockRecord_Fragment;

export type TourFieldsFragment = {
  __typename?: "TourRecord";
  id: any;
  title?: string | null;
  slug?: string | null;
  _status: ItemStatus;
  isDiscoverable?: any | null;
  durationInMinutes?: any | null;
  durationByBikeInMinutes?: any | null;
  durationByBoatInMinutes?: any | null;
  durationByBusInMinutes?: any | null;
  durationByCarInMinutes?: any | null;
  durationByTrainInMinutes?: any | null;
  audioDurationInMinutes?: any | null;
  distanceInKilometers?: any | null;
  regularTourPrice?: any | null;
  currentTourPrice?: any | null;
  routeGeoJson?: any | null;
  isTrackableForTourStops?: any | null;
  maximumDistanceToTourStop?: any | null;
  highlights?: any | null;
  highlightImage?: {
    __typename?: "FileField";
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    username?: string | null;
    slogan?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioPreview?: {
      __typename?: "FileField";
      url: string;
      mimeType: string;
    } | null;
    aboutMe?: {
      __typename?: "CreatorProfileModelAboutMeField";
      value: any;
      blocks: Array<
        | {
            __typename: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | { __typename: "PlaceholderBlockRecord"; id: any }
        | { __typename: "VideoRecord" }
      >;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    squareProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
  tourPreviewContent?: {
    __typename?: "TourModelTourPreviewContentField";
    value: any;
    blocks: Array<
      | {
          __typename: "ImageRecord";
          id: any;
          caption?: string | null;
          image?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | { __typename: "PlaceholderBlockRecord"; id: any }
    >;
  } | null;
  tourImpressions: Array<
    | {
        __typename: "ImageRecord";
        id: any;
        caption?: string | null;
        image?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      }
    | { __typename: "PlaceholderBlockRecord"; id: any }
  >;
  mediaGallery: Array<{
    __typename?: "ImageRecord";
    image?: {
      __typename?: "FileField";
      id: any;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  }>;
  videoGallery: Array<
    | { __typename?: "PlaceholderBlockRecord" }
    | {
        __typename?: "VideoRecord";
        id: any;
        order?: any | null;
        caption?: string | null;
        externalVideoUrl?: {
          __typename?: "VideoField";
          provider: string;
          providerUid: string;
          thumbnailUrl: string;
          title: string;
          url: string;
          height: any;
          width: any;
        } | null;
        videoFile?: {
          __typename?: "FileField";
          url: string;
          height?: any | null;
          width?: any | null;
          mimeType: string;
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
        } | null;
      }
  >;
};

export type TourPreviewFieldsFragment = {
  __typename?: "TourRecord";
  id: any;
  title?: string | null;
  slug?: string | null;
  _status: ItemStatus;
  isDiscoverable?: any | null;
  audioDurationInMinutes?: any | null;
  durationInMinutes?: any | null;
  durationByBikeInMinutes?: any | null;
  durationByBoatInMinutes?: any | null;
  durationByBusInMinutes?: any | null;
  durationByCarInMinutes?: any | null;
  durationByTrainInMinutes?: any | null;
  distanceInKilometers?: any | null;
  regularTourPrice?: any | null;
  currentTourPrice?: any | null;
  routeGeoJson?: any | null;
  highlights?: any | null;
  highlightImage?: {
    __typename?: "FileField";
    responsiveImage?: {
      __typename?: "ResponsiveImage";
      src: string;
      sizes: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt?: string | null;
      title?: string | null;
      bgColor?: string | null;
      base64?: string | null;
    } | null;
  } | null;
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    creatorName?: string | null;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
};

export type TourStopFieldsFragment = {
  __typename?: "TourStopRecord";
  id: any;
  title?: string | null;
  placeName?: string | null;
  displayedPlaceName?: string | null;
  isIntroTourStop?: any | null;
  isOutroTourStop?: any | null;
  isPreviewTourStop?: any | null;
  maximumDistanceToNotificationTrigger?: any | null;
  notificationTriggerLocation?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
  location?: {
    __typename?: "LatLonField";
    latitude: any;
    longitude: any;
  } | null;
};

export type VideoRecordFieldsFragment = {
  __typename?: "VideoRecord";
  id: any;
  order?: any | null;
  caption?: string | null;
  externalVideoUrl?: {
    __typename?: "VideoField";
    provider: string;
    providerUid: string;
    thumbnailUrl: string;
    title: string;
    url: string;
    height: any;
    width: any;
  } | null;
  videoFile?: {
    __typename?: "FileField";
    url: string;
    height?: any | null;
    width?: any | null;
    mimeType: string;
    id: any;
    title?: string | null;
    copyright?: string | null;
    author?: string | null;
  } | null;
};

export type AppContentCountQueryVariables = Exact<{ [key: string]: never }>;

export type AppContentCountQuery = {
  __typename?: "Query";
  _allToursMeta: { __typename?: "CollectionMetadata"; count: any };
  _allStoriesMeta: { __typename?: "CollectionMetadata"; count: any };
};

export type ApplicationQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type ApplicationQuery = {
  __typename?: "Query";
  application?: {
    __typename?: "ApplicationRecord";
    homePageTitle?: string | null;
    homePageText?: string | null;
    homePageImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CategoriesQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
  isAvailableForDynamicTourCreation?: InputMaybe<Scalars["BooleanType"]>;
}>;

export type CategoriesQuery = {
  __typename?: "Query";
  categories: Array<{
    __typename?: "CategoryRecord";
    id: any;
    title?: string | null;
    color?: {
      __typename?: "ColorField";
      hex: string;
      red: any;
      green: any;
      blue: any;
    } | null;
    icon?: { __typename?: "FileField"; url: string } | null;
    colorIcon?: { __typename?: "FileField"; url: string } | null;
  }>;
};

export type CitiesWithStoriesCountQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type CitiesWithStoriesCountQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "CityRecord";
    isImageVisibleOnMap?: any | null;
    id: any;
    name?: string | null;
    title?: string | null;
    description?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    _allReferencingStoriesMeta: {
      __typename?: "CollectionMetadata";
      count: any;
    };
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  }>;
};

export type CitiesWithToursAndStoriesCountQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type CitiesWithToursAndStoriesCountQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "CityRecord";
    id: any;
    name?: string | null;
    title?: string | null;
    description?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    _allReferencingToursMeta: { __typename?: "CollectionMetadata"; count: any };
    _allReferencingStoriesMeta: {
      __typename?: "CollectionMetadata";
      count: any;
    };
  }>;
};

export type CitiesWithToursAndStoriesCountByCoordinatesQueryVariables = Exact<{
  locale: SiteLocale;
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
}>;

export type CitiesWithToursAndStoriesCountByCoordinatesQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "CityRecord";
    id: any;
    name?: string | null;
    title?: string | null;
    description?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    _allReferencingToursMeta: { __typename?: "CollectionMetadata"; count: any };
    _allReferencingStoriesMeta: {
      __typename?: "CollectionMetadata";
      count: any;
    };
  }>;
};

export type CitiesWithToursByCoordinatesQueryVariables = Exact<{
  locale: SiteLocale;
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type CitiesWithToursByCoordinatesQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "CityRecord";
    id: any;
    name?: string | null;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    _allReferencingTours: Array<{
      __typename?: "TourRecord";
      id: any;
      title?: string | null;
      slug?: string | null;
      _status: ItemStatus;
      isDiscoverable?: any | null;
      audioDurationInMinutes?: any | null;
      durationInMinutes?: any | null;
      durationByBikeInMinutes?: any | null;
      durationByBoatInMinutes?: any | null;
      durationByBusInMinutes?: any | null;
      durationByCarInMinutes?: any | null;
      durationByTrainInMinutes?: any | null;
      distanceInKilometers?: any | null;
      regularTourPrice?: any | null;
      currentTourPrice?: any | null;
      routeGeoJson?: any | null;
      highlights?: any | null;
      tourStops: Array<{
        __typename?: "TourStopRecord";
        id: any;
        isPreviewTourStop?: any | null;
        isIntroTourStop?: any | null;
        isOutroTourStop?: any | null;
        location?: {
          __typename?: "LatLonField";
          latitude: any;
          longitude: any;
        } | null;
        stories: Array<{
          __typename?: "StoryRecord";
          audioDurationInSeconds?: any | null;
          storySlides: Array<{
            __typename?: "StorySlideRecord";
            audioDurationInSeconds?: any | null;
          }>;
        }>;
      }>;
      _allReferencingTourTranslations: Array<{
        __typename?: "TourTranslationRecord";
        _allTourLocales?: Array<{
          __typename?: "TourRecordMultiLocaleField";
          locale?: SiteLocale | null;
          value?: { __typename?: "TourRecord"; id: any } | null;
        }> | null;
      }>;
      highlightImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      categories: Array<{
        __typename?: "CategoryRecord";
        id: any;
        title?: string | null;
        color?: {
          __typename?: "ColorField";
          hex: string;
          red: any;
          green: any;
          blue: any;
        } | null;
        icon?: { __typename?: "FileField"; url: string } | null;
        colorIcon?: { __typename?: "FileField"; url: string } | null;
      }>;
      creatorProfile?: {
        __typename?: "CreatorProfileRecord";
        creatorName?: string | null;
        roundProfileImage?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  }>;
};

export type CitiesWithToursNumberForLocalesQueryVariables = Exact<{
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type CitiesWithToursNumberForLocalesQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "CityRecord";
    de: { __typename?: "CollectionMetadata"; count: any };
    en: { __typename?: "CollectionMetadata"; count: any };
    es: { __typename?: "CollectionMetadata"; count: any };
    fr: { __typename?: "CollectionMetadata"; count: any };
  }>;
};

export type CityByCityIdQueryVariables = Exact<{
  locale: SiteLocale;
  cityId: Scalars["ItemId"];
}>;

export type CityByCityIdQuery = {
  __typename?: "Query";
  city?: {
    __typename?: "CityRecord";
    id: any;
    name?: string | null;
    title?: string | null;
    description?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  } | null;
};

export type CityByCoordinatesQueryVariables = Exact<{
  locale: SiteLocale;
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
}>;

export type CityByCoordinatesQuery = {
  __typename?: "Query";
  city?: {
    __typename?: "CityRecord";
    id: any;
    name?: string | null;
    title?: string | null;
    description?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  } | null;
};

export type CreatorProfileQueryVariables = Exact<{
  locale: SiteLocale;
  creatorProfileUsername: Scalars["String"];
}>;

export type CreatorProfileQuery = {
  __typename?: "Query";
  creatorProfile?: {
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    username?: string | null;
    slogan?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioPreview?: {
      __typename?: "FileField";
      url: string;
      mimeType: string;
    } | null;
    aboutMe?: {
      __typename?: "CreatorProfileModelAboutMeField";
      value: any;
      blocks: Array<
        | {
            __typename: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | { __typename: "PlaceholderBlockRecord"; id: any }
        | { __typename: "VideoRecord" }
      >;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    roundProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    squareProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DefaultCityImageQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultCityImageQuery = {
  __typename?: "Query";
  application?: {
    __typename?: "ApplicationRecord";
    defaultCityImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ExperienceLevelsQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type ExperienceLevelsQuery = {
  __typename?: "Query";
  experienceLevels: Array<{
    __typename?: "ExperienceLevelRecord";
    id: any;
    title?: string | null;
    description?: string | null;
    color?: { __typename?: "ColorField"; hex: string } | null;
  }>;
};

export type ExploreCreatorProfilesQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type ExploreCreatorProfilesQuery = {
  __typename?: "Query";
  creatorProfiles: Array<{
    __typename?: "CreatorProfileRecord";
    id: any;
    creatorName?: string | null;
    username?: string | null;
    slogan?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    squareProfileImage?: {
      __typename?: "FileField";
      id: any;
      title?: string | null;
      copyright?: string | null;
      author?: string | null;
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  }>;
};

export type ExploreSightseeingSpotsQueryVariables = Exact<{
  locale: SiteLocale;
  filter: SightseeingSpotModelFilter;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type ExploreSightseeingSpotsQuery = {
  __typename?: "Query";
  sightseeingSpots: Array<{
    __typename?: "SightseeingSpotRecord";
    id: any;
    name?: string | null;
    description?: string | null;
    isVisibleOnMap?: any | null;
    preview: Array<{
      __typename?: "ImageRecord";
      roundPreviewImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      rectangularPreviewImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    mediaGallery: Array<{
      __typename?: "ImageRecord";
      rectangularMediaGalleryImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  }>;
};

export type ExploreSightseeingSpotsByCoordinatesQueryVariables = Exact<{
  locale: SiteLocale;
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type ExploreSightseeingSpotsByCoordinatesQuery = {
  __typename?: "Query";
  sightseeingSpots: Array<{
    __typename?: "SightseeingSpotRecord";
    id: any;
    name?: string | null;
    description?: string | null;
    isVisibleOnMap?: any | null;
    preview: Array<{
      __typename?: "ImageRecord";
      roundPreviewImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      rectangularPreviewImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    mediaGallery: Array<{
      __typename?: "ImageRecord";
      rectangularMediaGalleryImage?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  }>;
};

export type ExploreStoriesQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
  cityId: Scalars["ItemId"];
  categoryIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
  experienceLevelIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
  excludeStoryIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type ExploreStoriesQuery = {
  __typename?: "Query";
  stories: Array<{
    __typename?: "StoryRecord";
    id: any;
    title?: string | null;
    isPremium?: any | null;
    placeName?: string | null;
    displayedPlaceName?: string | null;
    _allReferencingTourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      _allReferencingTours: Array<{
        __typename?: "TourRecord";
        id: any;
        currentTourPrice?: any | null;
        regularTourPrice?: any | null;
      }>;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    experienceLevels: Array<{
      __typename?: "ExperienceLevelRecord";
      id: any;
      title?: string | null;
      description?: string | null;
      color?: { __typename?: "ColorField"; hex: string } | null;
    }>;
  }>;
};

export type ExploreStoriesByCoordinatesQueryVariables = Exact<{
  locale: SiteLocale;
  latitude: Scalars["FloatType"];
  longitude: Scalars["FloatType"];
  radius: Scalars["FloatType"];
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type ExploreStoriesByCoordinatesQuery = {
  __typename?: "Query";
  stories: Array<{
    __typename?: "StoryRecord";
    id: any;
    title?: string | null;
    isPremium?: any | null;
    placeName?: string | null;
    displayedPlaceName?: string | null;
    _allReferencingTourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      _allReferencingTours: Array<{
        __typename?: "TourRecord";
        id: any;
        currentTourPrice?: any | null;
        regularTourPrice?: any | null;
      }>;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    experienceLevels: Array<{
      __typename?: "ExperienceLevelRecord";
      id: any;
      title?: string | null;
      description?: string | null;
      color?: { __typename?: "ColorField"; hex: string } | null;
    }>;
  }>;
};

export type GendersQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type GendersQuery = {
  __typename?: "Query";
  genders: Array<{
    __typename?: "GenderRecord";
    id: any;
    title?: string | null;
  }>;
};

export type LastAppVersionFeaturesQueryVariables = Exact<{
  locale: SiteLocale;
  filter: AppVersionFeatureModelFilter;
}>;

export type LastAppVersionFeaturesQuery = {
  __typename?: "Query";
  allAppVersionFeatures: Array<{
    __typename?: "AppVersionFeatureRecord";
    id: any;
    title?: string | null;
    description?: string | null;
    appVersion?: string | null;
    image?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    buttons: Array<
      | {
          __typename?: "LinkButtonRecord";
          id: any;
          text?: string | null;
          pathWithoutLocale?: string | null;
          fill?: string | null;
        }
      | { __typename?: "PlaceholderBlockRecord"; id: any }
    >;
  }>;
};

export type PopularCitiesQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type PopularCitiesQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "CityRecord";
    id: any;
    name?: string | null;
    title?: string | null;
    description?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  }>;
};

export type PopularSightseeingSpotsByCityIdQueryVariables = Exact<{
  cityId: Scalars["ItemId"];
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type PopularSightseeingSpotsByCityIdQuery = {
  __typename?: "Query";
  sightseeingSpots: Array<{
    __typename?: "SightseeingSpotRecord";
    id: any;
    name?: string | null;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
  }>;
};

export type PopularToursQueryVariables = Exact<{
  locale: SiteLocale;
  filter: TourModelFilter;
}>;

export type PopularToursQuery = {
  __typename?: "Query";
  tours: Array<{
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioDurationInMinutes?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      isPreviewTourStop?: any | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      stories: Array<{
        __typename?: "StoryRecord";
        audioDurationInSeconds?: any | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          audioDurationInSeconds?: any | null;
        }>;
      }>;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type SightseeingSpotByIdQueryVariables = Exact<{
  sightseeingSpotId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type SightseeingSpotByIdQuery = {
  __typename?: "Query";
  sightseeingSpot?: {
    __typename?: "SightseeingSpotRecord";
    id: any;
    name?: string | null;
    description?: string | null;
    mediaGallery: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        id: any;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  } | null;
};

export type StoriesByStoryIdsQueryVariables = Exact<{
  storyIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type StoriesByStoryIdsQuery = {
  __typename?: "Query";
  stories: Array<{
    __typename?: "StoryRecord";
    id: any;
    title?: string | null;
    isPremium?: any | null;
    placeName?: string | null;
    displayedPlaceName?: string | null;
    _allReferencingTourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      _allReferencingTours: Array<{
        __typename?: "TourRecord";
        id: any;
        currentTourPrice?: any | null;
        regularTourPrice?: any | null;
      }>;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    experienceLevels: Array<{
      __typename?: "ExperienceLevelRecord";
      id: any;
      title?: string | null;
      description?: string | null;
      color?: { __typename?: "ColorField"; hex: string } | null;
    }>;
  }>;
};

export type StoriesForCreatorProfileQueryVariables = Exact<{
  locale: SiteLocale;
  creatorProfileId: Scalars["ItemId"];
}>;

export type StoriesForCreatorProfileQuery = {
  __typename?: "Query";
  stories: Array<{
    __typename?: "StoryRecord";
    id: any;
    title?: string | null;
    isPremium?: any | null;
    placeName?: string | null;
    displayedPlaceName?: string | null;
    _allReferencingTourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      _allReferencingTours: Array<{
        __typename?: "TourRecord";
        id: any;
        currentTourPrice?: any | null;
        regularTourPrice?: any | null;
      }>;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    experienceLevels: Array<{
      __typename?: "ExperienceLevelRecord";
      id: any;
      title?: string | null;
      description?: string | null;
      color?: { __typename?: "ColorField"; hex: string } | null;
    }>;
  }>;
};

export type StoryByStoryIdQueryVariables = Exact<{
  storyId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type StoryByStoryIdQuery = {
  __typename?: "Query";
  story?: {
    __typename?: "StoryRecord";
    id: any;
    title?: string | null;
    isPremium?: any | null;
    placeName?: string | null;
    displayedPlaceName?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioDurationInSeconds?: any | null;
    externalStoryUrl?: string | null;
    _allReferencingTourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      _allReferencingTours: Array<{
        __typename?: "TourRecord";
        id: any;
        currentTourPrice?: any | null;
        regularTourPrice?: any | null;
      }>;
    }>;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    storySlides: Array<{
      __typename?: "StorySlideRecord";
      id: any;
      isSlideAudioFixed?: any | null;
      audioDurationInSeconds?: any | null;
      isNavigationSlide?: any | null;
      speechMarks?: any | null;
      subtitles?: string | null;
      slideAudio?: {
        __typename?: "FileField";
        id: any;
        basename: string;
        filename: string;
        format: string;
        mimeType: string;
        size: any;
        url: string;
      } | null;
      slideVoiceAudios: Array<
        | { __typename?: "PlaceholderBlockRecord" }
        | {
            __typename?: "VoiceAudioRecord";
            voice?: { __typename?: "VoiceRecord"; id: any } | null;
            audio?: {
              __typename?: "FileField";
              id: any;
              basename: string;
              filename: string;
              format: string;
              mimeType: string;
              size: any;
              url: string;
            } | null;
          }
      >;
      mediaGallery: Array<
        | {
            __typename?: "ImageComparerRecord";
            id: any;
            caption?: string | null;
            imageBefore?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
            imageAfter?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | {
            __typename?: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | {
            __typename?: "VideoRecord";
            id: any;
            order?: any | null;
            caption?: string | null;
            externalVideoUrl?: {
              __typename?: "VideoField";
              provider: string;
              providerUid: string;
              thumbnailUrl: string;
              title: string;
              url: string;
              height: any;
              width: any;
            } | null;
            videoFile?: {
              __typename?: "FileField";
              url: string;
              height?: any | null;
              width?: any | null;
              mimeType: string;
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
            } | null;
          }
      >;
      panoramaImage: Array<{
        __typename?: "PanoramaImageRecord";
        defaultLongitude?: any | null;
        defaultLatitude?: any | null;
        defaultZoomLevel?: any | null;
        sphereCorrectionPan?: any | null;
        sphereCorrectionTilt?: any | null;
        sphereCorrectionRoll?: any | null;
        panoramaImageFile?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
        markers: Array<{
          __typename?: "PanoramaImageMarkerRecord";
          id: any;
          longitude?: any | null;
          latitude?: any | null;
          title?: string | null;
        }>;
      }>;
      backgroundImage?: { __typename?: "FileField"; url: string } | null;
      slideActions: Array<
        | { __typename?: "PlaceholderBlockRecord" }
        | {
            __typename?: "SlideActionButtonRecord";
            label?: string | null;
            url?: string | null;
            variant?: string | null;
          }
      >;
      textTrack?: { __typename?: "FileField"; url: string } | null;
      quiz?: {
        __typename?: "QuizRecord";
        id: any;
        isBlocking?: any | null;
        isVisibleAtEndOfAudio?: any | null;
        quizTasks: Array<
          | {
              __typename?: "LocationTaskRecord";
              id: any;
              task?: string | null;
              maximumDistanceToPoint?: any | null;
              location?: {
                __typename?: "LatLonField";
                latitude: any;
                longitude: any;
              } | null;
              backgroundImage?: {
                __typename?: "FileField";
                url: string;
              } | null;
            }
          | {
              __typename?: "MultipleChoiceQuestionRecord";
              id: any;
              question?: string | null;
              backgroundImage?: {
                __typename?: "FileField";
                url: string;
              } | null;
              options: Array<{
                __typename?: "MultipleChoiceQuestionOptionRecord";
                id: any;
                answer?: string | null;
                isCorrect?: any | null;
              }>;
            }
          | {
              __typename?: "QrCodeTaskRecord";
              id: any;
              task?: string | null;
              referenceId?: string | null;
            }
          | {
              __typename?: "TextInputRecord";
              id: any;
              question?: string | null;
              correctAnswer?: string | null;
              backgroundImage?: {
                __typename?: "FileField";
                url: string;
              } | null;
            }
        >;
      } | null;
    }>;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      username?: string | null;
      slogan?: string | null;
      _status: ItemStatus;
      isDiscoverable?: any | null;
      audioPreview?: {
        __typename?: "FileField";
        url: string;
        mimeType: string;
      } | null;
      aboutMe?: {
        __typename?: "CreatorProfileModelAboutMeField";
        value: any;
        blocks: Array<
          | {
              __typename: "ImageRecord";
              id: any;
              caption?: string | null;
              image?: {
                __typename?: "FileField";
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
                responsiveImage?: {
                  __typename?: "ResponsiveImage";
                  src: string;
                  sizes: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt?: string | null;
                  title?: string | null;
                  bgColor?: string | null;
                  base64?: string | null;
                } | null;
              } | null;
            }
          | { __typename: "PlaceholderBlockRecord"; id: any }
          | { __typename: "VideoRecord" }
        >;
      } | null;
      categories: Array<{
        __typename?: "CategoryRecord";
        id: any;
        title?: string | null;
        color?: {
          __typename?: "ColorField";
          hex: string;
          red: any;
          green: any;
          blue: any;
        } | null;
        icon?: { __typename?: "FileField"; url: string } | null;
        colorIcon?: { __typename?: "FileField"; url: string } | null;
      }>;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      squareProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type StoryTranslationByIdQueryVariables = Exact<{
  storyTranslationId: Scalars["ItemId"];
}>;

export type StoryTranslationByIdQuery = {
  __typename?: "Query";
  storyTranslation?: {
    __typename?: "StoryTranslationRecord";
    _allStoryLocales?: Array<{
      __typename?: "StoryRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: { __typename?: "StoryRecord"; id: any } | null;
    }> | null;
  } | null;
};

export type ThemesQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type ThemesQuery = {
  __typename?: "Query";
  themes: Array<{
    __typename?: "ThemeRecord";
    id: any;
    title?: string | null;
    description?: string | null;
    image?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
  }>;
};

export type RectangularThemesImageFragmentFragment = {
  __typename?: "FileField";
  responsiveImage?: {
    __typename?: "ResponsiveImage";
    src: string;
    sizes: string;
    width: any;
    height: any;
    aspectRatio: any;
    alt?: string | null;
    title?: string | null;
    bgColor?: string | null;
    base64?: string | null;
  } | null;
};

export type TourByTourIdQueryVariables = Exact<{
  tourId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type TourByTourIdQuery = {
  __typename?: "Query";
  tour?: {
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    audioDurationInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    isTrackableForTourStops?: any | null;
    maximumDistanceToTourStop?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      title?: string | null;
      placeName?: string | null;
      displayedPlaceName?: string | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      isPreviewTourStop?: any | null;
      maximumDistanceToNotificationTrigger?: any | null;
      stories: Array<{
        __typename?: "StoryRecord";
        id: any;
        title?: string | null;
        isPremium?: any | null;
        placeName?: string | null;
        displayedPlaceName?: string | null;
        _status: ItemStatus;
        isDiscoverable?: any | null;
        audioDurationInSeconds?: any | null;
        externalStoryUrl?: string | null;
        location?: {
          __typename?: "LatLonField";
          latitude: any;
          longitude: any;
        } | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          id: any;
          isSlideAudioFixed?: any | null;
          audioDurationInSeconds?: any | null;
          isNavigationSlide?: any | null;
          speechMarks?: any | null;
          subtitles?: string | null;
          slideAudio?: {
            __typename?: "FileField";
            id: any;
            basename: string;
            filename: string;
            format: string;
            mimeType: string;
            size: any;
            url: string;
          } | null;
          slideVoiceAudios: Array<
            | { __typename?: "PlaceholderBlockRecord" }
            | {
                __typename?: "VoiceAudioRecord";
                voice?: { __typename?: "VoiceRecord"; id: any } | null;
                audio?: {
                  __typename?: "FileField";
                  id: any;
                  basename: string;
                  filename: string;
                  format: string;
                  mimeType: string;
                  size: any;
                  url: string;
                } | null;
              }
          >;
          mediaGallery: Array<
            | {
                __typename?: "ImageComparerRecord";
                id: any;
                caption?: string | null;
                imageBefore?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
                imageAfter?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
              }
            | {
                __typename?: "ImageRecord";
                id: any;
                caption?: string | null;
                image?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
              }
            | {
                __typename?: "VideoRecord";
                id: any;
                order?: any | null;
                caption?: string | null;
                externalVideoUrl?: {
                  __typename?: "VideoField";
                  provider: string;
                  providerUid: string;
                  thumbnailUrl: string;
                  title: string;
                  url: string;
                  height: any;
                  width: any;
                } | null;
                videoFile?: {
                  __typename?: "FileField";
                  url: string;
                  height?: any | null;
                  width?: any | null;
                  mimeType: string;
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                } | null;
              }
          >;
          panoramaImage: Array<{
            __typename?: "PanoramaImageRecord";
            defaultLongitude?: any | null;
            defaultLatitude?: any | null;
            defaultZoomLevel?: any | null;
            sphereCorrectionPan?: any | null;
            sphereCorrectionTilt?: any | null;
            sphereCorrectionRoll?: any | null;
            panoramaImageFile?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
            markers: Array<{
              __typename?: "PanoramaImageMarkerRecord";
              id: any;
              longitude?: any | null;
              latitude?: any | null;
              title?: string | null;
            }>;
          }>;
          backgroundImage?: { __typename?: "FileField"; url: string } | null;
          slideActions: Array<
            | { __typename?: "PlaceholderBlockRecord" }
            | {
                __typename?: "SlideActionButtonRecord";
                label?: string | null;
                url?: string | null;
                variant?: string | null;
              }
          >;
          textTrack?: { __typename?: "FileField"; url: string } | null;
          quiz?: {
            __typename?: "QuizRecord";
            id: any;
            isBlocking?: any | null;
            isVisibleAtEndOfAudio?: any | null;
            quizTasks: Array<
              | {
                  __typename?: "LocationTaskRecord";
                  id: any;
                  task?: string | null;
                  maximumDistanceToPoint?: any | null;
                  location?: {
                    __typename?: "LatLonField";
                    latitude: any;
                    longitude: any;
                  } | null;
                  backgroundImage?: {
                    __typename?: "FileField";
                    url: string;
                  } | null;
                }
              | {
                  __typename?: "MultipleChoiceQuestionRecord";
                  id: any;
                  question?: string | null;
                  backgroundImage?: {
                    __typename?: "FileField";
                    url: string;
                  } | null;
                  options: Array<{
                    __typename?: "MultipleChoiceQuestionOptionRecord";
                    id: any;
                    answer?: string | null;
                    isCorrect?: any | null;
                  }>;
                }
              | {
                  __typename?: "QrCodeTaskRecord";
                  id: any;
                  task?: string | null;
                  referenceId?: string | null;
                }
              | {
                  __typename?: "TextInputRecord";
                  id: any;
                  question?: string | null;
                  correctAnswer?: string | null;
                  backgroundImage?: {
                    __typename?: "FileField";
                    url: string;
                  } | null;
                }
            >;
          } | null;
        }>;
        categories: Array<{
          __typename?: "CategoryRecord";
          id: any;
          title?: string | null;
          color?: {
            __typename?: "ColorField";
            hex: string;
            red: any;
            green: any;
            blue: any;
          } | null;
          icon?: { __typename?: "FileField"; url: string } | null;
          colorIcon?: { __typename?: "FileField"; url: string } | null;
        }>;
        creatorProfile?: {
          __typename?: "CreatorProfileRecord";
          id: any;
          creatorName?: string | null;
          username?: string | null;
          slogan?: string | null;
          _status: ItemStatus;
          isDiscoverable?: any | null;
          audioPreview?: {
            __typename?: "FileField";
            url: string;
            mimeType: string;
          } | null;
          aboutMe?: {
            __typename?: "CreatorProfileModelAboutMeField";
            value: any;
            blocks: Array<
              | {
                  __typename: "ImageRecord";
                  id: any;
                  caption?: string | null;
                  image?: {
                    __typename?: "FileField";
                    id: any;
                    title?: string | null;
                    copyright?: string | null;
                    author?: string | null;
                    responsiveImage?: {
                      __typename?: "ResponsiveImage";
                      src: string;
                      sizes: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt?: string | null;
                      title?: string | null;
                      bgColor?: string | null;
                      base64?: string | null;
                    } | null;
                  } | null;
                }
              | { __typename: "PlaceholderBlockRecord"; id: any }
              | { __typename: "VideoRecord" }
            >;
          } | null;
          categories: Array<{
            __typename?: "CategoryRecord";
            id: any;
            title?: string | null;
            color?: {
              __typename?: "ColorField";
              hex: string;
              red: any;
              green: any;
              blue: any;
            } | null;
            icon?: { __typename?: "FileField"; url: string } | null;
            colorIcon?: { __typename?: "FileField"; url: string } | null;
          }>;
          roundProfileImage?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
          squareProfileImage?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        } | null;
      }>;
      notificationTriggerLocation?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      username?: string | null;
      slogan?: string | null;
      _status: ItemStatus;
      isDiscoverable?: any | null;
      audioPreview?: {
        __typename?: "FileField";
        url: string;
        mimeType: string;
      } | null;
      aboutMe?: {
        __typename?: "CreatorProfileModelAboutMeField";
        value: any;
        blocks: Array<
          | {
              __typename: "ImageRecord";
              id: any;
              caption?: string | null;
              image?: {
                __typename?: "FileField";
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
                responsiveImage?: {
                  __typename?: "ResponsiveImage";
                  src: string;
                  sizes: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt?: string | null;
                  title?: string | null;
                  bgColor?: string | null;
                  base64?: string | null;
                } | null;
              } | null;
            }
          | { __typename: "PlaceholderBlockRecord"; id: any }
          | { __typename: "VideoRecord" }
        >;
      } | null;
      categories: Array<{
        __typename?: "CategoryRecord";
        id: any;
        title?: string | null;
        color?: {
          __typename?: "ColorField";
          hex: string;
          red: any;
          green: any;
          blue: any;
        } | null;
        icon?: { __typename?: "FileField"; url: string } | null;
        colorIcon?: { __typename?: "FileField"; url: string } | null;
      }>;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      squareProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
    tourPreviewContent?: {
      __typename?: "TourModelTourPreviewContentField";
      value: any;
      blocks: Array<
        | {
            __typename: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | { __typename: "PlaceholderBlockRecord"; id: any }
      >;
    } | null;
    tourImpressions: Array<
      | {
          __typename: "ImageRecord";
          id: any;
          caption?: string | null;
          image?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | { __typename: "PlaceholderBlockRecord"; id: any }
    >;
    mediaGallery: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        id: any;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    videoGallery: Array<
      | { __typename?: "PlaceholderBlockRecord" }
      | {
          __typename?: "VideoRecord";
          id: any;
          order?: any | null;
          caption?: string | null;
          externalVideoUrl?: {
            __typename?: "VideoField";
            provider: string;
            providerUid: string;
            thumbnailUrl: string;
            title: string;
            url: string;
            height: any;
            width: any;
          } | null;
          videoFile?: {
            __typename?: "FileField";
            url: string;
            height?: any | null;
            width?: any | null;
            mimeType: string;
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
          } | null;
        }
    >;
  } | null;
};

export type TourByTourSlugQueryVariables = Exact<{
  tourSlug: Scalars["String"];
  locale: SiteLocale;
}>;

export type TourByTourSlugQuery = {
  __typename?: "Query";
  tour?: {
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    audioDurationInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    isTrackableForTourStops?: any | null;
    maximumDistanceToTourStop?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      title?: string | null;
      placeName?: string | null;
      displayedPlaceName?: string | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      isPreviewTourStop?: any | null;
      maximumDistanceToNotificationTrigger?: any | null;
      stories: Array<{
        __typename?: "StoryRecord";
        id: any;
        title?: string | null;
        isPremium?: any | null;
        placeName?: string | null;
        displayedPlaceName?: string | null;
        _status: ItemStatus;
        isDiscoverable?: any | null;
        audioDurationInSeconds?: any | null;
        externalStoryUrl?: string | null;
        location?: {
          __typename?: "LatLonField";
          latitude: any;
          longitude: any;
        } | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          id: any;
          isSlideAudioFixed?: any | null;
          audioDurationInSeconds?: any | null;
          isNavigationSlide?: any | null;
          speechMarks?: any | null;
          subtitles?: string | null;
          slideAudio?: {
            __typename?: "FileField";
            id: any;
            basename: string;
            filename: string;
            format: string;
            mimeType: string;
            size: any;
            url: string;
          } | null;
          slideVoiceAudios: Array<
            | { __typename?: "PlaceholderBlockRecord" }
            | {
                __typename?: "VoiceAudioRecord";
                voice?: { __typename?: "VoiceRecord"; id: any } | null;
                audio?: {
                  __typename?: "FileField";
                  id: any;
                  basename: string;
                  filename: string;
                  format: string;
                  mimeType: string;
                  size: any;
                  url: string;
                } | null;
              }
          >;
          mediaGallery: Array<
            | {
                __typename?: "ImageComparerRecord";
                id: any;
                caption?: string | null;
                imageBefore?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
                imageAfter?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
              }
            | {
                __typename?: "ImageRecord";
                id: any;
                caption?: string | null;
                image?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
              }
            | {
                __typename?: "VideoRecord";
                id: any;
                order?: any | null;
                caption?: string | null;
                externalVideoUrl?: {
                  __typename?: "VideoField";
                  provider: string;
                  providerUid: string;
                  thumbnailUrl: string;
                  title: string;
                  url: string;
                  height: any;
                  width: any;
                } | null;
                videoFile?: {
                  __typename?: "FileField";
                  url: string;
                  height?: any | null;
                  width?: any | null;
                  mimeType: string;
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                } | null;
              }
          >;
          panoramaImage: Array<{
            __typename?: "PanoramaImageRecord";
            defaultLongitude?: any | null;
            defaultLatitude?: any | null;
            defaultZoomLevel?: any | null;
            sphereCorrectionPan?: any | null;
            sphereCorrectionTilt?: any | null;
            sphereCorrectionRoll?: any | null;
            panoramaImageFile?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
            markers: Array<{
              __typename?: "PanoramaImageMarkerRecord";
              id: any;
              longitude?: any | null;
              latitude?: any | null;
              title?: string | null;
            }>;
          }>;
          backgroundImage?: { __typename?: "FileField"; url: string } | null;
          slideActions: Array<
            | { __typename?: "PlaceholderBlockRecord" }
            | {
                __typename?: "SlideActionButtonRecord";
                label?: string | null;
                url?: string | null;
                variant?: string | null;
              }
          >;
          textTrack?: { __typename?: "FileField"; url: string } | null;
          quiz?: {
            __typename?: "QuizRecord";
            id: any;
            isBlocking?: any | null;
            isVisibleAtEndOfAudio?: any | null;
            quizTasks: Array<
              | {
                  __typename?: "LocationTaskRecord";
                  id: any;
                  task?: string | null;
                  maximumDistanceToPoint?: any | null;
                  location?: {
                    __typename?: "LatLonField";
                    latitude: any;
                    longitude: any;
                  } | null;
                  backgroundImage?: {
                    __typename?: "FileField";
                    url: string;
                  } | null;
                }
              | {
                  __typename?: "MultipleChoiceQuestionRecord";
                  id: any;
                  question?: string | null;
                  backgroundImage?: {
                    __typename?: "FileField";
                    url: string;
                  } | null;
                  options: Array<{
                    __typename?: "MultipleChoiceQuestionOptionRecord";
                    id: any;
                    answer?: string | null;
                    isCorrect?: any | null;
                  }>;
                }
              | {
                  __typename?: "QrCodeTaskRecord";
                  id: any;
                  task?: string | null;
                  referenceId?: string | null;
                }
              | {
                  __typename?: "TextInputRecord";
                  id: any;
                  question?: string | null;
                  correctAnswer?: string | null;
                  backgroundImage?: {
                    __typename?: "FileField";
                    url: string;
                  } | null;
                }
            >;
          } | null;
        }>;
        categories: Array<{
          __typename?: "CategoryRecord";
          id: any;
          title?: string | null;
          color?: {
            __typename?: "ColorField";
            hex: string;
            red: any;
            green: any;
            blue: any;
          } | null;
          icon?: { __typename?: "FileField"; url: string } | null;
          colorIcon?: { __typename?: "FileField"; url: string } | null;
        }>;
        creatorProfile?: {
          __typename?: "CreatorProfileRecord";
          id: any;
          creatorName?: string | null;
          username?: string | null;
          slogan?: string | null;
          _status: ItemStatus;
          isDiscoverable?: any | null;
          audioPreview?: {
            __typename?: "FileField";
            url: string;
            mimeType: string;
          } | null;
          aboutMe?: {
            __typename?: "CreatorProfileModelAboutMeField";
            value: any;
            blocks: Array<
              | {
                  __typename: "ImageRecord";
                  id: any;
                  caption?: string | null;
                  image?: {
                    __typename?: "FileField";
                    id: any;
                    title?: string | null;
                    copyright?: string | null;
                    author?: string | null;
                    responsiveImage?: {
                      __typename?: "ResponsiveImage";
                      src: string;
                      sizes: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt?: string | null;
                      title?: string | null;
                      bgColor?: string | null;
                      base64?: string | null;
                    } | null;
                  } | null;
                }
              | { __typename: "PlaceholderBlockRecord"; id: any }
              | { __typename: "VideoRecord" }
            >;
          } | null;
          categories: Array<{
            __typename?: "CategoryRecord";
            id: any;
            title?: string | null;
            color?: {
              __typename?: "ColorField";
              hex: string;
              red: any;
              green: any;
              blue: any;
            } | null;
            icon?: { __typename?: "FileField"; url: string } | null;
            colorIcon?: { __typename?: "FileField"; url: string } | null;
          }>;
          roundProfileImage?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
          squareProfileImage?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        } | null;
      }>;
      notificationTriggerLocation?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      id: any;
      creatorName?: string | null;
      username?: string | null;
      slogan?: string | null;
      _status: ItemStatus;
      isDiscoverable?: any | null;
      audioPreview?: {
        __typename?: "FileField";
        url: string;
        mimeType: string;
      } | null;
      aboutMe?: {
        __typename?: "CreatorProfileModelAboutMeField";
        value: any;
        blocks: Array<
          | {
              __typename: "ImageRecord";
              id: any;
              caption?: string | null;
              image?: {
                __typename?: "FileField";
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
                responsiveImage?: {
                  __typename?: "ResponsiveImage";
                  src: string;
                  sizes: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt?: string | null;
                  title?: string | null;
                  bgColor?: string | null;
                  base64?: string | null;
                } | null;
              } | null;
            }
          | { __typename: "PlaceholderBlockRecord"; id: any }
          | { __typename: "VideoRecord" }
        >;
      } | null;
      categories: Array<{
        __typename?: "CategoryRecord";
        id: any;
        title?: string | null;
        color?: {
          __typename?: "ColorField";
          hex: string;
          red: any;
          green: any;
          blue: any;
        } | null;
        icon?: { __typename?: "FileField"; url: string } | null;
        colorIcon?: { __typename?: "FileField"; url: string } | null;
      }>;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
      squareProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
    tourPreviewContent?: {
      __typename?: "TourModelTourPreviewContentField";
      value: any;
      blocks: Array<
        | {
            __typename: "ImageRecord";
            id: any;
            caption?: string | null;
            image?: {
              __typename?: "FileField";
              id: any;
              title?: string | null;
              copyright?: string | null;
              author?: string | null;
              responsiveImage?: {
                __typename?: "ResponsiveImage";
                src: string;
                sizes: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt?: string | null;
                title?: string | null;
                bgColor?: string | null;
                base64?: string | null;
              } | null;
            } | null;
          }
        | { __typename: "PlaceholderBlockRecord"; id: any }
      >;
    } | null;
    tourImpressions: Array<
      | {
          __typename: "ImageRecord";
          id: any;
          caption?: string | null;
          image?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
        }
      | { __typename: "PlaceholderBlockRecord"; id: any }
    >;
    mediaGallery: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        id: any;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
    videoGallery: Array<
      | { __typename?: "PlaceholderBlockRecord" }
      | {
          __typename?: "VideoRecord";
          id: any;
          order?: any | null;
          caption?: string | null;
          externalVideoUrl?: {
            __typename?: "VideoField";
            provider: string;
            providerUid: string;
            thumbnailUrl: string;
            title: string;
            url: string;
            height: any;
            width: any;
          } | null;
          videoFile?: {
            __typename?: "FileField";
            url: string;
            height?: any | null;
            width?: any | null;
            mimeType: string;
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
          } | null;
        }
    >;
  } | null;
};

export type TourCollectionByIdQueryVariables = Exact<{
  tourCollectionId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type TourCollectionByIdQuery = {
  __typename?: "Query";
  tourCollection?: {
    __typename?: "TourCollectionRecord";
    id: any;
    title?: string | null;
    longDescription?: string | null;
    mediaGallery: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type TourCollectionsByCityIdQueryVariables = Exact<{
  cityId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type TourCollectionsByCityIdQuery = {
  __typename?: "Query";
  tourCollections: Array<{
    __typename?: "TourCollectionRecord";
    id: any;
    name?: string | null;
    shortDescription?: string | null;
    preview: Array<{
      __typename?: "ImageRecord";
      image?: {
        __typename?: "FileField";
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    }>;
  }>;
};

export type TourStopByTourStopIdQueryVariables = Exact<{
  tourStopId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type TourStopByTourStopIdQuery = {
  __typename?: "Query";
  tourStop?: {
    __typename?: "TourStopRecord";
    id: any;
    title?: string | null;
    placeName?: string | null;
    displayedPlaceName?: string | null;
    isIntroTourStop?: any | null;
    isOutroTourStop?: any | null;
    isPreviewTourStop?: any | null;
    maximumDistanceToNotificationTrigger?: any | null;
    stories: Array<{
      __typename?: "StoryRecord";
      id: any;
      title?: string | null;
      isPremium?: any | null;
      placeName?: string | null;
      displayedPlaceName?: string | null;
      _status: ItemStatus;
      isDiscoverable?: any | null;
      audioDurationInSeconds?: any | null;
      externalStoryUrl?: string | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      storySlides: Array<{
        __typename?: "StorySlideRecord";
        id: any;
        isSlideAudioFixed?: any | null;
        audioDurationInSeconds?: any | null;
        isNavigationSlide?: any | null;
        speechMarks?: any | null;
        subtitles?: string | null;
        slideAudio?: {
          __typename?: "FileField";
          id: any;
          basename: string;
          filename: string;
          format: string;
          mimeType: string;
          size: any;
          url: string;
        } | null;
        slideVoiceAudios: Array<
          | { __typename?: "PlaceholderBlockRecord" }
          | {
              __typename?: "VoiceAudioRecord";
              voice?: { __typename?: "VoiceRecord"; id: any } | null;
              audio?: {
                __typename?: "FileField";
                id: any;
                basename: string;
                filename: string;
                format: string;
                mimeType: string;
                size: any;
                url: string;
              } | null;
            }
        >;
        mediaGallery: Array<
          | {
              __typename?: "ImageComparerRecord";
              id: any;
              caption?: string | null;
              imageBefore?: {
                __typename?: "FileField";
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
                responsiveImage?: {
                  __typename?: "ResponsiveImage";
                  src: string;
                  sizes: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt?: string | null;
                  title?: string | null;
                  bgColor?: string | null;
                  base64?: string | null;
                } | null;
              } | null;
              imageAfter?: {
                __typename?: "FileField";
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
                responsiveImage?: {
                  __typename?: "ResponsiveImage";
                  src: string;
                  sizes: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt?: string | null;
                  title?: string | null;
                  bgColor?: string | null;
                  base64?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: "ImageRecord";
              id: any;
              caption?: string | null;
              image?: {
                __typename?: "FileField";
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
                responsiveImage?: {
                  __typename?: "ResponsiveImage";
                  src: string;
                  sizes: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt?: string | null;
                  title?: string | null;
                  bgColor?: string | null;
                  base64?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: "VideoRecord";
              id: any;
              order?: any | null;
              caption?: string | null;
              externalVideoUrl?: {
                __typename?: "VideoField";
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                height: any;
                width: any;
              } | null;
              videoFile?: {
                __typename?: "FileField";
                url: string;
                height?: any | null;
                width?: any | null;
                mimeType: string;
                id: any;
                title?: string | null;
                copyright?: string | null;
                author?: string | null;
              } | null;
            }
        >;
        panoramaImage: Array<{
          __typename?: "PanoramaImageRecord";
          defaultLongitude?: any | null;
          defaultLatitude?: any | null;
          defaultZoomLevel?: any | null;
          sphereCorrectionPan?: any | null;
          sphereCorrectionTilt?: any | null;
          sphereCorrectionRoll?: any | null;
          panoramaImageFile?: {
            __typename?: "FileField";
            id: any;
            title?: string | null;
            copyright?: string | null;
            author?: string | null;
            responsiveImage?: {
              __typename?: "ResponsiveImage";
              src: string;
              sizes: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt?: string | null;
              title?: string | null;
              bgColor?: string | null;
              base64?: string | null;
            } | null;
          } | null;
          markers: Array<{
            __typename?: "PanoramaImageMarkerRecord";
            id: any;
            longitude?: any | null;
            latitude?: any | null;
            title?: string | null;
          }>;
        }>;
        backgroundImage?: { __typename?: "FileField"; url: string } | null;
        slideActions: Array<
          | { __typename?: "PlaceholderBlockRecord" }
          | {
              __typename?: "SlideActionButtonRecord";
              label?: string | null;
              url?: string | null;
              variant?: string | null;
            }
        >;
        textTrack?: { __typename?: "FileField"; url: string } | null;
        quiz?: {
          __typename?: "QuizRecord";
          id: any;
          isBlocking?: any | null;
          isVisibleAtEndOfAudio?: any | null;
          quizTasks: Array<
            | {
                __typename?: "LocationTaskRecord";
                id: any;
                task?: string | null;
                maximumDistanceToPoint?: any | null;
                location?: {
                  __typename?: "LatLonField";
                  latitude: any;
                  longitude: any;
                } | null;
                backgroundImage?: {
                  __typename?: "FileField";
                  url: string;
                } | null;
              }
            | {
                __typename?: "MultipleChoiceQuestionRecord";
                id: any;
                question?: string | null;
                backgroundImage?: {
                  __typename?: "FileField";
                  url: string;
                } | null;
                options: Array<{
                  __typename?: "MultipleChoiceQuestionOptionRecord";
                  id: any;
                  answer?: string | null;
                  isCorrect?: any | null;
                }>;
              }
            | {
                __typename?: "QrCodeTaskRecord";
                id: any;
                task?: string | null;
                referenceId?: string | null;
              }
            | {
                __typename?: "TextInputRecord";
                id: any;
                question?: string | null;
                correctAnswer?: string | null;
                backgroundImage?: {
                  __typename?: "FileField";
                  url: string;
                } | null;
              }
          >;
        } | null;
      }>;
      categories: Array<{
        __typename?: "CategoryRecord";
        id: any;
        title?: string | null;
        color?: {
          __typename?: "ColorField";
          hex: string;
          red: any;
          green: any;
          blue: any;
        } | null;
        icon?: { __typename?: "FileField"; url: string } | null;
        colorIcon?: { __typename?: "FileField"; url: string } | null;
      }>;
      creatorProfile?: {
        __typename?: "CreatorProfileRecord";
        id: any;
        creatorName?: string | null;
        username?: string | null;
        slogan?: string | null;
        _status: ItemStatus;
        isDiscoverable?: any | null;
        audioPreview?: {
          __typename?: "FileField";
          url: string;
          mimeType: string;
        } | null;
        aboutMe?: {
          __typename?: "CreatorProfileModelAboutMeField";
          value: any;
          blocks: Array<
            | {
                __typename: "ImageRecord";
                id: any;
                caption?: string | null;
                image?: {
                  __typename?: "FileField";
                  id: any;
                  title?: string | null;
                  copyright?: string | null;
                  author?: string | null;
                  responsiveImage?: {
                    __typename?: "ResponsiveImage";
                    src: string;
                    sizes: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt?: string | null;
                    title?: string | null;
                    bgColor?: string | null;
                    base64?: string | null;
                  } | null;
                } | null;
              }
            | { __typename: "PlaceholderBlockRecord"; id: any }
            | { __typename: "VideoRecord" }
          >;
        } | null;
        categories: Array<{
          __typename?: "CategoryRecord";
          id: any;
          title?: string | null;
          color?: {
            __typename?: "ColorField";
            hex: string;
            red: any;
            green: any;
            blue: any;
          } | null;
          icon?: { __typename?: "FileField"; url: string } | null;
          colorIcon?: { __typename?: "FileField"; url: string } | null;
        }>;
        roundProfileImage?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
        squareProfileImage?: {
          __typename?: "FileField";
          id: any;
          title?: string | null;
          copyright?: string | null;
          author?: string | null;
          responsiveImage?: {
            __typename?: "ResponsiveImage";
            src: string;
            sizes: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt?: string | null;
            title?: string | null;
            bgColor?: string | null;
            base64?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
    notificationTriggerLocation?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
    location?: {
      __typename?: "LatLonField";
      latitude: any;
      longitude: any;
    } | null;
  } | null;
};

export type TourStopTranslationByIdQueryVariables = Exact<{
  tourStopTranslationId: Scalars["ItemId"];
}>;

export type TourStopTranslationByIdQuery = {
  __typename?: "Query";
  tourStopTranslation?: {
    __typename?: "TourStopTranslationRecord";
    _allTourStopLocales?: Array<{
      __typename?: "TourStopRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: {
        __typename?: "TourStopRecord";
        id: any;
        stories: Array<{ __typename?: "StoryRecord"; id: any }>;
      } | null;
    }> | null;
    _allTourLocales?: Array<{
      __typename?: "TourRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: {
        __typename?: "TourRecord";
        id: any;
        tourStops: Array<{ __typename?: "TourStopRecord"; id: any }>;
      } | null;
    }> | null;
  } | null;
};

export type TourTranslationByTourIdQueryVariables = Exact<{
  tourId: Scalars["ItemId"];
}>;

export type TourTranslationByTourIdQuery = {
  __typename?: "Query";
  en?: {
    __typename?: "TourTranslationRecord";
    _allTourLocales?: Array<{
      __typename?: "TourRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: {
        __typename?: "TourRecord";
        id: any;
        slug?: string | null;
      } | null;
    }> | null;
  } | null;
  de?: {
    __typename?: "TourTranslationRecord";
    _allTourLocales?: Array<{
      __typename?: "TourRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: {
        __typename?: "TourRecord";
        id: any;
        slug?: string | null;
      } | null;
    }> | null;
  } | null;
  fr?: {
    __typename?: "TourTranslationRecord";
    _allTourLocales?: Array<{
      __typename?: "TourRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: {
        __typename?: "TourRecord";
        id: any;
        slug?: string | null;
      } | null;
    }> | null;
  } | null;
  it?: {
    __typename?: "TourTranslationRecord";
    _allTourLocales?: Array<{
      __typename?: "TourRecordMultiLocaleField";
      locale?: SiteLocale | null;
      value?: {
        __typename?: "TourRecord";
        id: any;
        slug?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type ToursAndStoriesCountByCityIdQueryVariables = Exact<{
  cityId: Scalars["ItemId"];
}>;

export type ToursAndStoriesCountByCityIdQuery = {
  __typename?: "Query";
  city?: {
    __typename?: "CityRecord";
    _allReferencingToursMeta: { __typename?: "CollectionMetadata"; count: any };
    _allReferencingStoriesMeta: {
      __typename?: "CollectionMetadata";
      count: any;
    };
  } | null;
};

export type ToursByTourCollectionIdQueryVariables = Exact<{
  tourCollectionId: Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type ToursByTourCollectionIdQuery = {
  __typename?: "Query";
  tours: Array<{
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioDurationInMinutes?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      isPreviewTourStop?: any | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      stories: Array<{
        __typename?: "StoryRecord";
        audioDurationInSeconds?: any | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          audioDurationInSeconds?: any | null;
        }>;
      }>;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type ToursByTourIdsQueryVariables = Exact<{
  tourIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type ToursByTourIdsQuery = {
  __typename?: "Query";
  tours: Array<{
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioDurationInMinutes?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      isPreviewTourStop?: any | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      stories: Array<{
        __typename?: "StoryRecord";
        audioDurationInSeconds?: any | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          audioDurationInSeconds?: any | null;
        }>;
      }>;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type ToursByTourStopIdsQueryVariables = Exact<{
  locale: SiteLocale;
  tourStopIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type ToursByTourStopIdsQuery = {
  __typename?: "Query";
  tours: Array<{
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioDurationInMinutes?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      isPreviewTourStop?: any | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      stories: Array<{
        __typename?: "StoryRecord";
        audioDurationInSeconds?: any | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          audioDurationInSeconds?: any | null;
        }>;
      }>;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type ToursForCreatorProfileQueryVariables = Exact<{
  locale: SiteLocale;
  creatorProfileId: Scalars["ItemId"];
}>;

export type ToursForCreatorProfileQuery = {
  __typename?: "Query";
  tours: Array<{
    __typename?: "TourRecord";
    id: any;
    title?: string | null;
    slug?: string | null;
    _status: ItemStatus;
    isDiscoverable?: any | null;
    audioDurationInMinutes?: any | null;
    durationInMinutes?: any | null;
    durationByBikeInMinutes?: any | null;
    durationByBoatInMinutes?: any | null;
    durationByBusInMinutes?: any | null;
    durationByCarInMinutes?: any | null;
    durationByTrainInMinutes?: any | null;
    distanceInKilometers?: any | null;
    regularTourPrice?: any | null;
    currentTourPrice?: any | null;
    routeGeoJson?: any | null;
    highlights?: any | null;
    tourStops: Array<{
      __typename?: "TourStopRecord";
      id: any;
      isPreviewTourStop?: any | null;
      isIntroTourStop?: any | null;
      isOutroTourStop?: any | null;
      location?: {
        __typename?: "LatLonField";
        latitude: any;
        longitude: any;
      } | null;
      stories: Array<{
        __typename?: "StoryRecord";
        audioDurationInSeconds?: any | null;
        storySlides: Array<{
          __typename?: "StorySlideRecord";
          audioDurationInSeconds?: any | null;
        }>;
      }>;
    }>;
    highlightImage?: {
      __typename?: "FileField";
      responsiveImage?: {
        __typename?: "ResponsiveImage";
        src: string;
        sizes: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt?: string | null;
        title?: string | null;
        bgColor?: string | null;
        base64?: string | null;
      } | null;
    } | null;
    categories: Array<{
      __typename?: "CategoryRecord";
      id: any;
      title?: string | null;
      color?: {
        __typename?: "ColorField";
        hex: string;
        red: any;
        green: any;
        blue: any;
      } | null;
      icon?: { __typename?: "FileField"; url: string } | null;
      colorIcon?: { __typename?: "FileField"; url: string } | null;
    }>;
    creatorProfile?: {
      __typename?: "CreatorProfileRecord";
      creatorName?: string | null;
      roundProfileImage?: {
        __typename?: "FileField";
        id: any;
        title?: string | null;
        copyright?: string | null;
        author?: string | null;
        responsiveImage?: {
          __typename?: "ResponsiveImage";
          src: string;
          sizes: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt?: string | null;
          title?: string | null;
          bgColor?: string | null;
          base64?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type ToursStopsByStoryIdsQueryVariables = Exact<{
  locale: SiteLocale;
  storyIds?: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type ToursStopsByStoryIdsQuery = {
  __typename?: "Query";
  tourStops: Array<{ __typename?: "TourStopRecord"; id: any }>;
};

export type VoicesQueryVariables = Exact<{
  locale: SiteLocale;
  first: Scalars["IntType"];
  skip: Scalars["IntType"];
}>;

export type VoicesQuery = {
  __typename?: "Query";
  voices: Array<{
    __typename?: "VoiceRecord";
    id: any;
    name?: string | null;
    description?: string | null;
    isDefault?: any | null;
    audio?: {
      __typename?: "FileField";
      id: any;
      basename: string;
      filename: string;
      format: string;
      mimeType: string;
      size: any;
      url: string;
    } | null;
  }>;
};

export const CityFieldsFragmentDoc = gql`
  fragment CityFields on CityRecord {
    id
    name
    title
    description
    location {
      latitude
      longitude
    }
  }
`;
export const ResponsiveImageFieldsFragmentDoc = gql`
  fragment ResponsiveImageFields on ResponsiveImage {
    src
    sizes
    width
    height
    aspectRatio
    alt
    title
    bgColor
    base64
  }
`;
export const RoundCityImageFragmentDoc = gql`
  fragment RoundCityImageFragment on FileField {
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: crop
        w: 100
        h: 100
        mask: "ellipse"
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const RectangularCityImageFragmentDoc = gql`
  fragment RectangularCityImageFragment on FileField {
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: clamp
        maxW: 576
        maxH: 600
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const CityToursAndStoriesCountFragmentDoc = gql`
  fragment CityToursAndStoriesCount on CityRecord {
    _allReferencingToursMeta(filter: { isDiscoverable: { eq: true } }) {
      count
    }
    _allReferencingStoriesMeta(filter: { isDiscoverable: { eq: true } }) {
      count
    }
  }
`;
export const CategoryFieldsFragmentDoc = gql`
  fragment CategoryFields on CategoryRecord {
    id
    title
    color {
      hex
      red
      green
      blue
    }
    icon {
      url
    }
    colorIcon {
      url
    }
  }
`;
export const BaseFileFieldsFragmentDoc = gql`
  fragment BaseFileFields on FileField {
    id
    title
    copyright
    author
  }
`;
export const SquareCreatorProfileImageFragmentDoc = gql`
  fragment SquareCreatorProfileImageFragment on FileField {
    ...BaseFileFields
    responsiveImage(
      imgixParams: { auto: [compress, format], fit: crop, w: 400, h: 400 }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const CreatorProfilePreviewFieldsFragmentDoc = gql`
  fragment CreatorProfilePreviewFields on CreatorProfileRecord {
    id
    creatorName
    username
    slogan
    _status
    isDiscoverable
    categories {
      ...CategoryFields
    }
    squareProfileImage: profileImage {
      ...SquareCreatorProfileImageFragment
    }
  }
`;
export const GenderFieldsFragmentDoc = gql`
  fragment GenderFields on GenderRecord {
    id
    title
  }
`;
export const RoundSightSeeingSpotImageFragmentDoc = gql`
  fragment RoundSightSeeingSpotImageFragment on FileField {
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: crop
        w: 50
        h: 50
        mask: "ellipse"
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const RectangularSightSeeingSpotImageFragmentDoc = gql`
  fragment RectangularSightSeeingSpotImageFragment on FileField {
    responsiveImage(
      imgixParams: { auto: [compress, format], fit: crop, w: 350, h: 250 }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const StorySlideAudioDurationFieldsFragmentDoc = gql`
  fragment StorySlideAudioDurationFields on StorySlideRecord {
    audioDurationInSeconds
  }
`;
export const StoryAudioDurationFieldsFragmentDoc = gql`
  fragment StoryAudioDurationFields on StoryRecord {
    audioDurationInSeconds
    storySlides {
      ...StorySlideAudioDurationFields
    }
  }
`;
export const LocationFieldsFragmentDoc = gql`
  fragment LocationFields on LatLonField {
    latitude
    longitude
  }
`;
export const AudioFileFieldsFragmentDoc = gql`
  fragment AudioFileFields on FileField {
    id
    basename
    filename
    format
    mimeType
    size
    url
  }
`;
export const ClampedResponsiveImageFileFieldsFragmentDoc = gql`
  fragment ClampedResponsiveImageFileFields on FileField {
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: clamp
        maxW: 1024
        maxH: 768
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const ImageRecordFieldsFragmentDoc = gql`
  fragment ImageRecordFields on ImageRecord {
    id
    caption
    image {
      ...BaseFileFields
      ...ClampedResponsiveImageFileFields
    }
  }
`;
export const CroppedResponsiveImageFileFieldsFragmentDoc = gql`
  fragment CroppedResponsiveImageFileFields on FileField {
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: crop
        maxW: 1024
        maxH: 768
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const ImageComparerFieldsFragmentDoc = gql`
  fragment ImageComparerFields on ImageComparerRecord {
    id
    caption
    imageBefore {
      ...BaseFileFields
      ...CroppedResponsiveImageFileFields
    }
    imageAfter {
      ...BaseFileFields
      ...CroppedResponsiveImageFileFields
    }
  }
`;
export const VideoRecordFieldsFragmentDoc = gql`
  fragment VideoRecordFields on VideoRecord {
    id
    order
    caption
    externalVideoUrl {
      provider
      providerUid
      thumbnailUrl
      title
      url
      height
      width
    }
    videoFile {
      ...BaseFileFields
      url
      height
      width
      mimeType
    }
  }
`;
export const MediaGalleryFieldsFragmentDoc = gql`
  fragment MediaGalleryFields on StorySlideModelMediaGalleryField {
    ... on ImageRecord {
      ...ImageRecordFields
    }
    ... on ImageComparerRecord {
      ...ImageComparerFields
    }
    ... on VideoRecord {
      ...VideoRecordFields
    }
  }
`;
export const PanoramaImageFieldsFragmentDoc = gql`
  fragment PanoramaImageFields on PanoramaImageRecord {
    panoramaImageFile {
      ...BaseFileFields
      responsiveImage(imgixParams: { auto: [compress, format] }) {
        ...ResponsiveImageFields
      }
    }
    defaultLongitude
    defaultLatitude
    defaultZoomLevel
    sphereCorrectionPan
    sphereCorrectionTilt
    sphereCorrectionRoll
    markers {
      id
      longitude
      latitude
      title
    }
  }
`;
export const MultipleChoiceQuestionFieldsFragmentDoc = gql`
  fragment MultipleChoiceQuestionFields on MultipleChoiceQuestionRecord {
    id
    backgroundImage {
      url
    }
    question
    options {
      id
      answer
      isCorrect
    }
  }
`;
export const TextInputFieldsFragmentDoc = gql`
  fragment TextInputFields on TextInputRecord {
    id
    question
    correctAnswer
    backgroundImage {
      url
    }
  }
`;
export const LocationTaskFieldsFragmentDoc = gql`
  fragment LocationTaskFields on LocationTaskRecord {
    id
    task
    location {
      latitude
      longitude
    }
    maximumDistanceToPoint
    backgroundImage {
      url
    }
  }
`;
export const QrCodeTaskFieldsFragmentDoc = gql`
  fragment QrCodeTaskFields on QrCodeTaskRecord {
    id
    task
    referenceId
  }
`;
export const QuizFieldsFragmentDoc = gql`
  fragment QuizFields on QuizRecord {
    id
    isBlocking
    isVisibleAtEndOfAudio
    quizTasks {
      ... on MultipleChoiceQuestionRecord {
        ...MultipleChoiceQuestionFields
      }
      ... on TextInputRecord {
        ...TextInputFields
      }
      ... on LocationTaskRecord {
        ...LocationTaskFields
      }
      ... on QrCodeTaskRecord {
        ...QrCodeTaskFields
      }
    }
  }
`;
export const StorySlideFieldsFragmentDoc = gql`
  fragment StorySlideFields on StorySlideRecord {
    id
    slideAudio {
      ...AudioFileFields
    }
    slideVoiceAudios {
      ... on VoiceAudioRecord {
        voice {
          id
        }
        audio {
          ...AudioFileFields
        }
      }
    }
    isSlideAudioFixed
    audioDurationInSeconds
    isNavigationSlide
    mediaGallery {
      ...MediaGalleryFields
    }
    panoramaImage {
      ...PanoramaImageFields
    }
    backgroundImage {
      url
    }
    slideActions {
      ... on SlideActionButtonRecord {
        label
        url
        variant
      }
    }
    speechMarks
    textTrack {
      url
    }
    subtitles
    quiz {
      ...QuizFields
    }
  }
`;
export const PlaceholderBlockRecordFieldsFragmentDoc = gql`
  fragment PlaceholderBlockRecordFields on PlaceholderBlockRecord {
    id
  }
`;
export const CreatorProfileAboutMeFragmentDoc = gql`
  fragment CreatorProfileAboutMeFragment on CreatorProfileModelAboutMeField {
    value
    blocks {
      __typename
      ... on ImageRecord {
        ...ImageRecordFields
      }
      ... on PlaceholderBlockRecord {
        ...PlaceholderBlockRecordFields
      }
    }
  }
`;
export const RoundCreatorProfileImageFragmentDoc = gql`
  fragment RoundCreatorProfileImageFragment on FileField {
    ...BaseFileFields
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: crop
        w: 400
        h: 400
        mask: "ellipse"
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const CreatorProfileFieldsFragmentDoc = gql`
  fragment CreatorProfileFields on CreatorProfileRecord {
    id
    creatorName
    username
    slogan
    _status
    isDiscoverable
    audioPreview {
      url
      mimeType
    }
    aboutMe {
      ...CreatorProfileAboutMeFragment
    }
    categories {
      ...CategoryFields
    }
    roundProfileImage: profileImage {
      ...RoundCreatorProfileImageFragment
    }
    squareProfileImage: profileImage {
      ...SquareCreatorProfileImageFragment
    }
  }
`;
export const StoryFieldsFragmentDoc = gql`
  fragment StoryFields on StoryRecord {
    id
    title
    isPremium
    placeName
    displayedPlaceName
    _status
    isDiscoverable
    audioDurationInSeconds
    location {
      ...LocationFields
    }
    externalStoryUrl
    storySlides {
      ...StorySlideFields
    }
    categories {
      ...CategoryFields
    }
    creatorProfile {
      ...CreatorProfileFields
    }
  }
`;
export const StoryWithParentsFieldsFragmentDoc = gql`
  fragment StoryWithParentsFields on StoryRecord {
    ...StoryFields
    _allReferencingTourStops {
      id
      _allReferencingTours {
        id
        currentTourPrice
        regularTourPrice
      }
    }
  }
`;
export const ExperienceLevelFieldsFragmentDoc = gql`
  fragment ExperienceLevelFields on ExperienceLevelRecord {
    id
    title
    color {
      hex
    }
    description
  }
`;
export const StoryPreviewFieldsFragmentDoc = gql`
  fragment StoryPreviewFields on StoryRecord {
    id
    title
    isPremium
    placeName
    displayedPlaceName
    location {
      ...LocationFields
    }
    creatorProfile {
      id
      creatorName
      roundProfileImage: profileImage {
        ...RoundCreatorProfileImageFragment
      }
    }
    categories {
      ...CategoryFields
    }
    experienceLevels {
      ...ExperienceLevelFields
    }
  }
`;
export const StoryWithParentsPreviewFieldsFragmentDoc = gql`
  fragment StoryWithParentsPreviewFields on StoryRecord {
    ...StoryPreviewFields
    _allReferencingTourStops {
      id
      _allReferencingTours {
        id
        currentTourPrice
        regularTourPrice
      }
    }
  }
`;
export const TourPreviewContentFieldsFragmentDoc = gql`
  fragment TourPreviewContentFields on TourModelTourPreviewContentField {
    value
    blocks {
      __typename
      ... on ImageRecord {
        ...ImageRecordFields
      }
      ... on PlaceholderBlockRecord {
        ...PlaceholderBlockRecordFields
      }
    }
  }
`;
export const TourImpressionFieldsFragmentDoc = gql`
  fragment TourImpressionFields on TourModelTourImpressionsField {
    __typename
    ... on ImageRecord {
      ...ImageRecordFields
    }
    ... on PlaceholderBlockRecord {
      ...PlaceholderBlockRecordFields
    }
  }
`;
export const TourFieldsFragmentDoc = gql`
  fragment TourFields on TourRecord {
    id
    title
    slug
    _status
    isDiscoverable
    durationInMinutes
    durationByBikeInMinutes
    durationByBoatInMinutes
    durationByBusInMinutes
    durationByCarInMinutes
    durationByTrainInMinutes
    audioDurationInMinutes
    distanceInKilometers
    regularTourPrice
    currentTourPrice
    routeGeoJson
    isTrackableForTourStops
    maximumDistanceToTourStop
    highlights
    highlightImage {
      ...ClampedResponsiveImageFileFields
    }
    categories {
      ...CategoryFields
    }
    creatorProfile {
      ...CreatorProfileFields
    }
    tourPreviewContent {
      ...TourPreviewContentFields
    }
    tourImpressions {
      ...TourImpressionFields
    }
    mediaGallery {
      image {
        id
        ...ClampedResponsiveImageFileFields
      }
    }
    videoGallery {
      ...VideoRecordFields
    }
  }
`;
export const TourPreviewFieldsFragmentDoc = gql`
  fragment TourPreviewFields on TourRecord {
    id
    title
    slug
    _status
    isDiscoverable
    audioDurationInMinutes
    durationInMinutes
    durationByBikeInMinutes
    durationByBoatInMinutes
    durationByBusInMinutes
    durationByCarInMinutes
    durationByTrainInMinutes
    distanceInKilometers
    regularTourPrice
    currentTourPrice
    routeGeoJson
    highlights
    highlightImage {
      ...ClampedResponsiveImageFileFields
    }
    categories {
      ...CategoryFields
    }
    creatorProfile {
      creatorName
      roundProfileImage: profileImage {
        ...RoundCreatorProfileImageFragment
      }
    }
  }
`;
export const TourStopFieldsFragmentDoc = gql`
  fragment TourStopFields on TourStopRecord {
    id
    title
    placeName
    displayedPlaceName
    isIntroTourStop
    isOutroTourStop
    isPreviewTourStop
    maximumDistanceToNotificationTrigger
    notificationTriggerLocation {
      ...LocationFields
    }
    location {
      ...LocationFields
    }
  }
`;
export const RectangularThemesImageFragmentDoc = gql`
  fragment RectangularThemesImageFragment on FileField {
    responsiveImage(
      imgixParams: {
        auto: [compress, format]
        fit: clamp
        maxW: 600
        maxH: 300
      }
    ) {
      ...ResponsiveImageFields
    }
  }
`;
export const AppContentCountDocument = gql`
  query AppContentCount {
    _allToursMeta(filter: { isDiscoverable: { eq: true } }) {
      count
    }
    _allStoriesMeta(filter: { isDiscoverable: { eq: true } }) {
      count
    }
  }
`;

export function useAppContentCountQuery(
  options?: Omit<Urql.UseQueryArgs<AppContentCountQueryVariables>, "query">
) {
  return Urql.useQuery<AppContentCountQuery, AppContentCountQueryVariables>({
    query: AppContentCountDocument,
    ...options,
  });
}
export const ApplicationDocument = gql`
  query Application($locale: SiteLocale!) {
    application(locale: $locale) {
      homePageTitle
      homePageText
      homePageImage {
        responsiveImage(
          imgixParams: {
            auto: [compress, format]
            fit: clamp
            maxW: 576
            maxH: 600
          }
        ) {
          ...ResponsiveImageFields
        }
      }
    }
  }
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useApplicationQuery(
  options: Omit<Urql.UseQueryArgs<ApplicationQueryVariables>, "query">
) {
  return Urql.useQuery<ApplicationQuery, ApplicationQueryVariables>({
    query: ApplicationDocument,
    ...options,
  });
}
export const CategoriesDocument = gql`
  query Categories(
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
    $isAvailableForDynamicTourCreation: BooleanType
  ) {
    categories: allCategories(
      locale: $locale
      filter: {
        isAvailableForDynamicTourCreation: {
          eq: $isAvailableForDynamicTourCreation
        }
      }
      first: $first
      skip: $skip
    ) {
      ...CategoryFields
    }
  }
  ${CategoryFieldsFragmentDoc}
`;

export function useCategoriesQuery(
  options: Omit<Urql.UseQueryArgs<CategoriesQueryVariables>, "query">
) {
  return Urql.useQuery<CategoriesQuery, CategoriesQueryVariables>({
    query: CategoriesDocument,
    ...options,
  });
}
export const CitiesWithStoriesCountDocument = gql`
  query CitiesWithStoriesCount(
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
  ) {
    cities: allCities(
      locale: $locale
      first: $first
      skip: $skip
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CityFields
      isImageVisibleOnMap
      preview {
        image {
          ...RoundCityImageFragment
        }
      }
      _allReferencingStoriesMeta(
        locale: $locale
        filter: { title: { isBlank: false }, isDiscoverable: { eq: true } }
      ) {
        count
      }
    }
  }
  ${CityFieldsFragmentDoc}
  ${RoundCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useCitiesWithStoriesCountQuery(
  options: Omit<
    Urql.UseQueryArgs<CitiesWithStoriesCountQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    CitiesWithStoriesCountQuery,
    CitiesWithStoriesCountQueryVariables
  >({ query: CitiesWithStoriesCountDocument, ...options });
}
export const CitiesWithToursAndStoriesCountDocument = gql`
  query CitiesWithToursAndStoriesCount(
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
  ) {
    cities: allCities(
      locale: $locale
      first: $first
      skip: $skip
      orderBy: [position_ASC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CityFields
      preview {
        image {
          ...RectangularCityImageFragment
        }
      }
      ...CityToursAndStoriesCount
    }
  }
  ${CityFieldsFragmentDoc}
  ${RectangularCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CityToursAndStoriesCountFragmentDoc}
`;

export function useCitiesWithToursAndStoriesCountQuery(
  options: Omit<
    Urql.UseQueryArgs<CitiesWithToursAndStoriesCountQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    CitiesWithToursAndStoriesCountQuery,
    CitiesWithToursAndStoriesCountQueryVariables
  >({ query: CitiesWithToursAndStoriesCountDocument, ...options });
}
export const CitiesWithToursAndStoriesCountByCoordinatesDocument = gql`
  query CitiesWithToursAndStoriesCountByCoordinates(
    $locale: SiteLocale!
    $latitude: FloatType!
    $longitude: FloatType!
    $radius: FloatType!
  ) {
    cities: allCities(
      locale: $locale
      filter: {
        location: {
          near: { latitude: $latitude, longitude: $longitude, radius: $radius }
        }
      }
      first: 100
      skip: 0
      orderBy: [position_ASC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CityFields
      preview {
        image {
          ...RectangularCityImageFragment
        }
      }
      ...CityToursAndStoriesCount
    }
  }
  ${CityFieldsFragmentDoc}
  ${RectangularCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CityToursAndStoriesCountFragmentDoc}
`;

export function useCitiesWithToursAndStoriesCountByCoordinatesQuery(
  options: Omit<
    Urql.UseQueryArgs<CitiesWithToursAndStoriesCountByCoordinatesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    CitiesWithToursAndStoriesCountByCoordinatesQuery,
    CitiesWithToursAndStoriesCountByCoordinatesQueryVariables
  >({ query: CitiesWithToursAndStoriesCountByCoordinatesDocument, ...options });
}
export const CitiesWithToursByCoordinatesDocument = gql`
  query CitiesWithToursByCoordinates(
    $locale: SiteLocale!
    $latitude: FloatType!
    $longitude: FloatType!
    $radius: FloatType!
    $first: IntType!
    $skip: IntType!
  ) {
    cities: allCities(
      locale: $locale
      filter: {
        location: {
          near: { latitude: $latitude, longitude: $longitude, radius: $radius }
        }
      }
      first: $first
      skip: $skip
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      id
      name
      location {
        latitude
        longitude
      }
      _allReferencingTours(
        locale: $locale
        filter: { title: { isBlank: false }, isDiscoverable: { eq: true } }
        first: 100
        skip: 0
      ) {
        ...TourPreviewFields
        tourStops {
          id
          isPreviewTourStop
          isIntroTourStop
          isOutroTourStop
          location {
            latitude
            longitude
          }
          stories {
            ...StoryAudioDurationFields
          }
        }
        _allReferencingTourTranslations {
          _allTourLocales {
            locale
            value {
              id
            }
          }
        }
      }
    }
  }
  ${TourPreviewFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${StoryAudioDurationFieldsFragmentDoc}
  ${StorySlideAudioDurationFieldsFragmentDoc}
`;

export function useCitiesWithToursByCoordinatesQuery(
  options: Omit<
    Urql.UseQueryArgs<CitiesWithToursByCoordinatesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    CitiesWithToursByCoordinatesQuery,
    CitiesWithToursByCoordinatesQueryVariables
  >({ query: CitiesWithToursByCoordinatesDocument, ...options });
}
export const CitiesWithToursNumberForLocalesDocument = gql`
  query CitiesWithToursNumberForLocales(
    $latitude: FloatType!
    $longitude: FloatType!
    $radius: FloatType!
    $first: IntType!
    $skip: IntType!
  ) {
    cities: allCities(
      filter: {
        location: {
          near: { latitude: $latitude, longitude: $longitude, radius: $radius }
        }
      }
      first: $first
      skip: $skip
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      de: _allReferencingToursMeta(
        locale: de
        filter: { title: { isBlank: false }, isDiscoverable: { eq: true } }
      ) {
        count
      }
      en: _allReferencingToursMeta(
        locale: en
        filter: { title: { isBlank: false }, isDiscoverable: { eq: true } }
      ) {
        count
      }
      es: _allReferencingToursMeta(
        locale: es
        filter: { title: { isBlank: false }, isDiscoverable: { eq: true } }
      ) {
        count
      }
      fr: _allReferencingToursMeta(
        locale: fr
        filter: { title: { isBlank: false }, isDiscoverable: { eq: true } }
      ) {
        count
      }
    }
  }
`;

export function useCitiesWithToursNumberForLocalesQuery(
  options: Omit<
    Urql.UseQueryArgs<CitiesWithToursNumberForLocalesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    CitiesWithToursNumberForLocalesQuery,
    CitiesWithToursNumberForLocalesQueryVariables
  >({ query: CitiesWithToursNumberForLocalesDocument, ...options });
}
export const CityByCityIdDocument = gql`
  query CityByCityId($locale: SiteLocale!, $cityId: ItemId!) {
    city(
      locale: $locale
      filter: { id: { eq: $cityId } }
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CityFields
      preview {
        image {
          ...RectangularCityImageFragment
        }
      }
    }
  }
  ${CityFieldsFragmentDoc}
  ${RectangularCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useCityByCityIdQuery(
  options: Omit<Urql.UseQueryArgs<CityByCityIdQueryVariables>, "query">
) {
  return Urql.useQuery<CityByCityIdQuery, CityByCityIdQueryVariables>({
    query: CityByCityIdDocument,
    ...options,
  });
}
export const CityByCoordinatesDocument = gql`
  query CityByCoordinates(
    $locale: SiteLocale!
    $latitude: FloatType!
    $longitude: FloatType!
    $radius: FloatType!
  ) {
    city(
      locale: $locale
      filter: {
        location: {
          near: { latitude: $latitude, longitude: $longitude, radius: $radius }
        }
      }
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CityFields
      preview {
        image {
          ...RectangularCityImageFragment
        }
      }
    }
  }
  ${CityFieldsFragmentDoc}
  ${RectangularCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useCityByCoordinatesQuery(
  options: Omit<Urql.UseQueryArgs<CityByCoordinatesQueryVariables>, "query">
) {
  return Urql.useQuery<CityByCoordinatesQuery, CityByCoordinatesQueryVariables>(
    { query: CityByCoordinatesDocument, ...options }
  );
}
export const CreatorProfileDocument = gql`
  query CreatorProfile($locale: SiteLocale!, $creatorProfileUsername: String!) {
    creatorProfile(
      locale: $locale
      filter: { username: { eq: $creatorProfileUsername } }
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CreatorProfileFields
    }
  }
  ${CreatorProfileFieldsFragmentDoc}
  ${CreatorProfileAboutMeFragmentDoc}
  ${ImageRecordFieldsFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${PlaceholderBlockRecordFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${SquareCreatorProfileImageFragmentDoc}
`;

export function useCreatorProfileQuery(
  options: Omit<Urql.UseQueryArgs<CreatorProfileQueryVariables>, "query">
) {
  return Urql.useQuery<CreatorProfileQuery, CreatorProfileQueryVariables>({
    query: CreatorProfileDocument,
    ...options,
  });
}
export const DefaultCityImageDocument = gql`
  query DefaultCityImage {
    application {
      defaultCityImage {
        ...RectangularCityImageFragment
      }
    }
  }
  ${RectangularCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useDefaultCityImageQuery(
  options?: Omit<Urql.UseQueryArgs<DefaultCityImageQueryVariables>, "query">
) {
  return Urql.useQuery<DefaultCityImageQuery, DefaultCityImageQueryVariables>({
    query: DefaultCityImageDocument,
    ...options,
  });
}
export const ExperienceLevelsDocument = gql`
  query ExperienceLevels($locale: SiteLocale!) {
    experienceLevels: allExperienceLevels(
      locale: $locale
      first: 100
      skip: 0
    ) {
      ...ExperienceLevelFields
    }
  }
  ${ExperienceLevelFieldsFragmentDoc}
`;

export function useExperienceLevelsQuery(
  options: Omit<Urql.UseQueryArgs<ExperienceLevelsQueryVariables>, "query">
) {
  return Urql.useQuery<ExperienceLevelsQuery, ExperienceLevelsQueryVariables>({
    query: ExperienceLevelsDocument,
    ...options,
  });
}
export const ExploreCreatorProfilesDocument = gql`
  query ExploreCreatorProfiles($locale: SiteLocale!) {
    creatorProfiles: allCreatorProfiles(
      locale: $locale
      filter: { isDiscoverable: { eq: true } }
      orderBy: [position_ASC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...CreatorProfilePreviewFields
    }
  }
  ${CreatorProfilePreviewFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${SquareCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useExploreCreatorProfilesQuery(
  options: Omit<
    Urql.UseQueryArgs<ExploreCreatorProfilesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ExploreCreatorProfilesQuery,
    ExploreCreatorProfilesQueryVariables
  >({ query: ExploreCreatorProfilesDocument, ...options });
}
export const ExploreSightseeingSpotsDocument = gql`
  query ExploreSightseeingSpots(
    $locale: SiteLocale!
    $filter: SightseeingSpotModelFilter!
    $first: IntType!
    $skip: IntType!
  ) {
    sightseeingSpots: allSightseeingSpots(
      locale: $locale
      filter: $filter
      first: $first
      skip: $skip
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      id
      name
      description
      isVisibleOnMap
      preview {
        roundPreviewImage: image {
          ...RoundSightSeeingSpotImageFragment
        }
        rectangularPreviewImage: image {
          ...RectangularSightSeeingSpotImageFragment
        }
      }
      mediaGallery {
        rectangularMediaGalleryImage: image {
          ...RectangularSightSeeingSpotImageFragment
        }
      }
      location {
        latitude
        longitude
      }
    }
  }
  ${RoundSightSeeingSpotImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${RectangularSightSeeingSpotImageFragmentDoc}
`;

export function useExploreSightseeingSpotsQuery(
  options: Omit<
    Urql.UseQueryArgs<ExploreSightseeingSpotsQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ExploreSightseeingSpotsQuery,
    ExploreSightseeingSpotsQueryVariables
  >({ query: ExploreSightseeingSpotsDocument, ...options });
}
export const ExploreSightseeingSpotsByCoordinatesDocument = gql`
  query ExploreSightseeingSpotsByCoordinates(
    $locale: SiteLocale!
    $latitude: FloatType!
    $longitude: FloatType!
    $radius: FloatType!
    $first: IntType!
    $skip: IntType!
  ) {
    sightseeingSpots: allSightseeingSpots(
      locale: $locale
      filter: {
        isVisibleOnMap: { eq: true }
        location: {
          near: { latitude: $latitude, longitude: $longitude, radius: $radius }
        }
      }
      first: $first
      skip: $skip
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      id
      name
      description
      isVisibleOnMap
      preview {
        roundPreviewImage: image {
          ...RoundSightSeeingSpotImageFragment
        }
        rectangularPreviewImage: image {
          ...RectangularSightSeeingSpotImageFragment
        }
      }
      mediaGallery {
        rectangularMediaGalleryImage: image {
          ...RectangularSightSeeingSpotImageFragment
        }
      }
      location {
        latitude
        longitude
      }
    }
  }
  ${RoundSightSeeingSpotImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${RectangularSightSeeingSpotImageFragmentDoc}
`;

export function useExploreSightseeingSpotsByCoordinatesQuery(
  options: Omit<
    Urql.UseQueryArgs<ExploreSightseeingSpotsByCoordinatesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ExploreSightseeingSpotsByCoordinatesQuery,
    ExploreSightseeingSpotsByCoordinatesQueryVariables
  >({ query: ExploreSightseeingSpotsByCoordinatesDocument, ...options });
}
export const ExploreStoriesDocument = gql`
  query ExploreStories(
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
    $cityId: ItemId!
    $categoryIds: [ItemId!]
    $experienceLevelIds: [ItemId!]
    $excludeStoryIds: [ItemId!]
  ) {
    stories: allStories(
      locale: $locale
      filter: {
        title: { isBlank: false }
        isDiscoverable: { eq: true }
        city: { eq: $cityId }
        AND: [
          { categories: { anyIn: $categoryIds } }
          { experienceLevels: { anyIn: $experienceLevelIds } }
          { id: { notIn: $excludeStoryIds } }
        ]
      }
      first: $first
      skip: $skip
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...StoryWithParentsPreviewFields
    }
  }
  ${StoryWithParentsPreviewFieldsFragmentDoc}
  ${StoryPreviewFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${ExperienceLevelFieldsFragmentDoc}
`;

export function useExploreStoriesQuery(
  options: Omit<Urql.UseQueryArgs<ExploreStoriesQueryVariables>, "query">
) {
  return Urql.useQuery<ExploreStoriesQuery, ExploreStoriesQueryVariables>({
    query: ExploreStoriesDocument,
    ...options,
  });
}
export const ExploreStoriesByCoordinatesDocument = gql`
  query ExploreStoriesByCoordinates(
    $locale: SiteLocale!
    $latitude: FloatType!
    $longitude: FloatType!
    $radius: FloatType!
    $first: IntType!
    $skip: IntType!
  ) {
    stories: allStories(
      locale: $locale
      filter: {
        title: { isBlank: false }
        isDiscoverable: { eq: true }
        location: {
          near: { latitude: $latitude, longitude: $longitude, radius: $radius }
        }
      }
      first: $first
      skip: $skip
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...StoryWithParentsPreviewFields
    }
  }
  ${StoryWithParentsPreviewFieldsFragmentDoc}
  ${StoryPreviewFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${ExperienceLevelFieldsFragmentDoc}
`;

export function useExploreStoriesByCoordinatesQuery(
  options: Omit<
    Urql.UseQueryArgs<ExploreStoriesByCoordinatesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ExploreStoriesByCoordinatesQuery,
    ExploreStoriesByCoordinatesQueryVariables
  >({ query: ExploreStoriesByCoordinatesDocument, ...options });
}
export const GendersDocument = gql`
  query Genders($locale: SiteLocale!) {
    genders: allGenders(locale: $locale) {
      ...GenderFields
    }
  }
  ${GenderFieldsFragmentDoc}
`;

export function useGendersQuery(
  options: Omit<Urql.UseQueryArgs<GendersQueryVariables>, "query">
) {
  return Urql.useQuery<GendersQuery, GendersQueryVariables>({
    query: GendersDocument,
    ...options,
  });
}
export const LastAppVersionFeaturesDocument = gql`
  query LastAppVersionFeatures(
    $locale: SiteLocale!
    $filter: AppVersionFeatureModelFilter!
  ) {
    allAppVersionFeatures(
      locale: $locale
      filter: $filter
      first: 1
      orderBy: _createdAt_DESC
      fallbackLocales: [en]
    ) {
      id
      image {
        responsiveImage(
          imgixParams: {
            auto: [compress, format]
            fit: clamp
            maxW: 768
            maxH: 600
          }
        ) {
          ...ResponsiveImageFields
        }
      }
      title
      description
      appVersion
      buttons {
        ... on LinkButtonRecord {
          id
          text
          pathWithoutLocale
          fill
        }
        ... on PlaceholderBlockRecord {
          ...PlaceholderBlockRecordFields
        }
      }
    }
  }
  ${ResponsiveImageFieldsFragmentDoc}
  ${PlaceholderBlockRecordFieldsFragmentDoc}
`;

export function useLastAppVersionFeaturesQuery(
  options: Omit<
    Urql.UseQueryArgs<LastAppVersionFeaturesQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    LastAppVersionFeaturesQuery,
    LastAppVersionFeaturesQueryVariables
  >({ query: LastAppVersionFeaturesDocument, ...options });
}
export const PopularCitiesDocument = gql`
  query PopularCities($locale: SiteLocale!, $first: IntType!, $skip: IntType!) {
    cities: allCities(
      locale: $locale
      filter: { isPopular: { eq: true } }
      first: $first
      skip: $skip
    ) {
      ...CityFields
      preview {
        image {
          ...RectangularCityImageFragment
        }
      }
    }
  }
  ${CityFieldsFragmentDoc}
  ${RectangularCityImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function usePopularCitiesQuery(
  options: Omit<Urql.UseQueryArgs<PopularCitiesQueryVariables>, "query">
) {
  return Urql.useQuery<PopularCitiesQuery, PopularCitiesQueryVariables>({
    query: PopularCitiesDocument,
    ...options,
  });
}
export const PopularSightseeingSpotsByCityIdDocument = gql`
  query PopularSightseeingSpotsByCityId(
    $cityId: ItemId!
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
  ) {
    sightseeingSpots: allSightseeingSpots(
      locale: $locale
      filter: { city: { eq: $cityId }, isPopular: { eq: true } }
      first: $first
      skip: $skip
    ) {
      id
      name
      location {
        latitude
        longitude
      }
      preview {
        image {
          ...ClampedResponsiveImageFileFields
        }
      }
    }
  }
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function usePopularSightseeingSpotsByCityIdQuery(
  options: Omit<
    Urql.UseQueryArgs<PopularSightseeingSpotsByCityIdQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    PopularSightseeingSpotsByCityIdQuery,
    PopularSightseeingSpotsByCityIdQueryVariables
  >({ query: PopularSightseeingSpotsByCityIdDocument, ...options });
}
export const PopularToursDocument = gql`
  query PopularTours($locale: SiteLocale!, $filter: TourModelFilter!) {
    tours: allTours(
      locale: $locale
      filter: $filter
      first: 100
      skip: 0
      orderBy: [position_ASC]
    ) {
      ...TourPreviewFields
      tourStops {
        id
        isPreviewTourStop
        isIntroTourStop
        isOutroTourStop
        location {
          latitude
          longitude
        }
        stories {
          ...StoryAudioDurationFields
        }
      }
    }
  }
  ${TourPreviewFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${StoryAudioDurationFieldsFragmentDoc}
  ${StorySlideAudioDurationFieldsFragmentDoc}
`;

export function usePopularToursQuery(
  options: Omit<Urql.UseQueryArgs<PopularToursQueryVariables>, "query">
) {
  return Urql.useQuery<PopularToursQuery, PopularToursQueryVariables>({
    query: PopularToursDocument,
    ...options,
  });
}
export const SightseeingSpotByIdDocument = gql`
  query SightseeingSpotById($sightseeingSpotId: ItemId!, $locale: SiteLocale!) {
    sightseeingSpot(
      locale: $locale
      filter: { id: { eq: $sightseeingSpotId } }
      fallbackLocales: [de, en, fr, es]
    ) {
      id
      name
      description
      mediaGallery {
        image {
          id
          ...ClampedResponsiveImageFileFields
        }
      }
      location {
        latitude
        longitude
      }
    }
  }
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useSightseeingSpotByIdQuery(
  options: Omit<Urql.UseQueryArgs<SightseeingSpotByIdQueryVariables>, "query">
) {
  return Urql.useQuery<
    SightseeingSpotByIdQuery,
    SightseeingSpotByIdQueryVariables
  >({ query: SightseeingSpotByIdDocument, ...options });
}
export const StoriesByStoryIdsDocument = gql`
  query StoriesByStoryIds(
    $storyIds: [ItemId!]
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
  ) {
    stories: allStories(
      locale: $locale
      filter: { title: { isBlank: false }, id: { in: $storyIds } }
      first: $first
      skip: $skip
      orderBy: [_createdAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...StoryWithParentsPreviewFields
    }
  }
  ${StoryWithParentsPreviewFieldsFragmentDoc}
  ${StoryPreviewFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${ExperienceLevelFieldsFragmentDoc}
`;

export function useStoriesByStoryIdsQuery(
  options: Omit<Urql.UseQueryArgs<StoriesByStoryIdsQueryVariables>, "query">
) {
  return Urql.useQuery<StoriesByStoryIdsQuery, StoriesByStoryIdsQueryVariables>(
    { query: StoriesByStoryIdsDocument, ...options }
  );
}
export const StoriesForCreatorProfileDocument = gql`
  query StoriesForCreatorProfile(
    $locale: SiteLocale!
    $creatorProfileId: ItemId!
  ) {
    stories: allStories(
      locale: $locale
      filter: {
        isDiscoverable: { eq: true }
        creatorProfile: { eq: $creatorProfileId }
      }
      orderBy: [_updatedAt_DESC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...StoryWithParentsPreviewFields
    }
  }
  ${StoryWithParentsPreviewFieldsFragmentDoc}
  ${StoryPreviewFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${ExperienceLevelFieldsFragmentDoc}
`;

export function useStoriesForCreatorProfileQuery(
  options: Omit<
    Urql.UseQueryArgs<StoriesForCreatorProfileQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    StoriesForCreatorProfileQuery,
    StoriesForCreatorProfileQueryVariables
  >({ query: StoriesForCreatorProfileDocument, ...options });
}
export const StoryByStoryIdDocument = gql`
  query StoryByStoryId($storyId: ItemId!, $locale: SiteLocale!) {
    story(
      locale: $locale
      filter: { id: { eq: $storyId } }
      fallbackLocales: [de, en, fr, es]
    ) {
      ...StoryWithParentsFields
    }
  }
  ${StoryWithParentsFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${StorySlideFieldsFragmentDoc}
  ${AudioFileFieldsFragmentDoc}
  ${MediaGalleryFieldsFragmentDoc}
  ${ImageRecordFieldsFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${ImageComparerFieldsFragmentDoc}
  ${CroppedResponsiveImageFileFieldsFragmentDoc}
  ${VideoRecordFieldsFragmentDoc}
  ${PanoramaImageFieldsFragmentDoc}
  ${QuizFieldsFragmentDoc}
  ${MultipleChoiceQuestionFieldsFragmentDoc}
  ${TextInputFieldsFragmentDoc}
  ${LocationTaskFieldsFragmentDoc}
  ${QrCodeTaskFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${CreatorProfileFieldsFragmentDoc}
  ${CreatorProfileAboutMeFragmentDoc}
  ${PlaceholderBlockRecordFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${SquareCreatorProfileImageFragmentDoc}
`;

export function useStoryByStoryIdQuery(
  options: Omit<Urql.UseQueryArgs<StoryByStoryIdQueryVariables>, "query">
) {
  return Urql.useQuery<StoryByStoryIdQuery, StoryByStoryIdQueryVariables>({
    query: StoryByStoryIdDocument,
    ...options,
  });
}
export const StoryTranslationByIdDocument = gql`
  query StoryTranslationById($storyTranslationId: ItemId!) {
    storyTranslation(filter: { id: { eq: $storyTranslationId } }) {
      _allStoryLocales {
        locale
        value {
          id
        }
      }
    }
  }
`;

export function useStoryTranslationByIdQuery(
  options: Omit<Urql.UseQueryArgs<StoryTranslationByIdQueryVariables>, "query">
) {
  return Urql.useQuery<
    StoryTranslationByIdQuery,
    StoryTranslationByIdQueryVariables
  >({ query: StoryTranslationByIdDocument, ...options });
}
export const ThemesDocument = gql`
  query Themes($locale: SiteLocale!, $first: IntType!, $skip: IntType!) {
    themes: allThemes(
      locale: $locale
      first: $first
      skip: $skip
      orderBy: [position_ASC]
    ) {
      id
      title
      description
      image {
        ...RectangularThemesImageFragment
      }
    }
  }
  ${RectangularThemesImageFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useThemesQuery(
  options: Omit<Urql.UseQueryArgs<ThemesQueryVariables>, "query">
) {
  return Urql.useQuery<ThemesQuery, ThemesQueryVariables>({
    query: ThemesDocument,
    ...options,
  });
}
export const TourByTourIdDocument = gql`
  query TourByTourId($tourId: ItemId!, $locale: SiteLocale!) {
    tour(
      locale: $locale
      filter: { id: { eq: $tourId } }
      fallbackLocales: [de, en, fr, es, it]
    ) {
      ...TourFields
      tourStops {
        ...TourStopFields
        stories {
          ...StoryFields
        }
      }
    }
  }
  ${TourFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${CreatorProfileFieldsFragmentDoc}
  ${CreatorProfileAboutMeFragmentDoc}
  ${ImageRecordFieldsFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${PlaceholderBlockRecordFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${SquareCreatorProfileImageFragmentDoc}
  ${TourPreviewContentFieldsFragmentDoc}
  ${TourImpressionFieldsFragmentDoc}
  ${VideoRecordFieldsFragmentDoc}
  ${TourStopFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${StorySlideFieldsFragmentDoc}
  ${AudioFileFieldsFragmentDoc}
  ${MediaGalleryFieldsFragmentDoc}
  ${ImageComparerFieldsFragmentDoc}
  ${CroppedResponsiveImageFileFieldsFragmentDoc}
  ${PanoramaImageFieldsFragmentDoc}
  ${QuizFieldsFragmentDoc}
  ${MultipleChoiceQuestionFieldsFragmentDoc}
  ${TextInputFieldsFragmentDoc}
  ${LocationTaskFieldsFragmentDoc}
  ${QrCodeTaskFieldsFragmentDoc}
`;

export function useTourByTourIdQuery(
  options: Omit<Urql.UseQueryArgs<TourByTourIdQueryVariables>, "query">
) {
  return Urql.useQuery<TourByTourIdQuery, TourByTourIdQueryVariables>({
    query: TourByTourIdDocument,
    ...options,
  });
}
export const TourByTourSlugDocument = gql`
  query TourByTourSlug($tourSlug: String!, $locale: SiteLocale!) {
    tour(
      locale: $locale
      filter: { slug: { eq: $tourSlug } }
      fallbackLocales: [de, en, fr, es, it]
    ) {
      ...TourFields
      tourStops {
        ...TourStopFields
        stories {
          ...StoryFields
        }
      }
    }
  }
  ${TourFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${CreatorProfileFieldsFragmentDoc}
  ${CreatorProfileAboutMeFragmentDoc}
  ${ImageRecordFieldsFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${PlaceholderBlockRecordFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${SquareCreatorProfileImageFragmentDoc}
  ${TourPreviewContentFieldsFragmentDoc}
  ${TourImpressionFieldsFragmentDoc}
  ${VideoRecordFieldsFragmentDoc}
  ${TourStopFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${StorySlideFieldsFragmentDoc}
  ${AudioFileFieldsFragmentDoc}
  ${MediaGalleryFieldsFragmentDoc}
  ${ImageComparerFieldsFragmentDoc}
  ${CroppedResponsiveImageFileFieldsFragmentDoc}
  ${PanoramaImageFieldsFragmentDoc}
  ${QuizFieldsFragmentDoc}
  ${MultipleChoiceQuestionFieldsFragmentDoc}
  ${TextInputFieldsFragmentDoc}
  ${LocationTaskFieldsFragmentDoc}
  ${QrCodeTaskFieldsFragmentDoc}
`;

export function useTourByTourSlugQuery(
  options: Omit<Urql.UseQueryArgs<TourByTourSlugQueryVariables>, "query">
) {
  return Urql.useQuery<TourByTourSlugQuery, TourByTourSlugQueryVariables>({
    query: TourByTourSlugDocument,
    ...options,
  });
}
export const TourCollectionByIdDocument = gql`
  query TourCollectionById($tourCollectionId: ItemId!, $locale: SiteLocale!) {
    tourCollection(
      locale: $locale
      filter: { id: { eq: $tourCollectionId } }
      fallbackLocales: [de, en, fr, es]
    ) {
      id
      title
      longDescription
      mediaGallery {
        image {
          ...ClampedResponsiveImageFileFields
        }
      }
    }
  }
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useTourCollectionByIdQuery(
  options: Omit<Urql.UseQueryArgs<TourCollectionByIdQueryVariables>, "query">
) {
  return Urql.useQuery<
    TourCollectionByIdQuery,
    TourCollectionByIdQueryVariables
  >({ query: TourCollectionByIdDocument, ...options });
}
export const TourCollectionsByCityIdDocument = gql`
  query TourCollectionsByCityId($cityId: ItemId!, $locale: SiteLocale!) {
    tourCollections: allTourCollections(
      locale: $locale
      filter: { cities: { anyIn: [$cityId] } }
      first: 100
      skip: 0
    ) {
      id
      name
      shortDescription
      preview {
        image {
          ...ClampedResponsiveImageFileFields
        }
      }
    }
  }
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
`;

export function useTourCollectionsByCityIdQuery(
  options: Omit<
    Urql.UseQueryArgs<TourCollectionsByCityIdQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    TourCollectionsByCityIdQuery,
    TourCollectionsByCityIdQueryVariables
  >({ query: TourCollectionsByCityIdDocument, ...options });
}
export const TourStopByTourStopIdDocument = gql`
  query TourStopByTourStopId($tourStopId: ItemId!, $locale: SiteLocale!) {
    tourStop(
      locale: $locale
      filter: { id: { eq: $tourStopId } }
      fallbackLocales: [de, en, fr, es]
    ) {
      ...TourStopFields
      stories {
        ...StoryFields
      }
    }
  }
  ${TourStopFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${StorySlideFieldsFragmentDoc}
  ${AudioFileFieldsFragmentDoc}
  ${MediaGalleryFieldsFragmentDoc}
  ${ImageRecordFieldsFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${ImageComparerFieldsFragmentDoc}
  ${CroppedResponsiveImageFileFieldsFragmentDoc}
  ${VideoRecordFieldsFragmentDoc}
  ${PanoramaImageFieldsFragmentDoc}
  ${QuizFieldsFragmentDoc}
  ${MultipleChoiceQuestionFieldsFragmentDoc}
  ${TextInputFieldsFragmentDoc}
  ${LocationTaskFieldsFragmentDoc}
  ${QrCodeTaskFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${CreatorProfileFieldsFragmentDoc}
  ${CreatorProfileAboutMeFragmentDoc}
  ${PlaceholderBlockRecordFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${SquareCreatorProfileImageFragmentDoc}
`;

export function useTourStopByTourStopIdQuery(
  options: Omit<Urql.UseQueryArgs<TourStopByTourStopIdQueryVariables>, "query">
) {
  return Urql.useQuery<
    TourStopByTourStopIdQuery,
    TourStopByTourStopIdQueryVariables
  >({ query: TourStopByTourStopIdDocument, ...options });
}
export const TourStopTranslationByIdDocument = gql`
  query TourStopTranslationById($tourStopTranslationId: ItemId!) {
    tourStopTranslation(filter: { id: { eq: $tourStopTranslationId } }) {
      _allTourStopLocales {
        locale
        value {
          id
          stories {
            id
          }
        }
      }
      _allTourLocales {
        locale
        value {
          id
          tourStops {
            id
          }
        }
      }
    }
  }
`;

export function useTourStopTranslationByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<TourStopTranslationByIdQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    TourStopTranslationByIdQuery,
    TourStopTranslationByIdQueryVariables
  >({ query: TourStopTranslationByIdDocument, ...options });
}
export const TourTranslationByTourIdDocument = gql`
  query TourTranslationByTourId($tourId: ItemId!) {
    en: tourTranslation(locale: en, filter: { tour: { eq: $tourId } }) {
      _allTourLocales {
        locale
        value {
          id
          slug
        }
      }
    }
    de: tourTranslation(locale: de, filter: { tour: { eq: $tourId } }) {
      _allTourLocales {
        locale
        value {
          id
          slug
        }
      }
    }
    fr: tourTranslation(locale: fr, filter: { tour: { eq: $tourId } }) {
      _allTourLocales {
        locale
        value {
          id
          slug
        }
      }
    }
    it: tourTranslation(locale: it, filter: { tour: { eq: $tourId } }) {
      _allTourLocales {
        locale
        value {
          id
          slug
        }
      }
    }
  }
`;

export function useTourTranslationByTourIdQuery(
  options: Omit<
    Urql.UseQueryArgs<TourTranslationByTourIdQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    TourTranslationByTourIdQuery,
    TourTranslationByTourIdQueryVariables
  >({ query: TourTranslationByTourIdDocument, ...options });
}
export const ToursAndStoriesCountByCityIdDocument = gql`
  query ToursAndStoriesCountByCityId($cityId: ItemId!) {
    city(filter: { id: { eq: $cityId } }, fallbackLocales: [de, en, fr, es]) {
      ...CityToursAndStoriesCount
    }
  }
  ${CityToursAndStoriesCountFragmentDoc}
`;

export function useToursAndStoriesCountByCityIdQuery(
  options: Omit<
    Urql.UseQueryArgs<ToursAndStoriesCountByCityIdQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ToursAndStoriesCountByCityIdQuery,
    ToursAndStoriesCountByCityIdQueryVariables
  >({ query: ToursAndStoriesCountByCityIdDocument, ...options });
}
export const ToursByTourCollectionIdDocument = gql`
  query ToursByTourCollectionId(
    $tourCollectionId: ItemId!
    $locale: SiteLocale!
  ) {
    tours: allTours(
      locale: $locale
      filter: {
        title: { isBlank: false }
        isDiscoverable: { eq: true }
        tourCollections: { anyIn: [$tourCollectionId] }
      }
      first: 100
      skip: 0
      orderBy: [position_ASC]
    ) {
      ...TourPreviewFields
      tourStops {
        id
        isPreviewTourStop
        isIntroTourStop
        isOutroTourStop
        location {
          latitude
          longitude
        }
        stories {
          ...StoryAudioDurationFields
        }
      }
    }
  }
  ${TourPreviewFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${StoryAudioDurationFieldsFragmentDoc}
  ${StorySlideAudioDurationFieldsFragmentDoc}
`;

export function useToursByTourCollectionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<ToursByTourCollectionIdQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ToursByTourCollectionIdQuery,
    ToursByTourCollectionIdQueryVariables
  >({ query: ToursByTourCollectionIdDocument, ...options });
}
export const ToursByTourIdsDocument = gql`
  query ToursByTourIds(
    $tourIds: [ItemId!]
    $locale: SiteLocale!
    $first: IntType!
    $skip: IntType!
  ) {
    tours: allTours(
      locale: $locale
      filter: { title: { isBlank: false }, id: { in: $tourIds } }
      first: $first
      skip: $skip
      orderBy: [_createdAt_DESC]
      fallbackLocales: [de, en]
    ) {
      ...TourPreviewFields
      tourStops {
        id
        isPreviewTourStop
        isIntroTourStop
        isOutroTourStop
        location {
          latitude
          longitude
        }
        stories {
          ...StoryAudioDurationFields
        }
      }
    }
  }
  ${TourPreviewFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${StoryAudioDurationFieldsFragmentDoc}
  ${StorySlideAudioDurationFieldsFragmentDoc}
`;

export function useToursByTourIdsQuery(
  options: Omit<Urql.UseQueryArgs<ToursByTourIdsQueryVariables>, "query">
) {
  return Urql.useQuery<ToursByTourIdsQuery, ToursByTourIdsQueryVariables>({
    query: ToursByTourIdsDocument,
    ...options,
  });
}
export const ToursByTourStopIdsDocument = gql`
  query ToursByTourStopIds($locale: SiteLocale!, $tourStopIds: [ItemId!]) {
    tours: allTours(
      locale: $locale
      filter: {
        title: { isBlank: false }
        isDiscoverable: { eq: true }
        tourStops: { anyIn: $tourStopIds }
      }
      first: 100
      skip: 0
      orderBy: [position_ASC]
    ) {
      ...TourPreviewFields
      tourStops {
        id
        isPreviewTourStop
        isIntroTourStop
        isOutroTourStop
        location {
          latitude
          longitude
        }
        stories {
          ...StoryAudioDurationFields
        }
      }
    }
  }
  ${TourPreviewFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${StoryAudioDurationFieldsFragmentDoc}
  ${StorySlideAudioDurationFieldsFragmentDoc}
`;

export function useToursByTourStopIdsQuery(
  options: Omit<Urql.UseQueryArgs<ToursByTourStopIdsQueryVariables>, "query">
) {
  return Urql.useQuery<
    ToursByTourStopIdsQuery,
    ToursByTourStopIdsQueryVariables
  >({ query: ToursByTourStopIdsDocument, ...options });
}
export const ToursForCreatorProfileDocument = gql`
  query ToursForCreatorProfile(
    $locale: SiteLocale!
    $creatorProfileId: ItemId!
  ) {
    tours: allTours(
      locale: $locale
      filter: {
        isDiscoverable: { eq: true }
        creatorProfile: { eq: $creatorProfileId }
      }
      orderBy: [position_ASC]
      fallbackLocales: [de, en, fr, es]
    ) {
      ...TourPreviewFields
      tourStops {
        id
        isPreviewTourStop
        isIntroTourStop
        isOutroTourStop
        location {
          latitude
          longitude
        }
        stories {
          ...StoryAudioDurationFields
        }
      }
    }
  }
  ${TourPreviewFieldsFragmentDoc}
  ${ClampedResponsiveImageFileFieldsFragmentDoc}
  ${ResponsiveImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${RoundCreatorProfileImageFragmentDoc}
  ${BaseFileFieldsFragmentDoc}
  ${StoryAudioDurationFieldsFragmentDoc}
  ${StorySlideAudioDurationFieldsFragmentDoc}
`;

export function useToursForCreatorProfileQuery(
  options: Omit<
    Urql.UseQueryArgs<ToursForCreatorProfileQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    ToursForCreatorProfileQuery,
    ToursForCreatorProfileQueryVariables
  >({ query: ToursForCreatorProfileDocument, ...options });
}
export const ToursStopsByStoryIdsDocument = gql`
  query ToursStopsByStoryIds($locale: SiteLocale!, $storyIds: [ItemId!]) {
    tourStops: allTourStops(
      locale: $locale
      filter: { stories: { anyIn: $storyIds } }
      first: 100
      skip: 0
      fallbackLocales: [de, en, fr, es]
    ) {
      id
    }
  }
`;

export function useToursStopsByStoryIdsQuery(
  options: Omit<Urql.UseQueryArgs<ToursStopsByStoryIdsQueryVariables>, "query">
) {
  return Urql.useQuery<
    ToursStopsByStoryIdsQuery,
    ToursStopsByStoryIdsQueryVariables
  >({ query: ToursStopsByStoryIdsDocument, ...options });
}
export const VoicesDocument = gql`
  query Voices($locale: SiteLocale!, $first: IntType!, $skip: IntType!) {
    voices: allVoices(locale: $locale, first: $first, skip: $skip) {
      id
      name
      description
      isDefault
      audio {
        ...AudioFileFields
      }
    }
  }
  ${AudioFileFieldsFragmentDoc}
`;

export function useVoicesQuery(
  options: Omit<Urql.UseQueryArgs<VoicesQueryVariables>, "query">
) {
  return Urql.useQuery<VoicesQuery, VoicesQueryVariables>({
    query: VoicesDocument,
    ...options,
  });
}
